import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
// import Map from "./map";
import logo from '../tennis.png'
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import nadal_bg from '../images/nadal_bg.png'
import conquer from '../images/conquer.jpg'
import kobe from '../images/kobe.jpg'
import kobe_rest from '../images/kobe_rest.jpg'
import soccer_team from '../images/soccer_team.jpeg'



// Store the image URLs in an array
const imageUrls = [
    nadal_bg,
    kobe_rest,
];
// Get a random index from the array
const randomIndex = Math.floor(Math.random() * imageUrls.length);


export default function SplitScreen() {
    const navigate = useNavigate();
    const subject = encodeURIComponent('Question');
    const body = encodeURIComponent('Hello,\n\nI wanted to inquire about...');

    const handleNavigation = () => {
        navigate('/', { state: { tab: 1 } });
    };

    return (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={6} w={'full'} maxW={'lg'}>
                    <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: useBreakpointValue({ base: '20%', md: '30%' }),
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'blue.400',
                                zIndex: -1,
                            }}>
                            SportMatchHK
                        </Text>
                        <br />
                        <Text color={'blue.400'} as={'span'} fontSize={'25px'}>
                            {/* Ready for a game, anytime. */}
                            {/* Plan your game here. */}
                            Play your favourite sports, made easy.

                        </Text>
                    </Heading>
                    <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                        {/* Connecting Sports Enthusiasts, One Match at a Time */}
                        {/* Player-Court Matching Platform */}
                        {/* <p>Our mission is simple. </p> */}
                        <p>Connect with other <b>sports players</b> & <b>discover nearby games</b> today.</p>

                        <p>Leave a message on our <b><Link to="/contactus">Contact Us</Link></b> page.</p>
                        {/* Matching sports players, one game at a time. */}
                    </Text>

                    <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>

                        <Flex justifyContent="center" alignItems="center">
                            <Link className="btn btn-link" to={`/`}>
                                <Button
                                    rounded={'full'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    Find Game
                                </Button>
                            </Link>
                        </Flex>

                        {/* <Link className="btn btn-link" to={{ pathname: '/', hash: '#create' }}> */}
                        {/* <Link className="btn btn-link" to={{ pathname: '/', hash: '#create', state: { tab: 1 } }}>
                            <Button
                                rounded={'full'}
                            >
                                Create Game
                            </Button>
                        </Link> */}

                        <Flex justifyContent="center" alignItems="center">
                            <Button
                                rounded={'full'}
                                bg={'green.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'green.500',
                                }}
                                width={'130px'}
                                // className="btn btn-link"
                                onClick={handleNavigation}
                            >
                                Create Game
                            </Button>
                        </Flex>

                        {/* <Button rounded={'full'}>Create a Game</Button> */}
                        {/* <Link className="btn btn-link" to={`/contactus`}> */}

                        {/* <Link className="btn btn-link" to={`mailto:contactus@sportmatch.club?subject=${subject}&body=${body}`} target="_blank">
                            <Button
                                rounded={'full'} fontSize="12px"
                                _hover={{ backgroundColor: 'lightyellow' }}
                            >
                                Contact Us
                            </Button>
                        </Link> */}

                        <Link className="btn btn-link" to={`/contactus`}>
                            <Button
                                rounded={'full'}
                                _hover={{ backgroundColor: 'lightyellow' }}
                            >
                                Contact Us
                            </Button>
                        </Link>

                    </Stack>

                </Stack>
            </Flex>
            <Flex flex={1}>
                <Image
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={
                        // 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        // { logo }
                        // 'https://assets.tennismajors.com/app/uploads/2022/06/06001504/Rafael_Nadal_foot.jpg'
                        nadal_bg
                        // conquer
                        // kobe
                        // kobe_rest
                        // soccer_team
                        // imageUrls[randomIndex]
                    }
                // bg={`url(${kobe}) no-repeat center center fixed`}
                // bgSize={{ base: "300%", md: 'cover' }}
                // backgroundPosition={{ base: '60% 25%', md: 'center center' }}
                />

            </Flex>
        </Stack>
    );
}
