import React, { useEffect, useState, useMemo } from "react";
import { Link, Navigate } from "react-router-dom";
// import { useTable, useExpanded } from 'react-table'
import { useTable, useExpanded, useGroupBy, useFilters } from 'react-table';
import Map from "./map";
import Test2 from "./test2";
import { Table, Tr, Td, Thead, Tbody, TableCaption, Button, ButtonGroup } from "@chakra-ui/react"
// import { Button, ButtonGroup } from '@chakra-ui/react'
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Route, Routes } from "react-router-dom";
import { joinGame, leaveGame } from './joinGame';
import { useParams, useNavigate } from "react-router";
import { Flex, Spacer, Box, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import Select from 'react-select';
// import all_courts from '../data/courts.json';
import all_courts from '../data/courts.json';

// const sports = [...new Set(all_courts.map((x) => x.sport))];
const sports = ["basketball", "tennis"];
// console.log("SPORTS: ", sports);
// const sports = ["basketball", "tennis", "pickleball", "soccer", "hockey", "volleyball"]

// const regions = [...new Set(all_courts.map((x) => x.region))];


const HomepageHK = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
  });
  const [records, setRecords] = useState([]);
  // const [all_courts, setAll_courts] = useState([]);

  // const all_courts = props.courts;
  // console.log("recordTable courts:", all_courts)

  // const sports = [...new Set(all_courts.map((x) => x.sport))];
  // const regions = [...new Set(all_courts.map((x) => x.region))];
  // console.log('sports:', sports);
  // console.log('regions:', regions)

  // const locations = all_courts.filter(court => props.region.includes(court.region)).map((x) => x.name);
  // const [selectedLocation, setSelectedLocation] = useState(locations)
  const [isLoggedin, setIsLoggedin] = useState(!!props.username);
  const [selectedSport, setSelectedSport] = useState(sports);
  // const [selectedRegion, setSelectedRegion] = useState(["NUS"]);

  // const db_courts = props.db_courts;
  // console.log("table db_courts:", db_courts);

  useEffect(() => {
    setIsLoggedin(!!props.username);
    // isLoggedin = !!props.username;
  }, [props.username]);

  const isAdmin = false;
  const navigate = useNavigate();
  // const isLoggedin = props.username ? true : false;
  // console.log('logged in? ', isLoggedin);
  // console.log("current username: ", props.username)
  // const handleLocationChange = (event) => {
  //   setSelectedLocation(event.target.value);
  //   console.log("selectedLocationn", selectedLocation);
  // };

  // function handleLocationChange(event) {
  //   const options = event.target.options;
  //   console.log("options:", options);
  //   const selectedValues = Array.from(options)
  //     .filter((option) => option.selected)
  //     .map((option) => option.value);
  //   setSelectedLocation(selectedValues);
  //   console.log("selectedLocationn", selectedLocation);
  // }

  // const handleSportChange = (event) => {
  //   const selectedSports = Array.from(event.target.selectedOptions, option => option.value.toLowerCase());
  //   setSelectedSport(selectedSports);
  //   // Find locations that have all of the selected sports
  //   const filteredLocations = all_courts.filter(location => {
  //     return selectedSports.every(sport => location.sport.toLowerCase() === sport);
  //   });
  //   // Update the selected locations based on the filtered locations
  //   const selectedLocations = filteredLocations.map(location => location.name);
  //   setSelectedLocation(selectedLocations);
  // }


  const filteredLocations = all_courts.filter(location => {
    const locationSports = location.sport.toLowerCase();
    return selectedSport.every(sport => locationSports.includes(sport));
  });

  // useMemo reduces unnecessary re-renders. Only renders when records updates
  // const data = React.useMemo(
  //   () => records.length > 0 ? records.map((record) => (record)) : [], [records])

  const data = React.useMemo(
    () =>
      records.length > 0
        ? records.map((record) => {
          const capacity = `${record.no_of_players}/${record.max_no_of_players}`;
          const isFull = parseInt(record.max_no_of_players) <= parseInt(record.no_of_players);
          const alreadyJoined = record.players && record.players.includes(props.username);
          const isJoinable = !isFull && !alreadyJoined && isLoggedin;
          return { ...record, capacity, isFull, alreadyJoined, isJoinable };
        })
        : [],
    [records, props.username, isLoggedin]
  );

  // console.log("data: ", data);
  // console.log("selectedLocation:", selectedLocation)

  // const filtered_data = React.useMemo(
  //   () => {
  //     const selectedLocations = all_courts
  //       .filter(court => selectedSport.includes(court.sport.toLowerCase()))
  //       .map(court => court.name);

  //     return data.filter(item => selectedLocations.includes(item.location));
  //   },
  //   [data, selectedSport, all_courts, selectedLocation]
  // );

  const filtered_data = React.useMemo(() => data, [data]);

  // function handleRegionChange(event) {
  //   const options = event.target.options;
  //   const selectedValues = Array.from(options)
  //     .filter((option) => option.selected)
  //     .map((option) => option.value);
  //   // setSelectedRegion(selectedValues);
  //   // props.setRegion(selectedValues);
  //   // // console.log("selectedRegion", selectedRegion);
  //   // console.log("props.region:", props.region);    
  //   props.setRegion(selectedValues);

  //   // locations = list of locations
  //   // all_courts = json of locations AND info, including region
  //   // all_courts.map((x) => x.name);
  //   all_courts.filter((option) => option.selected)
  //     .map((option) => option.value);
  //   const selectedCourts = all_courts.filter((court) => selectedValues.includes(court.region)).map((filtered_court) => filtered_court.name);
  //   setSelectedLocation(selectedCourts);
  // }

  // useEffect(() => {
  //   console.log(`props.region is now ${props.region}`);
  //   console.log(`selectedLocation is now ${selectedLocation}`);
  // }, [props.region, selectedLocation]);

  // This method fetches the records from the database.
  useEffect(() => {
    if (isLoaded) {
      async function getRecords() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          window.alert(message);
          return;
        }
        const records = await response.json();
        setRecords(records);
        // console.log("recordsss", records);

        // Fetch courts
        //   const courtsResponse = await fetch(`${process.env.REACT_APP_API_URL}/courts/`);
        //   if (!courtsResponse.ok) {
        //     const message = `An error occurred while fetching courts: ${courtsResponse.statusText}`;
        //     window.alert(message);
        //     return;
        //   }
        //   const courts = await courtsResponse.json();
        //   setAll_courts(courts);
        //   console.log("courtssss:", all_courts);
      }
      getRecords();
    }
  }, [isLoaded, records.length]);


  function handleJoinClick(e, id, game_id) {
    e.preventDefault();
    joinGame(id, props.username, setRecords, game_id);
  }

  function handleLeaveClick(e, id, game_id) {
    e.preventDefault();
    leaveGame(id, props.username, setRecords, game_id);
  }
  // console.log("props.type 1", props.type)
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Box maxW={{ base: "100%", md: "85vw" }} marginX="auto">
      <>
        {/* <Map selectedLocation={selectedLocation} /> */}
        {/* 
        <Flex maxW={{ base: '800px', md: 'none' }} flexDirection={{ base: 'row', md: 'row' }}
          marginX={{ base: '2', md: '0' }}
          marginY={{ base: '0', md: '0' }}
          marginTop={{ base: '2', md: '0' }}
        > */}

        {/* <Box mr={{ base: '10px', md: '20px' }} maxW={{ base: "400px", md: 'none' }}>
            <Flex direction="column" alignItems="center">

              <b>Region</b>
              <select value={props.region} onChange={handleRegionChange}
                style={{ border: '2px solid black', borderRadius: '10px', padding: '10px', width: '100%' }}>
                {regions.map((region) => (
                  <option key={region} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </Flex>
          </Box> */}
        {/* 
          <Box mr={{ base: '10px', md: '20px' }} maxW={{ base: "400px", md: 'none' }}>
            <Flex direction="column" alignItems="center">
              <b>Sport</b>
              <select multiple={true} value={selectedSport} onChange={handleSportChange}
                style={{ border: '2px solid black', borderRadius: '10px', padding: '10px', width: '100%' }}>
                {sports.map((sport) => (
                  <option key={sport} value={sport.toLowerCase()}>
                    {sport}
                  </option>
                ))}
              </select>
            </Flex>
          </Box>

          <Box maxW={{ base: "400px", md: 'none' }}>
            <Flex direction="column" alignItems="center">
              <b>Court</b>
              <select multiple={true} value={selectedLocation} onChange={handleLocationChange}
                style={{ border: '2px solid black', borderRadius: '10px', padding: '10px', width: '100%' }}>
                {filteredLocations.map((location) => (
                  <option key={location.name} value={location.name}>
                    {location.name}
                  </option>
                ))}
              </select>
            </Flex>
          </Box>
        </Flex>
        <br /> */}

        <Test2 data={filtered_data} username={props.username}
          handleJoinClick={handleJoinClick}
          handleLeaveClick={handleLeaveClick}
          mapLocation={props.mapLocation}
          type={props.type} />

      </>
    </Box>

  );
};
export default HomepageHK;
