import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { Link, Navigate } from "react-router-dom";
// import { useTable, useExpanded } from 'react-table'
import { useTable, useExpanded, useGroupBy, useFilters } from 'react-table';
import Map from "./map";
import Test2 from "./test2";
import { Table, Tr, Td, Thead, Tbody, TableCaption, Button, ButtonGroup, Select } from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
// import { Button, ButtonGroup } from '@chakra-ui/react'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { Route, Routes } from "react-router-dom";
import { joinGame, leaveGame } from './joinGame';
import { useParams, useNavigate } from "react-router";
import { Flex, Spacer, Box, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
// import Select from 'react-select';
// import all_courts from '../data/courts.json';
import all_courts from '../data/courts.json';
import Create2 from "./create2";
import HomepageHK from "./homepage_hk"
import { useRef } from 'react';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import basketball_icon2 from '../basketball_icon.png'
import football_icon from "../soccer_icon.png"
import mapStyle from '../styles/mapStyle3.json';
import { MdGpsFixed } from "react-icons/md";
// import GameCards from "./gameCards";
import GameCards2 from "./gameCards2";

// const sports = ["All", "basketball", "tennis", "football", "badminton", "jogging", "gym", "yoga", "hiking", "biking", "surfing", "misc"];
const sports = ["All", "basketball", "tennis", "football", "badminton", "misc"];
// const places = ["Hong Kong", "Singapore", "Vancouver"];
const places = ["Hong Kong"];
// const regions = ["Sai Wan", "Kowloon", "East Side"];
// const env = "prod"; 

const NewTableMobile = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
    });
    const [selectedSport, setSelectedSport] = useState("All");
    const [selectedPlace, setSelectedPlace] = useState("Hong Kong");
    // const [selectedRegion, setSelectedRegion] = useState("Sai Wan");
    // const [tennisCourts, setTennisCourts] = useState([]);
    // const center = { lat: 22.506274717872525, lng: 114.13053207693996 }
    const [center, setCenter] = useState({ lat: 22.288841297468345, lng: 114.18109673466331 })
    // const [bballCourts, setBballCourts] = useState([]);
    const [selectedCourt, setSelectedCourt] = useState(null);
    const [mapLocation, setMapLocation] = useState("");
    const [zoom, setZoom] = useState(13)
    const create2Ref = useRef();
    const viewGamesRef = useRef();
    const [mapType, setMapType] = useState("satellite");
    const [disableDefaultUI, setDisableDefaultUI] = useState(true);
    const [filtered_CombinedCourts, setFiltered_CombinedCourts] = useState([]);
    const [mapCourts, setMapCourts] = useState("upcoming")
    const [userLocation, setUserLocation] = useState(null);
    const [myLocationMarker, setMyLocationMarker] = useState(null);
    const [googleLocationMarker, setGoogleLocationMarker] = useState(null);
    // const [combined_markers, setCombined_markers] = useState([])
    // const [upcomingCourtsFilter, setUpcomingCourtsFilter] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();
    // console.log("location", location)
    const initialActiveTab = location.state?.tab || 0;
    // console.log("initialActiveTab:", initialActiveTab)
    // const [activeTab, setActiveTab] = useState(1);
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const [selectedTimeframe, setSelectedTimeframe] = useState("upcoming");

    const [filteredGames, setFilteredGames] = useState([]);
    const [combined_markers, setCombined_markers] = useState([]);
    // const [filteredCombinedCourtsList, setFilteredCombinedCourtsList] = useState([]);

    if (props.debugMode) { console.log("DEBUG MODE ON") }

    // Opens the Modal filter box by default, only if View Games, not Create Game
    useEffect(() => {
        if (initialActiveTab === 0) {
            onOpen(); // Open the modal by default
        }
    }, [onOpen]);

    useEffect(() => {
        setActiveTab(initialActiveTab);
        if (initialActiveTab === 1) {
            // console.log("heree")
            setMapCourts("all");
        }
    }, [initialActiveTab]);

    let combinedCourts_list = props.combinedCourts || [];
    // let combined_markers = [];

    // console.log("records:", props.records)
    const now = new Date();
    const time_buffer = new Date(now.getTime() - 12 * 60 * 60 * 1000);

    const all_games = props.env == 'dev' ? props.records.filter(rec => rec.env == "dev") : props.records.filter(rec => rec.env !== "dev");
    const upcoming_games = all_games.filter(rec => new Date(rec.datetime) >= time_buffer);
    // const upcoming_games = props.records.filter(rec => new Date(rec.datetime) >= now);
    // const past_games = props.records;
    const past_games = all_games.filter(rec => {
        const gameDate = new Date(rec.datetime);
        return gameDate >= new Date(new Date().setDate(new Date().getDate() - 21))
        // && gameDate < now;
    });
    // console.log("past_games:", past_games)

    // console.log("upcoming_games:", upcoming_games)
    useEffect(() => {
        if (selectedTimeframe === 'upcoming') {
            setFilteredGames(upcoming_games);
        } else if (selectedTimeframe === 'past') {
            setFilteredGames(past_games);
        } else {
            setFilteredGames([]);
        }
    }, [selectedTimeframe, props.records]);

    useEffect(() => {
        // if (combinedCourts_list !== null) {
        console.log("combinedCourts_list 1:", combinedCourts_list);
        console.log("filteredGames:", filteredGames);
        // if (upcomingCourtsFilter) {
        // if (mapCourts === "upcoming") {
        //     combinedCourts_list = combinedCourts_list.filter(court => {
        //         return filteredGames.some(game => game.location === court.id)
        //     }
        //     )
        // }
        console.log("filtered_CombinedCourts 2:", filtered_CombinedCourts);

        // show only courts relevant to selected SPORT and CITY:
        // const filtered_combinedCourts_list = combinedCourts_list.filter((obj) => obj.sport == selectedSport).filter((obj) => obj.country == selectedPlace);

        // useEffect(() => {
        //     let filteredCourts = combinedCourts_list.filter((obj) => obj.country === selectedPlace);

        //     if (selectedSport !== "All") {
        //         filteredCourts = filteredCourts.filter((obj) => obj.sport === selectedSport);
        //     }

        //     setFilteredCombinedCourtsList(filteredCourts);
        // }, [selectedSport, selectedPlace]);
        // combined_markers = filtered_combinedCourts_list.map(court => {
        // combined_markers = filteredCombinedCourtsList.map(court => {

        // combined_markers = filtered_CombinedCourts.map(court => {
        setCombined_markers(filtered_CombinedCourts.map(court => {
            // combined_markers = combinedCourts_list.map(court => {
            const markerPosition = { lat: court.coordinates[1], lng: court.coordinates[0] };
            let icon_img;
            switch (court.sport) {
                case "basketball":
                    icon_img = basketball_icon;
                    break;
                case "tennis":
                    icon_img = tennis_icon;
                    break;
                case "football":
                    icon_img = football_icon;
                    break;
                default:
                    icon_img = all_sports_icon;
            }
            let icon_size;
            switch (court.source) {
                case "db":
                    icon_size = { width: 30, height: 30 };
                    // icon_img = all_sports_icon;
                    break;
                case "gov":
                    icon_size = { width: 30, height: 30 };
                    break;
                case "google":
                    icon_size = { width: 25, height: 25 };
                    break;
                default:
                    icon_size = { width: 30, height: 30 };
            }
            let icon;
            icon = { url: icon_img, scaledSize: icon_size };
            return (
                <Marker
                    key={court.id}
                    position={markerPosition}
                    onClick={() => setSelectedCourt(court)}
                    icon={icon}
                >
                    {selectedCourt === court && (
                        <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                            <div>
                                {/* <h3>Combined Courts Info</h3> */}
                                <h6><b>{court.name}</b></h6>
                                {court.SEARCH01_EN && <p>Region: {court.SEARCH01_EN}</p>}
                                <p>Address: {court.address} <br />(<b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b>)</p>
                                <p>Sport: {court.sport}</p>
                                {court.SEARCH02_EN && <p>Sports: {court.SEARCH02_EN}</p>}
                                {court.no_of_courts && <p>No. of Courts: {court.no_of_courts}</p>}
                                {/* <p>Status: </p> */}
                                {court.NSEARCH02_EN && <p>Hours: {court.NSEARCH02_EN}</p>}
                                <p style={{ fontSize: '6px' }}>Source: {court.source}</p>
                                <Button
                                    colorScheme='teal'
                                    label="Create"
                                    fontSize="xs"
                                    size="xs"
                                    mr={2}
                                    onClick={(e) => {
                                        // handleJoinClick(e, rowData._id, rowData.game_id);
                                        // window.alert("create game")
                                        handleLocationChange(court.id)
                                        setActiveTab(1)
                                        create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Create
                                </Button>
                                <Button
                                    colorScheme='teal'
                                    label="view_availabilities"
                                    fontSize="xs"
                                    size="xs"
                                    onClick={(e) => {
                                        // handleJoinClick(e, rowData._id, rowData.game_id);
                                        // window.alert("view availabilities")
                                        setActiveTab(0);
                                        // console.log("activeTab:", activeTab);
                                        handleLocationChange(court.id)
                                        viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >View Existing Games</Button>
                            </div>
                        </InfoWindow>)}
                </Marker>)
        }))
        // console.log("combined_markers:", combined_markers)
    }
        // }
        , [filtered_CombinedCourts]);


    useEffect(() => {
        // console.log("props.combinedCourts", props.combinedCourts)
        // let temp_filtered_combinedCourts = props.combinedCourts
        let temp_filtered_combinedCourts = combinedCourts_list
            .filter((obj) => obj.country === selectedPlace);
        if (selectedSport !== "All") {
            temp_filtered_combinedCourts = temp_filtered_combinedCourts
                .filter((obj) => obj.sport === selectedSport);
        }

        if (mapCourts === "upcoming") {
            temp_filtered_combinedCourts = temp_filtered_combinedCourts.filter(court => {
                return filteredGames.some(game => game.location === court.id)
            })
        }
        // console.log("temp_filtered_combinedCourts:", temp_filtered_combinedCourts)
        temp_filtered_combinedCourts = temp_filtered_combinedCourts
            .sort((a, b) => a.name.localeCompare(b.name));
        console.log("temp_filtered_combinedCourts:", temp_filtered_combinedCourts)

        setFiltered_CombinedCourts(temp_filtered_combinedCourts);
        // if (props.debugMode) { console.log("combinedCourts in new_table:", temp_filtered_combinedCourts) }
    },
        [selectedSport, props.combinedCourts, selectedPlace, mapCourts]);



    useEffect(() => {
        if (activeTab === 1 && create2Ref.current) {
            // viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
            create2Ref.current.scrollIntoView({ behavior: 'smooth' });
            setMapCourts("all");
        }
        else if (activeTab === 0 && viewGamesRef.current) {
            setMapCourts("upcoming");
            // console.log("here2")
        }
    }, [activeTab]);

    console.log("selectedCourt", selectedCourt);

    const handleGoogleLocationChange = (location) => {
        // setMapLocation(location);
        console.log("handleGoogleLocationChange location:", location)
        const lat = location.geometry.location.lat();
        const lng = location.geometry.location.lng();
        setCenter({ lat: lat, lng: lng })
        setZoom(18.5);
        // const markerPosition = { lat: lat, lng: lng };
        const googleLocationMarker = (
            <Marker
                key={1}
                position={{ lat: lat, lng: lng }}
                //   onClick={() => setSelectedCourt(court)}
                //   icon={icon}
                onClick={() =>
                    setSelectedCourt(location.place_id)
                }
            // icon={icon}
            >
                {/* {selectedCourt === location.place_id && ( */}
                <InfoWindow
                    position={{ lat: lat, lng: lng }}
                    onCloseClick={() => setSelectedCourt(null)}
                >
                    <div>
                        {/* <h3>Court Info</h3> */}
                        <h3><b>{location.name}</b></h3>
                        <h6><b>Address: {location.formatted_address}</b></h6>
                        {/* {court.SEARCH01_EN && <p>Region: {court.SEARCH01_EN}</p>}
                        <p>Address: {court.address} <br />(<b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b>)</p>
                        <p>Sport: {court.sport}</p>
                        {court.SEARCH02_EN && <p>Sports: {court.SEARCH02_EN}</p>}
                        {court.no_of_courts && <p>No. of Courts: {court.no_of_courts}</p>}
                        {court.NSEARCH02_EN && <p>Hours: {court.NSEARCH02_EN}</p>}
                        <p style={{ fontSize: '6px' }}>Source: {court.source}</p> */}
                    </div>
                </InfoWindow>
                {/* )
                } */}
            </Marker >
        );
        console.log("setting googleLocationMarker:", googleLocationMarker)
        setGoogleLocationMarker(googleLocationMarker);
    };

    const handleLocationChange = (location) => {
        setMapLocation(location);

        // rebuild this function to reference full list:
        const selectedCourt = props.combinedCourts.find((court) => court.id == location);
        if (selectedCourt) {
            // console.log("selectedCourt2:", selectedCourt)
            setCenter({ lat: selectedCourt.coordinates[1], lng: selectedCourt.coordinates[0] })
            setZoom(18.5);
        } else {
            // Handle the case when the selected court is not found
            if (props.debugMode) { console.log("Selected court not found:", location); }
        }
    };

    const handleSportChange = (sport) => {
        if (sport === "All") {
            // Handle the "All" option
            setSelectedSport(""); // or any logic to include all sports
        } else {
            setSelectedSport(sport);
        }
        // setSelectedSport(sport);
        // console.log("sport!: ", sport);
    }

    // Place = Country (Eg. Hong Kong, Singapore)
    const handlePlaceChange = (place) => {
        setSelectedPlace(place);
        // console.log("place changed:", place);
    }

    // const handleRegionChange = (region) => {
    //     setSelectedRegion(region);
    // }

    const defaultView = () => {
        setCenter({ lat: 22.288841297468345, lng: 114.18109673466331 });
        setZoom(13);
        setMapLocation("");
        setMapType("satellite");
        setDisableDefaultUI(true);
        setSelectedSport("basketball");
        setSelectedCourt(null);
        setSelectedPlace("Hong Kong");
        // setUpcomingCourtsFilter(false);
        // toggleMapCourts()
        setMapCourts("upcoming")
    }

    useEffect(() => {
        switch (selectedPlace) {
            case 'Hong Kong':
                setCenter({ lat: 22.3193, lng: 114.1694 });
                setZoom(13);
                break;
            case 'Singapore':
                setCenter({ lat: 1.3521, lng: 103.8198 });
                setZoom(12);
                break;
            case 'Vancouver':
                setCenter({ lat: 49.1659, lng: -123.10233 });
                setZoom(12);
                break;
            default:
                setCenter({ lat: 22.3193, lng: 114.1694 });
                setZoom(13);
                break;
        }
    }, [selectedPlace]);

    // useEffect(() => {
    //     switch (selectedRegion) {
    //         case 'Sai Wan':
    //             setCenter({ lat: 22.3193, lng: 114.1694 });
    //             setZoom(14);
    //             break;
    //         case 'Kowloon':
    //             setCenter({ lat: 1.3521, lng: 103.8198 });
    //             setZoom(14);
    //             break;
    //         default:
    //             setCenter({ lat: 22.3193, lng: 114.1694 });
    //             setZoom(14);
    //             break;
    //     }
    // }, [selectedRegion]);


    const toggleMapType = () => {
        if (mapType === "satellite") {
            setMapType("roadmap");
            setDisableDefaultUI(false);
        } else {
            setMapType("satellite");
            setDisableDefaultUI(true);
        }
    };

    const toggleMapCourts = () => {
        setMapCourts((prevValue) => {
            if (prevValue === "all") {
                // setUpcomingCourtsFilter(true)
                return "upcoming";
            } else if (prevValue === "upcoming") {
                // setUpcomingCourtsFilter(false)
                return "all";
            }
        });
    };


    // useEffect(() => {
    //     // Check if Geolocation API is available in the browser
    //     if (navigator.geolocation) {
    //         // Request user's location
    //         navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 const { latitude, longitude } = position.coords;
    //                 setUserLocation({ latitude, longitude });
    //             },
    //             error => {
    //                 console.error('Error getting user location:', error);
    //             }
    //         );
    //     } else {
    //         console.error('Geolocation API is not supported in this browser.');
    //     }
    // }, []);

    const handleMyLocation = () => {
        if (navigator.geolocation) {
            // Request user's location
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    // const localUserLocation = { latitude, longitude };
                    setUserLocation({ latitude, longitude });
                    setCenter({ lat: latitude, lng: longitude });
                    // setCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
                    setZoom(16);

                    const myLocationMarker = (
                        <Marker
                            key={1}
                            position={{ lat: latitude, lng: longitude }}
                        //   onClick={() => setSelectedCourt(court)}
                        //   icon={icon}
                        />
                    );
                    setMyLocationMarker(myLocationMarker);
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation API is not supported in this browser.');
        }

    }
    // const applyFilters = () => {
    //     handleFiltersChange({
    //         sport: selectedSport,
    //         location: selectedLocation,
    //         court: selectedCourt,
    //     });
    //     onClose();
    // };

    if (!isLoaded) return <div>Loading...</div>;
    return (
        <Box>
            <Box p="2">
                <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container"
                    mapContainerStyle={{ height: "40vh", width: "100%", borderRadius: "10px" }}
                    options={{ disableDefaultUI: disableDefaultUI, mapTypeId: mapType, styles: mapStyle }}
                // options={{ styles: mapStyle }}
                >
                    {combined_markers}
                    {myLocationMarker}
                    {googleLocationMarker}
                    <Button
                        onClick={handleMyLocation}
                        position="absolute"
                        bottom="2"
                        right="2"
                    >
                        <MdGpsFixed />
                    </Button>

                </GoogleMap>
            </Box>

            <Box display="flex"
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '1', md: '1' }}
            >

                <Button
                    onClick={defaultView}
                    marginRight={2}
                    fontSize="xs"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                    colorScheme="teal"
                // variant="outline"
                >
                    Default View
                </Button>

                <Button
                    onClick={toggleMapType}
                    marginRight={2}
                    fontSize="xs"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                    colorScheme="teal"
                // variant="outline"
                >
                    Map Type
                </Button>

                <Button
                    onClick={toggleMapCourts}
                    marginRight={2}
                    fontSize="xs"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                    colorScheme="teal"
                // variant="outline"
                >
                    {mapCourts === "upcoming" ? "Show All Courts" : "Only Courts with Games"}
                </Button>

                <Button
                    onClick={onOpen}
                    fontSize="xs"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                    // colorScheme="blue"
                    // rounded={'full'}
                    colorScheme="teal"
                // variant="outline"
                >
                    Filter
                </Button>
                {/* <Modal isOpen={isOpen} onClose={onClose}> */}
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Filter Options</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Select placeholder="Select Sport" value={selectedSport} onChange={(e) => setSelectedSport(e.target.value)}>
                                {sports.map((sport) => (
                                    <option key={sport} value={sport}>{sport.charAt(0).toUpperCase() + sport.slice(1)}</option>
                                ))}
                            </Select>

                            <Select placeholder="Select Location" value={selectedPlace} onChange={(e) => handlePlaceChange(e.target.value)} mt={4}
                                isDisabled
                            >
                                {places.map((place) => (
                                    <option key={place} value={place}>
                                        {place}
                                    </option>
                                ))}
                            </Select>

                            {/* <Select placeholder="Select Court" value={selectedCourt} onChange={(e) => handleLocationChange(e.target.value)} mt={4}>
                                {filtered_CombinedCourts.map(court =>
                                    <option key={court.id} value={court.id}>
                                        {court.name}
                                    </option>)
                                }
                            </Select> */}

                            <Select placeholder="Select Timeframe" value={selectedTimeframe} onChange={(e) => setSelectedTimeframe(e.target.value)} mt={4}>
                                <option value="upcoming">Upcoming games only</option>
                                <option value="past">Including past games</option>
                            </Select>

                        </ModalBody>

                        <ModalFooter>
                            {/* <Button colorScheme="blue" mr={3} onClick={applyFilters}>
                                Apply Filters
                            </Button> */}
                            <Button colorScheme="gray" onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>


                {/* <Select value={selectedSport} onChange={(e) => {
                    handleSportChange(e.target.value)
                }}
                    w="150px"
                    fontSize="sm"
                    mr={2}
                >
                    {sports.map((sport) => (
                        <option key={sport} value={sport}>
                            {sport}
                        </option>
                    ))}

                </Select>

                <Select value={selectedPlace} onChange={(e) => {
                    handlePlaceChange(e.target.value)
                }}
                    w="150px"
                    fontSize="sm"
                    mr={2}
                >
                    {places.map((place) => (
                        <option key={place} value={place}>
                            {place}
                        </option>
                    ))}
                </Select> */}

                {/* Region Filter box */}
                {/* <Select value={selectedRegion} onChange={(e) => {
                    handleRegionChange(e.target.value)
                }}
                    w="150px">
                    {regions.map((region) => (
                        <option key={region} value={region}>
                            {region}
                        </option>
                    ))}
                </Select> */}

                {/* new Location Filter Box */}
                {/* <Select value={selectedCourt} onChange={(e) => {
                    handleLocationChange(e.target.value)
                }}
                    w="150px"
                    fontSize="sm"
                >
                    <option value={selectedCourt}>Select Court</option>
                    {filtered_CombinedCourts.map(court =>
                        <option key={court.id} value={court.id}>
                            {court.name}
                        </option>)
                    }
                </Select> */}

            </Box>

            {/* <Tabs variant='soft-rounded' colorScheme='green'  */}
            <Tabs size='md' variant='enclosed'
                index={activeTab} onChange={index => setActiveTab(index)}
                mt="2">
                <TabList>
                    <Tab
                    // isSelected={activeTab === 1}
                    // onClick={() => setActiveTab(1)}
                    >
                        View Games
                    </Tab>
                    <Tab
                    // isSelected={activeTab === 0}
                    // onClick={() => setActiveTab(0)}
                    >
                        Create Game
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <div ref={viewGamesRef}>
                            {/* <HomepageHK username={props.username} tennisCourts={props.tennisCourts} bballCourts={props.bballCourts} mapLocation={mapLocation} /> */}
                            {/* <GameCards
                                username={props.username}
                                records={props.records}
                                combinedCourts={props.combinedCourts}
                                mapLocation={mapLocation}
                                debugMode={props.debugMode}
                            /> */}
                            <GameCards2
                                username={props.username}
                                // records={props.records}
                                records={filteredGames}
                                combinedCourts={filtered_CombinedCourts}
                                selectedSport={selectedSport}
                                mapLocation={mapLocation}
                                debugMode={props.debugMode}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div ref={create2Ref} id="create">
                            <Create2
                                handleLocationChange={handleLocationChange} handleSportChange={handleSportChange} selectedSport={selectedSport}
                                username={props.username} mapLocation={mapLocation}
                                // basketball_google={props.basketball_google} db_courts={props.db_courts} tennis_courts={props.tennisCourts} basketball_courts={props.bballCourts} 
                                // combinedCourts={props.combinedCourts} 
                                combinedCourts={filtered_CombinedCourts}
                                debugMode={props.debugMode}
                                handleGoogleLocationChange={handleGoogleLocationChange}
                            />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box >
    )
};
export default NewTableMobile;