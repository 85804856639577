import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import {
    Button, Flex, Spacer, Box, NumberInput, NumberInputField, NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper, Stack, Text
} from "@chakra-ui/react"
import Map from "./map";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import all_courts from '../data/courts.json';
import { ArrowBackIcon } from "@chakra-ui/icons";
import LocationSelector from './locationSelector'; // Adjust the path as necessary

import { AdminContext } from '../App';


// const locations = all_courts.map((x) => x.name);

// const locations =
//     ["NUS Multipurpose Hall, Court 1", "NUS Multipurpose Hall, Court 2",
//         "NUS Tennis Court 1", "NUS Tennis Court 2",
//         "NUS Basketball Court 1", "NUS Basketball Court 2"];

// const sports = ["basketball", "tennis", "pickleball", "soccer", "hockey", "volleyball"]

//automated sport values based on courts.json
// const sports = [...new Set(all_courts.map((x) => x.sport))];



// const sportLocations = {};
// all_courts.forEach((court) => {
//     const sport = court.sport.toLowerCase();

//     if (!sportLocations[sport]) {
//         sportLocations[sport] = [];
//     }
//     sportLocations[sport].push(court.name);
// });
// console.log("sportlocations:", sportLocations);

// const sportLocations = {
//     tennis: [
//         "NUS Tennis Court 1",
//         "NUS Tennis Court 2",
//         "NUS Multipurpose Hall, Court 1",
//         "NUS Multipurpose Hall, Court 2"
//     ],
//     basketball: [
//         "NUS Sports Hall 1",
//         "NUS Sports Hall 2",
//         "NUS Sports Hall 3",
//         "NUS Multipurpose Hall, Basketball Court"
//     ]
// };

const hongKongBounds = {
    north: 22.543847,
    south: 22.153549,
    west: 113.835821,
    east: 114.451802
};

const cities = [
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Singapore", label: "Singapore" },
    { value: "Vancouver", label: "Vancouver" }
];

const libraries = ['places'];

export default function Create2(props) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
        libraries: libraries
    });
    // const [selectedLocation, setSelectedLocation] = useState(locations)
    const [locationTimes, setLocationTimes] = useState([]);
    const [form, setForm] = useState({
        name: props.username,
        location: props.mapLocation,
        time: "",
        datetime: "",
        length: "60",
        sport: props.selectedSport,
        no_of_players: 1,
        max_no_of_players: "",
        tags: "",
        level: "",
        players: [],
        comments: "",
        public_setting: "",
        enable_message_board: "no",
        message_host: 2,
        location_decided: "yes",
        city: props.selectedCity,
        price: 0,
        env: "prod"
    });
    const navigate = useNavigate();
    const [formChanged, setFormChanged] = useState(false);
    // const [tennisCourtNames, setTennisCourtNames] = useState([]);
    // const [bballCourtNames, setBballCourtNames] = useState([]);
    const [filtered_CombinedCourts, setFiltered_CombinedCourts] = useState([]);
    const [locationDecided, setLocationDecided] = useState("yes");
    // const [selectedCity, setSelectedCity] = useState("Hong Kong");
    const [selectedGooglePlace, setSelectedGooglePlace] = useState();
    const [autocomplete, setAutocomplete] = useState(null);
    // use this state to store for both selectedGoogleLocation and props.selectedCourt
    const [finalCourtObj, setFinalCourtObj] = useState(props.selectedCourt);

    const { isAdmin } = useContext(AdminContext);

    // default for combinedCourts should be: filtered_combinedCourts = props.combinedCourts.filter((obj) => obj.sport == props.selectedSport)

    // console.log("filtered_CombinedCourts", filtered_CombinedCourts);

    const minDate = new Date(); // set the minimum date to today's date
    const maxDate = new Date(); // create a new Date object for the maximum date
    maxDate.setDate(maxDate.getDate() + 180); // set the maximum date to 6 months after today's date

    const sports = props.type == "boardgames" ? ["board games"] : ["basketball", "tennis", "football", "badminton", "jogging", "gym", "yoga", "hiking", "biking", "surfing", "misc"];;

    // console.log("Create page Username:", props.username);
    if (props.debugMode) { console.log("PROPS.USERNME:", props.username); }
    // console.log("RECORDS:", props.records);
    // console.log("PROPS from Create: ", props)
    // console.log("create2 courts:", props.tennis_courts)
    // console.log("basketball_google courts:", props.basketball_google)
    // console.log("basketball_courts:", props.basketball_courts)


    console.log("form:", form);
    //for selectedGooglePlace
    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
        console.log('Autocomplete loaded:', autocomplete);
    };

    useEffect(() => {
        if (props.selectedCourt) {
            console.log("props.selectedCourt", props.selectedCourt);
            // I can't just set the GooglePlace to be selectedCourt because of different Formats.
            // I should write an IF ELSE statement in my Create Game function to handle the different formats.
            // setSelectedGooglePlace(props.selectedCourt);
            updateForm({ location: props.selectedCourt.id, sport: props.selectedCourt.sport });
            let placeData = {
                name: props.selectedCourt["name"],
                lng: props.selectedCourt.coordinates[0],
                lat: props.selectedCourt.coordinates[1],
                address: props.selectedCourt.address,
                sport: form.sport,
                username: props.username,
                location_id: props.selectedCourt.id
            };
            setFinalCourtObj(placeData);
        }
    }, [props.selectedCourt]);


    useEffect(() => {
        if (finalCourtObj) {
            updateForm({ location: finalCourtObj.location_id });
        }
    }, [finalCourtObj]);

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            setSelectedGooglePlace(place); // Update the state with the selected place
            // I think i need a new function to handle the location change for new Google places, since it will pass the whole place object
            // handleLocationChange(place);
            props.handleGoogleLocationChange(place);
            console.log('Place selected:', place);
            updateForm({ location: place.place_id });

            let placeData = {
                name: place["name"],
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                address: place.formatted_address,
                sport: form.sport,
                username: props.username,
                city: form.city,
                location_id: place.place_id
            };
            setFinalCourtObj(placeData);
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    console.log("selectedGooglePlace", selectedGooglePlace);

    useEffect(() => {
        setForm(prevForm => ({ ...prevForm, name: props.username, location: props.mapLocation }));
    }, [props.username, props.mapLocation]);

    // useEffect(() => {
    //     const updatedCourtNames = props.tennis_courts.map(court => court.properties.NAME_EN);
    //     setTennisCourtNames(updatedCourtNames.sort());
    //     // console.log("updatedCourtNames:", updatedCourtNames);
    // }, [props.tennis_courts])

    // useEffect(() => {
    //     const updatedCourtNames = props.basketball_courts.map(court => court.properties.NAME_EN);

    //     // console.log("props.basketball_google:", props.basketball_google);
    //     const updatedCourtNames_google = props.basketball_google.map(court => court.name);
    //     // console.log("google courts:", updatedCourtNames_google);
    //     // console.log("props.db_courts:", props.db_courts);
    //     // const updatedCourtNames_db = props.db_courts.filter(court => court.active === true && court.sport === "basketball")
    //     //     .map(court => court.name);
    //     const updatedCourtNames_db = props.db_courts.filter(court => court.sport == "basketball" && court.active == true)
    //         .map(court => court.name);
    //     // console.log("db courts:", updatedCourtNames_db);

    //     const mergedCourtNames = [...updatedCourtNames, ...updatedCourtNames_google, ...updatedCourtNames_db];
    //     setBballCourtNames(mergedCourtNames.sort());
    //     // console.log("mergedCourtNames:", mergedCourtNames);
    // }, [props.basketball_courts, props.db_courts, props.basketball_google])

    // combined Courts list:
    // useEffect(() => {
    //     const combinedCourtNames = props.combinedCourts.map(court => court.name);

    // }, [props.combinedCourts])

    useEffect(() => {
        const temp_filtered_combinedCourts = props.combinedCourts.filter((obj) => obj.sport == props.selectedSport).sort((a, b) => a.name.localeCompare(b.name));
        setFiltered_CombinedCourts(temp_filtered_combinedCourts);
        // console.log("combinedCourts on Create:", temp_filtered_combinedCourts)
    },
        [props.selectedSport, props.combinedCourts])

    // const excludeTimes = props.records.map((record) => new Date(record.datetime));
    // console.log('excludeTimes: ', excludeTimes);

    // const disabledDate = new Date('2023-03-15');
    // disabledDate.setHours(14, 0, 0, 0);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        if (currentDate.getTime() > selectedDate.getTime()) {
            return false
        } else if (locationTimes.find(datetime => datetime.getTime() === selectedDate.getTime())) {
            return false
        }
        else {
            return true
        }
        // return currentDate.getTime() < selectedDate.getTime();
    };

    // useEffect(() => {
    //     // code that should only run on mount or when props.username updates
    // }, [props.records]);

    // These methods will update the state properties.
    function updateForm(value) {
        return setForm((prev) => {
            setFormChanged(true);
            return { ...prev, ...value, name: props.username };
        });
    }

    // function handleLocationChange(value) {
    //     // const selectedValues = new Array(1).fill(value);
    //     // console.log("value.location: ", value);
    //     // const localLocationTimes = props.records
    //     //     .filter((record) => record.location === value.location)
    //     //     .map((record) => new Date(record.datetime));
    //     // console.log("locationTimes: ", localLocationTimes)
    //     setSelectedLocation(value);
    //     // setLocationTimes(localLocationTimes);
    //     props.handleLocationChange(value);
    // }

    function handleSportChange(value) {
        props.handleSportChange(value);
    }

    function handleLocationDecidedChange(value) {
        setLocationDecided(value);
        if (value === 'no') {
            // setSelectedLocation('TBD');
            setFinalCourtObj(null);
            updateForm({ location: 'TBD' });
        }
        console.log("locationDecided changed")
    }
    // const handleLocationDecidedChange = (value) => {
    //     setLocationDecided(value);
    //     console.log("locationDecided changed")
    // };

    // function handleLocationChangeSport(value) {
    //     console.log("list of locations:", sportLocations[value.sport]);
    //     return setSelectedLocation(sportLocations[value.sport]);
    // }


    // what was i thinking here?
    // function setLocationNone() {
    //     return setForm((prev) => {
    //         return { ...prev, location: "" };
    //     });
    // }

    // This function will handle the submission.
    async function onSubmit(e) {
        e.preventDefault();
        if (!props.username) {
            navigate(`/login`);
            return
        }
        // Append a name to the "players" list object inside the form state
        // console.log("form: ", form);

        if (!form.name) {
            window.alert("Username is empty.");
            return;
        }

        // if (!form.location || !form.datetime || !form.sport || !form.length) {
        if (!form.location) {
            window.alert("Invalid: please fill in the location")
            console.log("form: ", form);
            return;
        }
        if (!form.datetime) {
            window.alert("Invalid: please fill in the date and time")
            console.log("form: ", form);
            return;
        }
        if (!form.sport) {
            window.alert("Invalid: please fill in the sport")
            console.log("form: ", form);
            return;
        }
        if (!form.length) {
            window.alert("Invalid: please fill in the length of session")
            console.log("form: ", form);
            return;
        }

        if (form.location_decided == "yes" && finalCourtObj == null) {
            window.alert("Invalid: please select a location")
            return;
        }

        if (form.sport == "All") {
            window.alert("Invalid: please select a sport")
            return;
        }

        const max_no_of_players = +form.max_no_of_players

        if (isNaN(max_no_of_players) || !Number.isInteger(max_no_of_players) || max_no_of_players > 30) {
            window.alert("Invalid: max number of players must be an integer value, and must not be greater than 30");
            return;
        }

        if (form.no_of_players > form.max_no_of_players) {
            window.alert("Invalid: max number of players must be at least " + form.no_of_players);
            return;
        }



        // if (selectedLocation == 'other' && !selectedGooglePlace) {
        //     window.alert("Invalid: please select a location from the dropdown list or search for a location");
        //     return;
        // }

        // const filteredArray = filtered_CombinedCourts.filter((obj) => form.location == obj.id);
        // const location_name = filteredArray.length > 0 ? filteredArray[0].name : "To Be Decided";

        // if (selectedLocation == 'other') {
        //     form.location = selectedGooglePlace.place_id;
        //     location_name = selectedGooglePlace.name;
        //     console.log("google location", form.location, location_name)
        //     // return;
        // } else {
        //     const filteredArray = filtered_CombinedCourts.filter((obj) => form.location == obj.id);
        //     location_name = filteredArray.length > 0 ? filteredArray[0].name : "To Be Decided";
        // }
        let location_name;
        // location_name = props.selectedCourt ? props.selectedCourt.name : selectedGooglePlace.name;
        location_name = finalCourtObj ? finalCourtObj.name : "";

        // console.log("location_name:", location_name);
        const updatedForm = {
            ...form,
            players: [...form.players, props.username],
            location_name: location_name
        };
        if (props.debugMode) { console.log("updatedForm: ", updatedForm); }

        // When a post request is sent to the create url, we'll add a new record to the database.
        const newGame = { ...updatedForm };

        // await fetch("http://localhost:5000/record/add", {
        await fetch(`${process.env.REACT_APP_API_URL}/record/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newGame),
        })
            .then(response => {
                if (props.debugMode) { console.log("Response status:", response.status); }
                if (response.status === 409) {
                    // window.alert("Court is already full at requested time. Please choose another time or court.");
                    window.alert("Duplicate record found. Please choose another combination of Court and Time.");
                }
                return response.json();
            })
            .then(data => {
                if (props.debugMode) { console.log("Response data:", data); }
            })
            .catch(error => {
                window.alert(error);
                return;
            });

        // let placeData;
        // if (props.selectedCourt) {
        //     // i actually shouldn't need this block at all because i don't need to insert court into DB if seletedCourt, since that is from DB.
        //     placeData = {
        //         name: props.selectedCourt["name"],
        //         lng: props.selectedCourt.coordinates[0],
        //         lat: props.selectedCourt.coordinates[1],
        //         address: props.selectedCourt.address,
        //         sport: form.sport,
        //         username: props.username,
        //         location_id: props.selectedCourt.id
        //     };
        // }
        // else if (selectedGooglePlace && selectedGooglePlace.geometry && selectedGooglePlace.geometry.location) {
        //     // Call the lat() and lng() functions to get the actual values
        //     const lat = selectedGooglePlace.geometry.location.lat();
        //     const lng = selectedGooglePlace.geometry.location.lng();

        //     console.log("selectedGooglePlace name", selectedGooglePlace["name"]);
        //     // Prepare the data to be sent, including the latitude and longitude

        //     placeData = {
        //         name: selectedGooglePlace["name"],
        //         lng: lng,
        //         lat: lat,
        //         address: selectedGooglePlace.formatted_address,
        //         sport: form.sport,
        //         username: props.username,
        //         // city
        //         location_id: selectedGooglePlace.place_id
        //     };

        console.log("finalCourtObj:", finalCourtObj);
        if (form.location_decided == "yes") {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/add-court`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(finalCourtObj),
                });

                if (response.ok) {
                    // Handle success
                    console.log('Location added successfully to DB');
                    // window.alert("Location added successfully to database");
                } else {
                    // Handle error
                    console.log('Failed to add location to DB');
                    // window.alert("Failed to add location to database. Court for this sport may already exist.");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log("Location not added to DB");
        }
        window.alert("Game successfully created.")
        setForm({
            name: props.username, location: "", time: "", datetime: "", length: "60", sport: "",
            no_of_players: 1, max_no_of_players: "", tags: "", players: [], comments: "", level: "", public_setting: "",
            enable_message_board: "no", message_host: 2, location_decided: "yes", city: props.selectedCity, price: 0, env: "prod"
        });
        if (props.type !== "boardgames") {
            navigate("/");
        }
        else {
            navigate("/boardgames");
        }
        window.location.reload()
    }

    function handleBackClick() {
        navigate(-1);
    }

    // console.log("isAdmin:", isAdmin);
    // console.log("username:", props.username);

    // This following section will display the form that takes the input from the user.
    if (!isLoaded) return <div>Loading...</div>;
    return (
        <Flex direction={{ base: 'column-reverse', md: 'row' }}>
            <Spacer />
            <Box h="100%" w={{ base: "90%", md: '40%' }}
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '4', md: '4' }}
            >
                <h3><b>Create a New Game</b></h3>
                <br />
                <form onSubmit={onSubmit}>

                    <div className="form-group">
                        <label htmlFor="name">Host Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={props.username}
                            // onChange={(e) => updateForm({ name: e.target.value })}
                            // required
                            disabled
                            readOnly
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="sport">{props.type == "boardgames" ? "Activity" : "Sport"}</label>
                        <select
                            type="text"
                            className="form-control"
                            id="sport"
                            value={form.sport}
                            onChange={(e) => {
                                updateForm({ sport: e.target.value });
                                // handleLocationChangeSport({ sport: e.target.value });
                                // setLocationNone()
                                handleSportChange(e.target.value)
                            }}
                            required
                        >
                            <option value="" hidden selected>
                                Select Sport
                            </option>
                            {sports.map((sport) => (
                                <option key={sport} value={sport}>
                                    {sport.charAt(0).toUpperCase() + sport.slice(1)}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* <div className="form-group">
                        <label htmlFor="city">City</label>
                        <select
                            type="text"
                            className="form-control"
                            id="city"
                            value={form.city}
                            onChange={(e) => {
                                updateForm({ city: e.target.value });
                                // handleLocationChangeSport({ sport: e.target.value });
                                // setLocationNone()
                                // handleSportChange(e.target.value)
                            }}
                            required
                        >
                            <option value="" hidden selected>
                                Select Sport
                            </option>
                            {sports.map((sport) => (
                                <option key={sport} value={sport}>
                                    {sport.charAt(0).toUpperCase() + sport.slice(1)}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="city">Which City?</label>
                        <select
                            className="form-control"
                            id="city"
                            value={form.city}
                            onChange={(e) => {
                                updateForm({ city: e.target.value });
                                // props.handlePlaceChange(e.target.value);
                            }}
                            required
                            disabled
                        >
                            {/* <option value="">Which City?</option> */}
                            {cities.map((city) => (
                                <option key={city.value} value={city.value}>
                                    {city.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="location_decided">Do you know where you want to play?</label>
                        <select
                            type="text"
                            className="form-control"
                            id="location_decided"
                            value={form.location_decided}
                            onChange={(e) => {
                                updateForm({ location_decided: e.target.value });
                                handleLocationDecidedChange(e.target.value);
                            }}
                            required
                        >
                            <option key="yes" value="yes"> Yes</option>
                            <option key="no" value="no"> Not sure yet</option>
                        </select>
                    </div>


                    {/* Rebuilding Location drop-down list by filtering combinedCourts list */}
                    {/* {false && locationDecided === "yes" &&
                        (<>
                            <div className="form-group">
                                <label htmlFor="location">Location</label>
                                <select
                                    type="text"
                                    className="form-control"
                                    id="location"
                                    value={form.location}
                                    onChange={(e) => {
                                        updateForm({ location: e.target.value });
                                        handleLocationChange(e.target.value)
                                    }}
                                    required
                                >
                                    <option value="" hidden selected>
                                        Select Location
                                    </option>
                                    {[...filtered_CombinedCourts, { id: 'other', name: 'Other' }].map(court =>
                                        <option key={court.id} value={court.id}>
                                            {court.name}
                                        </option>
                                    )}

                                </select>
                            </div>

                            <div style={{ fontSize: '12px' }}><b>Address:</b> {filtered_CombinedCourts.filter(court => selectedLocation == court.id).length > 0 ? filtered_CombinedCourts.filter(court => selectedLocation == court.id)[0].address : ""}
                                
                            </div>
                        </>
                        )
                    } */}
                    {/* <b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b> */}

                    {/* {selectedLocation == 'other' && ( */}
                    {locationDecided === "yes" &&
                        <LocationSelector
                            isLoaded={isLoaded}
                            locationDecided={locationDecided}
                            // newLocation={newLocation}
                            hongKongBounds={hongKongBounds}
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            selectedGooglePlace={selectedGooglePlace}
                            selectedCourt={props.selectedCourt}
                            finalCourtObj={finalCourtObj}
                            env={props.env}
                        />
                    }
                    {/* ) */}
                    {/* } */}

                    <div className="form-group">
                        <label htmlFor="datetime">Date and Start Time</label>
                        <DatePicker
                            selected={form.datetime}
                            onChange={(datetime) => updateForm({ datetime })}
                            minDate={minDate}
                            maxDate={maxDate}
                            // excludeTimes={excludeTimes.filter(filterExcludeTimes)}
                            // excludeTimes={excludeTimes}
                            // disabledDates={[disabledDate]}
                            // disabledDates={['2023-03-29', '2015-08-26']}
                            filterTime={filterPassedTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            // dateFormat="yyyy-MM-dd HH:mm"
                            className="form-control"
                            required
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="length">Session Length</label>
                        <select
                            type="text"
                            className="form-control"
                            id="length"
                            value={form.length}
                            onChange={(e) => {
                                updateForm({ length: e.target.value });
                            }}
                            required
                        >
                            <option key="30min" value="30"> 30 mins</option>
                            <option key="1hour" value="60"> 1 hour</option>
                            <option key="1.5hour" value="90"> 1.5 hour</option>
                            <option key="2hour" value="120"> 2 hours</option>
                            <option key="2.5hour" value="150"> 2.5 hours</option>
                            <option key="3hour" value="180"> 3 hours</option>
                            <option key="3hour+" value="181"> More than 3 hours</option>
                        </select>
                    </div>


                    <div className="form-group">
                        <label htmlFor="no_of_players">No. of Players Currently</label>
                        <input
                            type="number"
                            className="form-control"
                            id="no_of_players"
                            value={form.no_of_players}
                            onChange={(e) => updateForm({ no_of_players: e.target.value })}
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="no_of_players">Max No. of Players</label>
                        <input
                            type="number"
                            className="form-control"
                            id="max_no_of_players"
                            value={form.max_no_of_players}
                            onChange={(e) => updateForm({ max_no_of_players: e.target.value })}
                            required
                        />
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="no_of_players">Price?</label>
                        <input
                            type="text"
                            className="form-control"
                            id="price"
                            value={form.price}
                            onChange={(e) => updateForm({ price: e.target.value })}
                        // required
                        />
                    </div> */}
                    <label htmlFor="no_of_players">Price per player <span style={{ fontSize: "9px" }}>(Let's keep it free by default :) )</span>&nbsp;&nbsp;</label>
                    <Stack direction="row" align="center">
                        <Text>$</Text>
                        <NumberInput
                            value={form.price}
                            onChange={(valueString) => updateForm({ price: parseInt(valueString, 10) })}
                            min={0}
                            max={50}
                            step={1}
                        >
                            <NumberInputField id="price" className="form-control" />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <Text>HKD</Text>
                    </Stack>

                    <div className="form-group">
                        <label htmlFor="public_setting">Open to Public? <span style={{ fontSize: "9px" }}>(private games will not be displayed on the main page, only accessible via URL)</span>&nbsp;&nbsp;</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="public_setting"
                                id="public"
                                value="public"
                                checked={form.public_setting === "public"}
                                onChange={(e) => updateForm({ public_setting: e.target.value })}
                            />
                            <label htmlFor="public_setting" className="form-check-label">Public</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="public_setting"
                                id="private"
                                value="private"
                                checked={form.public_setting === "private"}
                                onChange={(e) => updateForm({ public_setting: e.target.value })}
                            />
                            <label htmlFor="public_setting" className="form-check-label">Private</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="enable_message_board">Message Board? <span style={{ fontSize: "9px" }}>(Premium feature: note all participants can see and send messages)</span>&nbsp;&nbsp;</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="enable_message_board"
                                id="yes"
                                value={true}
                                checked={form.enable_message_board === true}
                                onChange={(e) => updateForm({ enable_message_board: e.target.value === 'true' })}
                            />
                            <label htmlFor="enable_message_boarding" className="form-check-label">Enable</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="enable_message_board"
                                id="no"
                                value={false}
                                checked={form.enable_message_board === false}
                                onChange={(e) => updateForm({ enable_message_board: e.target.value === 'true' })}
                            />
                            <label htmlFor="enable_message_board" className="form-check-label">Disable</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="message_host">Message Host Settings <span style={{ fontSize: "9px" }}>(Determine who can message you)</span>&nbsp;&nbsp;</label>
                        <select
                            type="text"
                            className="form-control"
                            id="message_host"
                            value={form.message_host}
                            onChange={(e) => {
                                updateForm({ message_host: e.target.value });
                            }}
                            required
                        >
                            <option key="Disable" value={0}> Disable</option>
                            <option key="Enable for all users" value={1}> Enable for all users</option>
                            <option key="Enable for game participants only" value={2}> Enable for game participants only</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tags">Tags</label>
                        <input
                            type="text"
                            className="form-control"
                            id="tags"
                            value={form.tags}
                            onChange={(e) => updateForm({ tags: e.target.value })}
                            maxLength={30}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="skill">Skill Level:&nbsp;&nbsp;</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelBeginner"
                                value="Beginner"
                                checked={form.level === "Beginner"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelBeginner" className="form-check-label">Beginner</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelIntermediate"
                                value="Intermediate"
                                checked={form.level === "Intermediate"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelIntermediate" className="form-check-label">Intermediate</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelAdvanced"
                                value="Advanced"
                                checked={form.level === "Advanced"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelAdvanced" className="form-check-label">Advanced</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="NA"
                                value="NA"
                                checked={form.level === "NA"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelAdvanced" className="form-check-label">N/A</label>
                        </div>

                    </div>

                    <div className="form-group">
                        <label htmlFor="comments">Comments</label>
                        <input
                            type="textarea"
                            className="form-control"
                            id="comments"
                            value={form.comments}
                            onChange={(e) => updateForm({ comments: e.target.value })}
                            maxLength={150}
                        />
                    </div>

                    {(isAdmin || props.username == 'sporty_buddy') &&
                        <div className="form-group">
                            <label htmlFor="env">Env?</label>
                            <select
                                className="form-control"
                                id="env"
                                value={form.env}
                                onChange={(e) => updateForm({ env: e.target.value })}
                            // required
                            >
                                <option value="dev">dev</option>
                                <option value="prod">prod</option>
                            </select>
                        </div>
                    }

                    <br />
                    <Box display="flex">
                        <Button
                            leftIcon={<ArrowBackIcon />}
                            variant='outline'
                            colorScheme='green'
                            onClick={handleBackClick}
                            mr={2}
                        >
                            Back
                        </Button>

                        <Button
                            onClick={onSubmit}
                            colorScheme={(formChanged && props.username) ? "blue" : "gray"}
                        // isDisabled={!formChanged || !props.username}
                        >
                            {/* Create Game */}
                            {props.username ? "Create Game" : "Login to Create"}
                        </Button>
                    </Box>
                </form>
                {/* </div> */}
            </Box>
            {/* <div width="100%"> */}
            <Spacer />
            {/* <Box
                bg="white"
                // borderRadius="md"
                // boxShadow="md"
                // p={4}
                // h={800}
                h="100%"
                w={{ md: '50%' }}
            >
                <Map selectedLocation={selectedLocation} selectedRegion={["NUS"]} />
            </Box> */}
            <Spacer />
        </Flex>
    );
}