import React, { useEffect, useState, useMemo } from "react";
import { Box, Badge, StarIcon, Button, Flex, Spacer, Text, VStack, Input } from '@chakra-ui/react'
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useRef } from 'react';
import Filter from 'bad-words';

// const sample_messages = [
//     {
//         user: "Alice",
//         message: "Hello, everyone!",
//         dateTime: "2023-12-10T18:05:00"
//     },
//     {
//         user: "Bob",
//         message: "I'm excited for the game!",
//         dateTime: "2023-12-10T18:10:00"
//     }]

export default function MessageBoard(props) {
    const [newMessage, setNewMessage] = useState('');
    const [allMessages, setAllMessages] = useState([])
    const [hasMessageBoard, setHasMessageBoard] = useState(false)

    // console.log("Messages game_id:", props.game_id);
    // console.log("host", props.host)

    const handleAddMessage = async () => {
        // Implement your logic to add the new message to the message list or submit it to the server.
        // You can access the new message text from the `newMessage` state.
        // e.preventDefault();
        // console.log('New message:', newMessage);
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/messages/${props.game_id.toString()}`);

        if (!newMessage) {
            window.alert("Message is empty.")
            return;
        }
        // Create a new instance of the profanity filter
        const filter = new Filter();

        if (filter.isProfane(newMessage)) {
            window.alert("Message contains inappropriate content.");
            return;
        }
        const newMessageObject = {
            name: props.username,
            dateTime: new Date(),
            message: newMessage
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/messages/update/${props.game_id}`, {
            method: "POST",
            body: JSON.stringify(newMessageObject),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (response.ok) {
            const updatedMessages = await response.json();

            // Update the messages state with the updated messages
            setAllMessages(updatedMessages.messages);
            setNewMessage('');
            // Remove focus from the input box
            inputRef.current.blur();
            fetchMessages()
        }
        else {
            const errorData = await response.json();
            const errorMessage = errorData.error;
            console.error('Error:', errorMessage);
            window.alert("Error: Maximum daily # of messages reached (3). Please wait until the next day to message.");
        }
        // Fetch the updated messages
        // await fetch(`${process.env.REACT_APP_API_URL}/messages/${props.game_id.toString()}`);

    };

    async function fetchMessages() {
        // const id = params.id.toString();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/messages/${props.game_id.toString()}`);

        if (!response.ok) {
            // console.log("1")
            const message = `An error has occurred: ${response.statusText}`;
            window.alert(message);
            return;
        }

        const messages = await response.json();
        // if (!messages) {
        //     // console.log("2")
        //     window.alert(`Record with id ${props.game_id} not found`);
        //     // navigate("/");
        //     return;
        // }
        if (!messages || !messages.messages) {
            // Handle the case where the game does not have a message board
            // Set a flag or state variable to indicate that the game does not have a message board
            // window.alert(`Record with id ${props.game_id} not found`);
            // console.log(`Message board with game id ${props.game_id} not found`);
            setHasMessageBoard(false);
            return;
        }

        setHasMessageBoard(true);
        setAllMessages(messages.messages);
        // console.log("Messages", messages);
        return messages;
    }
    // retrieve message board messages
    useEffect(() => {
        fetchMessages();
        return;
    }, [props.game_id]);

    const inputRef = useRef(null);

    useEffect(() => {
        // Update the flag in the parent component
        props.handleMessageBoardUpdate(hasMessageBoard);
    }, [hasMessageBoard]);

    return (
        <Box
            borderWidth="0px"
            borderRadius="sm"
            p={0}
            maxW="400px"
            w="100%"
            mx={0}
            my={0}
        >
            {hasMessageBoard ? (
                <>
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                        Message Board
                    </Text>

                    <VStack spacing={3} align="start">
                        {allMessages.map((message, index) => (
                            <Box key={index} borderWidth="1px" borderRadius="md" p={2}>
                                <Text fontWeight="bold">{`${message.user.substring(0, 3)}***`} {(message.user == props.host) && "(Host👑)"}</Text>
                                <Text>{message.message}</Text>
                                <Text fontSize="sm" color="gray.500">
                                    {new Date(message.dateTime).toLocaleString()}
                                </Text>
                            </Box>
                        ))}
                    </VStack>
                    <Input
                        placeholder="Type a new message"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        mt={4}
                        ref={inputRef} // Add the ref to the input element
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleAddMessage();
                            }
                        }}
                        maxLength={80}
                    />
                    <Button colorScheme="blue" onClick={handleAddMessage} mt={2}>
                        Add Message
                    </Button>
                </>
            ) :
                <Box p="4" mt="4" rounded="md" shadow="md">
                    <Text><h4>This game does not have Message Boards enabled.</h4></Text>
                </Box>
            }
        </Box>
    );
};