import React, { useState, useEffect } from 'react';
import { Box, Card, Flex, Avatar, Text, Button } from '@chakra-ui/react';
import { MdMail } from 'react-icons/md';
import { IoMdRemoveCircle } from 'react-icons/io';
import player_avatar1 from "../player-avatar1.png"
import bball_avatar1 from "../avatars/bball-shooter-male.jpg"
import tennis_avatar1 from "../avatars/tennis-male.jpg"
import vball_avatar1 from "../avatars/vball-female.jpg"

const PlayersTab = ({ form, username, updatePlayers, setRefreshTrigger }) => {
    const [playerAvatars, setPlayerAvatars] = useState({});

    useEffect(() => {
        const fetchPlayerAvatars = async () => {
            const avatarPromises = form.players.map(async (player) => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/avatar?username=${encodeURIComponent(player)}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const data = await response.json();
                return { [player]: data.avatar }; // Assuming the avatar URL/identifier is returned under 'avatar'
            });

            const avatars = await Promise.all(avatarPromises);
            const avatarsMap = avatars.reduce((acc, curr) => ({ ...acc, ...curr }), {});
            setPlayerAvatars(avatarsMap);
        };

        fetchPlayerAvatars();
    }, [form.players]); // Re-run this effect if the list of players changes

    // console.log('Player avatars:', playerAvatars);

    const handleRemovePlayer = async (player) => {
        console.log('Attempting to remove player:', player);
        // Additional logic to remove the player
        const isConfirmed = window.confirm(`Are you sure you want to remove ${player}?`);
        if (!isConfirmed) {
            console.log('Player removal cancelled.');
            return; // Exit the function if the user cancels
        }
        try {
            // Call the removePlayer function and wait for it to complete
            // await removePlayer(player);
            await fetch(`${process.env.REACT_APP_API_URL}/record/removePlayer`, {
                method: "POST",
                // body: JSON.stringify({ username: props.username, game_id: gameId }),
                body: JSON.stringify({ player: player, game_id: form.game_id }),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            console.log('Player removed successfully:', player);
            const updatedPlayers = form.players.filter(p => p !== player);
            updatePlayers(updatedPlayers);
            // Optionally, update the state or UI here to reflect the removal
        } catch (error) {
            // Handle any errors that occur during the call
            console.error('Error removing player:', player, error);
        }
    };

    const handleRemoveGuest = async (guest_name) => {
        console.log('Attempting to remove guest player:', guest_name);
        // Additional logic to remove the player
        const isConfirmed = window.confirm(`Are you sure you want to remove guest player ${guest_name}?`);
        if (!isConfirmed) {
            console.log('Guest player removal cancelled.');
            return; // Exit the function if the user cancels
        }
        try {
            // Call the removePlayer function and wait for it to complete
            // await removePlayer(player);
            await fetch(`${process.env.REACT_APP_API_URL}/record/removeGuest`, {
                method: "POST",
                // body: JSON.stringify({ username: props.username, game_id: gameId }),
                body: JSON.stringify({ guest_name: guest_name, game_id: form.game_id }),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            console.log('Guest player removed successfully:', guest_name);
            setRefreshTrigger(prev => !prev); // Toggle the boolean value
            // const updatedGuests = form.players.filter(p => p !== player);
            // updatePlayers(updatedPlayers);
            // Optionally, update the state or UI here to reflect the removal
        } catch (error) {
            // Handle any errors that occur during the call
            console.error('Error removing player:', guest_name, error);
        }
    };

    return (
        <Box
            p='40px'
            mt='4'
            rounded='md'
            shadow='md'
            maxHeight='600px'
            overflow='auto'
        >
            {form.players && form.players.length > 0
                ? form.players.map(player => (
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        key={player}
                        marginY={2}
                    >
                        <Flex align="center" justify="space-between" w="100%">
                            {/* <Avatar name={player} src={player_avatar1} /> */}
                            <Avatar
                                name={player}
                                src={playerAvatars[player]}
                                imgProps={{ objectFit: "contain" }} // Adjusts how the image fits within the avatar box
                            />
                            {/* <Text px='5' fontWeight='bold'>{`${player.substring(0, 3)}*****`}</Text> */}
                            <Text px='5' fontWeight='bold'>{`${player}`}</Text>
                            <Flex>
                                {form && form.name === username ? (
                                    <>
                                        {/* <Button variant='solid' colorScheme='blue' mr='2'>
                                            <MdMail />
                                        </Button> */}
                                        <Button variant='solid' colorScheme='red' onClick={() => handleRemovePlayer(player)}>
                                            <IoMdRemoveCircle />
                                        </Button>
                                    </>
                                ) : <></>}
                            </Flex>
                        </Flex>
                    </Card>
                ))
                : <Text px='4'>There are no registered players currently.</Text>
            }
            {form.guests && form.guests.length > 0
                ? form.guests.map(guest => (
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        key={guest.guest_name}
                    >
                        <Flex align="center" justify="space-between" w="100%">
                            <Avatar name={guest.guest_name} src={player_avatar1} />
                            <Text px='5' fontWeight='bold'>{guest.guest_name}</Text>
                            <Flex>
                                {form && form.name === username ? (
                                    <>
                                        {/* <Button variant='solid' colorScheme='blue' mr='2' >
                                    <MdMail />
                                </Button> */}
                                        <Button variant='solid' colorScheme='red' onClick={() => handleRemoveGuest(guest.guest_name)}>
                                            <IoMdRemoveCircle />
                                        </Button>
                                    </>
                                ) : <></>}
                            </Flex>
                        </Flex>
                    </Card>
                ))
                : <></>
            }
        </Box>
    );
};

export default PlayersTab;