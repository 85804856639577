import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
    <GoogleOAuthProvider clientId="26548253878-fk9jat1pocai7gm6trb12bd6h0ujg4ld.apps.googleusercontent.com">
        <React.StrictMode>
            <ChakraProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ChakraProvider>
        </React.StrictMode>
    </GoogleOAuthProvider>,
    document.getElementById("root")
);