import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useState, useMemo } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext"
import { Table, Tr, Td, Thead, Tbody, TableCaption, Button, ButtonGroup, Box } from "@chakra-ui/react"
import { useParams, useNavigate } from "react-router";
import { joinGame, leaveGame } from './joinGame';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Tag } from 'primereact/tag';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import soccer_icon from '../soccer_icon.png'
import hockey_icon from '../hockey_icon.png'
import all_sports_icon from '../all_sports_icon.png'
// import { Route, Routes } from "react-router-dom";
// import Edit from "./edit";
// import RecordTable from "./components/table";


export default function Test2(props) {
    const [records, setRecords] = useState([]);
    const [isLoggedin, setIsLoggedin] = useState(!!props.username);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    })
    const [showAllGames, setShowAllGames] = useState(false);

    const today = new Date();
    // console.log("PROPS data: ", props.data);
    // Sorting the props.data by datetime
    // props.data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))

    const navigate = useNavigate();

    // const data2 = props.data;
    // console.log("data: ", props.data);
    let public_data = props.data.filter(item => item.public_setting === "public");
    if (props.type == "boardgames") {
        public_data = public_data.filter(item => item.sport == "board games")
    } else {
        public_data = public_data.filter(item => item.sport !== "board games")
    }
    // console.log("props.type", props.type)
    // public_data = public_data.filter(item => item.sport == "tennis");
    const data2 = props.mapLocation ? public_data.filter(item => item.location === props.mapLocation) : public_data;

    const now = new Date();
    const data3 = data2.filter(rec => new Date(rec.datetime) >= now);

    // const boardgames_data = data2.filter(record=>record.sport=="board games");
    // const boardgames_data_future = boardgames_data.filter(rec => new Date(rec.datetime) >= now);
    // useEffect(() => {
    //     // if (isLoaded) {
    //     async function getRecords() {
    //         const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);
    //         if (!response.ok) {
    //             const message = `An error occurred: ${response.statusText}`;
    //             window.alert(message);
    //             return;
    //         }
    //         const records = await response.json();
    //         setRecords(records);
    //         console.log("recordsss", records);
    //     }
    //     getRecords();
    //     // }
    // }, [records.length]);

    // console.log("test2 records: ", props.data)
    function handleEditClick(e, id) {
        e.preventDefault();
        navigate(`/view/${id}`);
        // navigate(`/edit/${id}`, { state: { handleJoinClick: props.handleJoinClick } });
    }

    // console.log("props.username: ", props.username)

    function get_icon(sport) {
        // let icon;
        switch (sport) {
            case 'tennis':
                return tennis_icon;
            case 'basketball':
                return basketball_icon;
            case 'football':
                return soccer_icon;
            case 'hockey':
                return hockey_icon;
            default:
                return all_sports_icon;
        }
    }

    const toggleShowAllGames = () => {
        setShowAllGames(prevValue => !prevValue);
    }

    return (
        <div className="App">
            <Box display="flex"
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '0', md: '0' }}>
                {/* <div style={{ textAlign: 'center' }}> */}
                <Button
                    onClick={toggleShowAllGames}
                    marginRight={2}
                    fontSize="xs"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                >
                    {showAllGames ? 'Show Upcoming Games Only' : 'Show All Previous Games'}

                </Button>
                <div>
                    <InputText
                        placeholder="Filter by any field"
                        onInput={(e) =>
                            setFilters({
                                global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS },
                            })}
                    />
                </div>
            </Box>
            <br />
            <DataTable value={showAllGames ? data2 : data3} sortMode="multiple" tableStyle={{ minWidth: '50rem' }} filters={filters}
                paginator
                rows={20}
                rowsPerPageOptions={[5, 20, 50]}
                totalRecords={100}
            // defaultSortField="datetime" defaultSortOrder={1}
            >
                <Column field="game_id" header="ID" sortable ></Column>
                {/* <Column field="location_name" header="Location" sortable ></Column> */}
                {/* <Column
                    header="Location"
                    sortable
                    body={(rowData) =>
                        rowData.location_name || rowData.location
                    }
                ></Column> */}
                <Column
                    // field="capacity" 
                    header="Location"
                    sortable
                    body={(rowData) =>
                        rowData.location_name === "To Be Decided" ? (
                            <><Tag severity='info' value={rowData.location_name}></Tag></>
                        ) : (
                            rowData.location_name || rowData.location
                        )}
                ></Column>

                <Column field="sport" header="Sport" sortable
                    body={(rowData) => {
                        const icon = get_icon(rowData.sport);
                        return (
                            <div>
                                {rowData.sport}
                                <img src={icon} alt={rowData.sport} style={{ width: '20px', marginRight: '5px' }} />
                            </div>
                        )
                    }} />

                <Column field="tags" header="Tags" sortable
                    body={(rowData) =>
                        rowData.tags ?
                            (<Tag severity="warning" value={rowData.tags}></Tag>) :
                            null
                    }
                ></Column>
                {/* <Column field="no_of_players" header="Capacity" sortable></Column> */}
                <Column field="capacity" header="Capacity" sortable
                    body={(rowData) =>
                        rowData.isFull ? (
                            <>{rowData.capacity} <Tag severity="danger" value="Full"></Tag></>
                        ) : (
                            <>{rowData.capacity}</>
                        )}
                >
                </Column>
                <Column field="datetime" header="Date" sortable
                    body={(rowData) => {
                        const date = new Date(rowData.datetime);
                        return (
                            (date.getDate() === today.getDate() &&
                                date.getMonth() === today.getMonth() &&
                                date.getFullYear() === today.getFullYear()) ?
                                (<> {date.toISOString().split('T')[0]}
                                    <Tag severity="success" value="TODAY" marginleft="5px"></Tag></>)
                                :
                                date.toISOString().split('T')[0]
                        )
                    }}></Column>
                {/* <Column field="datetime" header="Date" sortable
                    body={(rowData) => {
                        const date = new Date(rowData.datetime);
                        return (
                            (date.getDate() === today.getDate() &&
                                date.getMonth() === today.getMonth() &&
                                date.getFullYear() === today.getFullYear()) ?
                                (<> {date.toLocaleDateString([], { weekday: 'short', month: 'short', day: 'numeric' })}
                                    <Tag severity="success" value="TODAY" marginLeft="5px"></Tag></>)
                                :
                                date.toLocaleDateString([], { weekday: 'short', month: 'short', day: 'numeric' })
                        )
                    }}></Column> */}

                <Column field="datetime" header="Time" sortable
                    body={(rowData) => {
                        const startTime = new Date(rowData.datetime);
                        // const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add 1 hour to get the end time
                        const endTime = new Date(startTime.getTime() + rowData.length * 60 * 1000); // Add 1 hour to get the end time
                        return (
                            `${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}-${endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}`
                        )
                    }}></Column>
                <Column field="name" header="Host" sortable
                    body={(rowData) => {
                        return (
                            <div>
                                {/* {rowData.name} */}
                                {`${rowData.name.substring(0, 3)}*****`}
                                {rowData.name === props.username &&
                                    <Tag severity="info" value="HOST" marginleft="5px">👑</Tag>}
                            </div>
                        )
                    }} />

                <Column field="" header="Actions"
                    body={(rowData) => (
                        <div>
                            {rowData.isJoinable && (
                                <Button
                                    colorScheme='teal'
                                    label="Join"
                                    mr={2}
                                    onClick={(e) => {
                                        // handleEdit(e, rowData._id)
                                        props.handleJoinClick(e, rowData._id, rowData.game_id);
                                    }}
                                >Join </Button>
                            )}
                            {rowData.alreadyJoined && (
                                <Button
                                    colorScheme='red'
                                    label="Leave"
                                    mr={2}
                                    onClick={(e) => {
                                        props.handleLeaveClick(e, rowData._id, rowData.game_id);
                                    }}
                                >Leave</Button>
                            )}
                            {/* {rowData.isFull && <span className="p-tag p-tag-danger">Full</span>} */}
                            {rowData.name === props.username ? (
                                <Button
                                    label="Edit"
                                    colorScheme="yellow"
                                    variant="outline"
                                    color="gray.500"
                                    onClick={(e) => {
                                        handleEditClick(e, rowData._id)
                                    }}
                                >
                                    Edit
                                </Button>)
                                : (<Button
                                    label="View"
                                    onClick={(e) => {
                                        handleEditClick(e, rowData._id)
                                    }}
                                >
                                    View
                                </Button>)
                            }
                            {/* <Edit username={props.username} records={props.data} /> */}
                        </div>
                    )}
                ></Column>
            </DataTable>
        </div >
    )
}

