import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    FormHelperText,
    Select,
    CheckboxGroup,
    Divider,
    AbsoluteCenter,
    Center
} from '@chakra-ui/react';
import { useNavigate } from "react-router";
import nadal_bg from '../images/nadal_bg.png'
// import { GoogleLogin } from 'react-google-login';
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import RegisterGoogle from "./register_google"


// async function loginUser(credentials) {
//     return fetch('http://localhost:5000/login', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(credentials)
//     })
//         .then(data => data.json())
// }

const options = [
    "Basketball",
    "Football",
    "Soccer",
    "Tennis",
    "Swimming",
    "Running",
];

export default function Register2({ setToken }) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();

    const [selectedSports, setSelectedSports] = useState([]);
    const [customSport, setCustomSport] = useState([]);

    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState(null);
    // Google Auth states:
    const [g_username, setG_username] = useState();
    const [g_password, setG_password] = useState();
    const [g_firstName, setG_firstName] = useState();
    const [g_lastName, setG_lastName] = useState();
    const [g_email, setG_email] = useState();

    const navigate = useNavigate();

    function validatePassword(password) {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}$/;
        return passwordRegex.test(password);
    }

    function validateUsername(username) {
        const usernameRegex = /^[a-zA-Z0-9_]{2,20}$/;
        return usernameRegex.test(username);
    }

    async function onSubmit(e) {
        // console.log("e:", e)
        e.preventDefault();
        // console.log("SUBMIT")

        //validate username
        if (!validateUsername(username)) {
            window.alert("Username must only contain letters, numbers, or '_', and must be between 2 and 20 characters.");
            return;
        }

        // Validate password
        if (!validatePassword(password)) {
            window.alert("Password must contain at least 1 letter, at least 1 number, and be at least 5 characters long.");
            return;
        }

        if (!username || !password || !firstName || !lastName || !email) {
            window.alert("Please fill in all necessary fields.")
            return;
        }

        // When a post request is sent to the create url, we'll add a new record to the database.
        const newUser = { username, password, firstName, lastName, email };
        // console.log("newUser: ", newUser);

        // await fetch("http://localhost:5000/record/add", {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newUser),
        })
            .catch(error => {
                window.alert(error);
                return;
            });
        const data = await response.json(); // parse response data into JSON object
        // console.log("Response from server:", response.status, data);
        if (response.status === 409) {
            window.alert("Username already exists.")
        }
        if (response.status === 200) {
            window.alert("Success: New user '" + username + "' created.");
            // console.log("NEW USER", username)
            navigate("/login");
        }
    }

    const handleSportSelection = (selected) => {
        if (selected.length <= 3) {
            setSelectedSports(selected);
        }
    };

    async function registerGoogle() {
        // console.log("Registering with Google");
        const newUser = { g_username, password, g_firstName, g_lastName, g_email };
        // console.log("newUser: ", newUser);
        // await fetch("http://localhost:5000/record/add", {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newUser),
        })
            .catch(error => {
                window.alert(error);
                return;
            });
        const data = await response.json(); // parse response data into JSON object
        // console.log("Response from server:", response.status, data);
        if (response.status === 409) {
            window.alert("Username already exists.")
        }
        if (response.status === 200) {
            window.alert("Success: New user '" + username + "' created.");
            // console.log("NEW USER", username)
            navigate("/login");
        }
    }


    // const responseGoogle = (response) => {
    //     // Handle the response when the user signs in with Google
    //     console.log(response);
    //     if (response.error) {
    //         // Handle error
    //         console.log('Google Error:', response.error);
    //     } else {
    //         // Handle successful authentication
    //         console.log('Google Success:', response);
    //         // You can access the access token and user information from the 'response' object
    //     }
    // };

    const responseMessage = (response) => {
        // console.log("Success:", response);
    };
    const errorMessage = (error) => {
        // console.log("Error:", error);
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // console.log("Successssss:", codeResponse)
            setUser(codeResponse)
            // console.log("checkpoint1")
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    // const handleSignInClick = async () => {
    //     login().then(() => {
    //         setUserName(profile.name);
    //         console.log("setUserName():", profile.name);
    //         setPassword("a12345");
    //         setFirstName(profile.given_name);
    //         setLastName(profile.family_name);
    //         setEmail(profile.email);
    //         // submitForm();
    //         registerGoogle()
    //     }).catch(error => {
    //         console.log('Login Failed:', error);
    //     })
    // };


    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        // console.log("res: ", res)
                        setProfile(res.data);
                        // console.log("profile:", res.data);
                        setG_username(res.data.name);
                        // console.log("checkpoint2")
                        setG_password("a12345");
                        setG_firstName(res.data.given_name);
                        setG_lastName(res.data.family_name);
                        setG_email(res.data.email);
                        // console.log("all google states:", g_username, g_password, g_firstName, g_lastName);
                        // console.log("all google states:", res.data.name, res.data.given_name, res.data.family_name, res.data.email);
                    })
                    .catch((err) => console.log(err));
                // console.log("user:", user)
                // setUserName(profile.name);
                // console.log("setUserName():", profile.name);
            }
        },
        [user]
    );

    // I need separate states for username, firstName, lastName, password for GOOGLE login function.... 
    // Since these fields get updated on every keystroke. Google and type in register need to be separate flows competely.
    // useEffect(
    //     () => {
    //         if (g_username && g_password && g_firstName && g_lastName) {
    //             console.log("all google states:", g_username, g_password, g_firstName, g_lastName);
    //             registerGoogle();
    //         }
    //     }, []);
    // }, [g_username, g_password, g_firstName, g_lastName]);




    // useEffect(
    //     () => {
    //         if (profile) {
    //             setUserName(profile.name);
    //             console.log("setUserName():", profile.name);
    //             setPassword("a12345");
    //             setFirstName(profile.given_name);
    //             setLastName(profile.family_name);
    //             setEmail(profile.email);
    //             // submitForm();
    //             registerGoogle();
    //         }
    //     },
    //     [profile]
    // )


    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    // console.log("profile: ", profile);

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            // bg={useColorModeValue('gray.50', 'gray.800')}>
            bg={`url(${nadal_bg}) no-repeat center center fixed`}
            bgSize={{ base: "300%", md: 'cover' }}
            backgroundPosition={{ base: '60% 25%', md: 'center center' }}
        >
            <Flex
                // bg={"whiteAlpha.500"}
                // position={"absolute"}
                // top={0}
                // left={0}
                minH={'100vh'}
                align={'center'}
                justify={'center'}
                w={"100%"}
                h={"100%"}
            >

                <Stack
                    spacing={8}
                    mx={'auto'}
                    maxW={'lg'}
                    py={12}
                    px={6}
                    bgColor="rgba(255, 255, 255, 0.5)"
                    rounded={'lg'}
                >
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>Register</Heading>
                        <Text fontSize={'lg'} color={'gray.1000'}>
                            <b>Get in the game with SportMatchNUS 🎾🏀⚽🏑🏸</b>
                        </Text>
                    </Stack>
                    {/* <div className="login-wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}> */}
                    {/* <h1>Please Log In</h1> */}
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('white', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            {/* <form onSubmit={handleSubmit}> */}
                            <form onSubmit={onSubmit}>
                                <Box display="flex">
                                    <FormControl id="firstName"
                                        mr={5}>
                                        <FormLabel>First Name *</FormLabel>
                                        <Input type="text" onChange={e => setFirstName(e.target.value)} />
                                    </FormControl>

                                    <FormControl id="lastName">
                                        <FormLabel>Last Name *</FormLabel>
                                        <Input type="text" onChange={e => setLastName(e.target.value)} />
                                    </FormControl>
                                </Box>

                                <FormControl id="email">
                                    <FormLabel>E-mail *</FormLabel>
                                    <Input type="email" onChange={e => setEmail(e.target.value)} />
                                </FormControl>

                                <FormControl id="username">
                                    <FormLabel>Username *</FormLabel>
                                    <Input type="text" onChange={e => setUsername(e.target.value)} required />
                                </FormControl>

                                <FormControl id="password">
                                    <FormLabel>Password *</FormLabel>
                                    <Input type="password" onChange={e => setPassword(e.target.value)} required />
                                    <FormHelperText>Password must contain at least 1 letter, 1 number, and 5+ characters.</FormHelperText>
                                </FormControl>
                                <Box mt={4}>
                                    {/* <FormControl>
                                        <FormLabel fontSize="sm">Which sport(s) are you most interested in? (up to 3)</FormLabel>
                                        <CheckboxGroup
                                            value={selectedSports}
                                            onChange={handleSportSelection}
                                        >
                                            <Stack direction="column">
                                                <Checkbox value="basketball">Basketball</Checkbox>
                                                <Checkbox value="tennis">Tennis</Checkbox>
                                                <Checkbox value="badminton">Badminton</Checkbox>
                                                <Checkbox value="soccer">Soccer</Checkbox>
                                                <Checkbox value="volleyball">Volleyball</Checkbox>
                                                <Checkbox value="others">
                                                    Others
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter sport name"
                                                        value={customSport}
                                                        onChange={(e) => setCustomSport(e.target.value)}
                                                    />
                                                </Checkbox>
                                            </Stack>
                                        </CheckboxGroup>
                                    </FormControl> */}
                                </Box>

                                <Stack spacing={10}>
                                    <Stack
                                        direction={{ base: 'column', sm: 'row' }}
                                        align={'start'}
                                        justify={'space-between'}>

                                    </Stack>
                                    <Button
                                        type="submit"
                                        // bg={'blue.400'}
                                        // color={'white'}
                                        colorScheme='blue'
                                        variant='outline'
                                        _hover={{
                                            bg: 'blue.500',
                                        }}>
                                        Register
                                    </Button>
                                </Stack>
                                <Box position='relative'>
                                    <Divider />
                                    <AbsoluteCenter bg='white' px='3'>
                                        or
                                    </AbsoluteCenter>
                                </Box>
                                <RegisterGoogle />
                            </form>
                            <Center><Flex style={{ fontSize: 'small' }}><p>Already have an account? Login <b><a href="/login">here</a></b>.🏀</p></Flex></Center>
                        </Stack>
                        {/* <div> */}


                        {/* <useGoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                        {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                        {/* </div> */}
                        {/* {profile ? (
                            <div>
                                <img src={profile.picture} alt="user image" />
                                <h3>User Logged in</h3>
                                <p>Name: {profile.name}</p>
                                <p>Email Address: {profile.email}</p>
                                <br />
                                <br />
                                <button onClick={logOut}>Log out</button>
                            </div>
                        ) : (
                            // <button onClick={() => login()}>Sign in with Google 🚀 </button>
                            <button onClick={login}>Sign in with Google 🚀</button>
                        )} */}
                    </Box>
                </Stack>
            </Flex>
        </Flex>
    )
}

// Register2.propTypes = {
//     setToken: PropTypes.func.isRequired
// }

