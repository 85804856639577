import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import {
    Table, Tr, Td, Thead, Tbody, TableCaption, Button, Flex, Spacer, Box, Collapse, useDisclosure,
    Tabs, TabList, TabPanels, Tab, TabPanel, Text, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Badge, Heading
} from "@chakra-ui/react"
import mapStyle from '../styles/mapStyle3.json';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import basketball_icon2 from '../basketball_icon.png'
import football_icon from "../soccer_icon.png"
import { MdGpsFixed } from "react-icons/md";
// import axios from 'axios';


// const OPENAI_API_KEY = '';
// const OPENAI_API_URL = 'https://api.openai.com/v1/completions';

const MapCheckin = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
    });
    const [zoom, setZoom] = useState(13)
    const [center, setCenter] = useState({ lat: 22.288841297468345, lng: 114.18109673466331 })
    const [userLocation, setUserLocation] = useState(null);
    const [selectedCourt, setSelectedCourt] = useState(null);
    const [myLocationMarker, setMyLocationMarker] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [userCheckedIn, setUserCheckedIn] = useState(null);
    // const [userInput, setUserInput] = useState('');
    // const [chatHistory, setChatHistory] = useState([]);

    useEffect(() => {
        // Check if Geolocation API is available in the browser
        if (navigator.geolocation) {
            // Request user's location
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation API is not supported in this browser.');
        }
    }, []);

    console.log("props.combinedCourts", props.combinedCourts);

    let combined_markers = props.combinedCourts || [];

    combined_markers = props.combinedCourts.map(court => {
        const markerPosition = { lat: court.coordinates[1], lng: court.coordinates[0] };
        let icon_img;
        switch (court.sport) {
            case "basketball":
                icon_img = basketball_icon;
                // icon_size = { width: 30, height: 30 };
                break;
            case "tennis":
                icon_img = tennis_icon;
                break;
            case "football":
                icon_img = football_icon;
                break;
            default:
                icon_img = all_sports_icon;
        }
        let icon_size;
        // switch (court.source) {
        //     case "db":
        //         icon_size = { width: 30, height: 30 };
        //         icon_img = all_sports_icon;
        //         break;
        //     case "gov":
        //         icon_size = { width: 30, height: 30 };
        //         break;
        //     case "google":
        //         icon_size = { width: 25, height: 25 };
        //         break;
        //     default:
        //         icon_size = { width: 30, height: 30 };
        // }

        let icon;
        icon = { url: icon_img, scaledSize: { width: 30, height: 30 } };

        return (
            <Marker
                key={court.id}
                position={markerPosition}
                onClick={() => setSelectedCourt(court)}
                icon={icon}
            >
                {selectedCourt === court && (
                    <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                        <div>
                            {/* <h3>Combined Courts Info</h3> */}
                            <h3><b>{court.name}</b></h3>
                            {court.SEARCH01_EN && <p>Region: {court.SEARCH01_EN}</p>}
                            <p>Address: {court.address} <br />(<b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b>)</p>
                            <p>Sport: {court.sport}</p>
                            {court.SEARCH02_EN && <p>Sports: {court.SEARCH02_EN}</p>}
                            {court.no_of_courts && <p>No. of Courts: {court.no_of_courts}</p>}
                            {/* <p>Status: </p> */}
                            {court.NSEARCH02_EN && <p>Hours: {court.NSEARCH02_EN}</p>}
                            {/* <p style={{ fontSize: '6px' }}>Source: {court.source}</p> */}
                            {/* <Button
                                colorScheme='teal'
                                label="Create"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    // window.alert("create game")
                                    // handleLocationChange(court.id)
                                    // setActiveTab(1)
                                    // create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Create
                            </Button> */}
                            {/* <Button
                            colorScheme='teal'
                            label="Join"
                            fontSize="xs"
                            size="xs"
                            mr={2}
                            onClick={(e) => {
                                // handleJoinClick(e, rowData._id, rowData.game_id);
                                window.alert("join game")
                            }}
                        >Join</Button> */}
                            {/* <Button
                                colorScheme='teal'
                                label="view_availabilities"
                                fontSize="xs"
                                size="xs"
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    // window.alert("view availabilities")
                                    // setActiveTab(0);
                                    // console.log("activeTab:", activeTab);
                                    // handleLocationChange(court.id)
                                    // viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                                mr={2}
                            >View Existing Games</Button> */}
                            <Button
                                colorScheme='teal'
                                label="view_availabilities"
                                fontSize="xs"
                                size="xs"
                                onClick={(e) => {
                                    handleCheckIn(e, court)
                                    console.log("court", court)
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    // window.alert("view availabilities")
                                    // setActiveTab(0);
                                    // console.log("activeTab:", activeTab);
                                    // handleLocationChange(court.id)
                                    // viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >Check-In</Button>
                        </div>
                    </InfoWindow>)}
            </Marker>)
    })


    const handleMyLocation = () => {
        setCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
        setZoom(16);
        const myLocationMarker = (
            <Marker
                key={1}
                position={{ lat: userLocation.latitude, lng: userLocation.longitude }}
            //   onClick={() => setSelectedCourt(court)}
            //   icon={icon}
            />
        );
        setMyLocationMarker(myLocationMarker);
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredCourts = props.combinedCourts.filter((court) =>
        court.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCheckIn = (e, court) => {
        console.log(court);
        const userCoordinates = {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
        };

        const courtCoordinates = {
            latitude: court.coordinates[1],
            longitude: court.coordinates[0],
        };

        const distance = calculateDistance(userCoordinates, courtCoordinates);

        if (distance < 300) {
            console.log("Check-in successful");
            window.alert("Check-in successful! :D")
            setUserCheckedIn(court.name);
        } else {
            console.log("Distance is too far for check-in");
            window.alert("Distance is too far for check-in. :(")
        }
    };

    const calculateDistance = (coord1, coord2) => {
        const earthRadius = 6371; // Radius of the Earth in kilometers
        const latDiff = toRadians(coord2.latitude - coord1.latitude);
        const lonDiff = toRadians(coord2.longitude - coord1.longitude);
        const a =
            Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
            Math.cos(toRadians(coord1.latitude)) *
            Math.cos(toRadians(coord2.latitude)) *
            Math.sin(lonDiff / 2) *
            Math.sin(lonDiff / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c * 1000; // Convert distance to meters
        return distance;
    };

    const toRadians = (angle) => {
        return (angle * Math.PI) / 180;
    };

    function get_icon(sport) {
        // let icon;
        switch (sport) {
            case 'tennis':
                return tennis_icon;
            case 'basketball':
                return basketball_icon;
            case 'soccer':
                return football_icon;
            default:
                return all_sports_icon;
        }
    }

    if (!isLoaded) return <div>Loading...</div>;
    return (
        <>
            <Heading as="h1" size="xl" mb={4} textAlign="center">
                Map Check-in Feature
            </Heading>

            <Flex padding="2" direction={{ base: 'column', md: 'row' }}>
                {/* <Spacer /> */}
                <Box h="90vh" w={{ base: '90%', md: '60%' }}
                    marginX={{ base: '4', md: '4' }}
                    marginY={{ base: '4', md: '4' }}
                >
                    <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container"
                        options={{ disableDefaultUI: true, mapTypeId: "roadmap", styles: mapStyle }}
                    // options={{ styles: mapStyle }}
                    >
                        {myLocationMarker}
                        {combined_markers}
                        <Button
                            onClick={handleMyLocation}
                            position="absolute"
                            bottom="2"
                            right="2"
                        >
                            <MdGpsFixed />
                        </Button>
                    </GoogleMap>
                </Box>
                <Box
                    bg="white"
                    // borderRadius="md"
                    // boxShadow="md"
                    // p={4}
                    // h={800}
                    h="100%"
                    w={{ md: '40%' }}
                    marginX={{ base: '0', md: '0' }}
                    marginY={{ base: '0', md: '0' }}
                >

                    {userLocation ? (
                        <p>
                            <b>Your Location:</b> Latitude: {userLocation?.latitude?.toFixed(2)}, Longitude: {userLocation?.longitude?.toFixed(2)}
                        </p>

                    ) : (
                        <p>Loading user location...</p>
                    )}
                    {userCheckedIn && <p>
                        <b>Checked-in Court: </b> {userCheckedIn}
                    </p>}
                    <Flex >
                        {/* <Button marginRight={2}>Check-in</Button> */}
                        <Button onClick={handleMyLocation}>Go to my Location</Button>
                    </Flex>
                    <Input
                        mt={4}
                        placeholder="Search by court name"
                        value={searchTerm}
                        onChange={handleSearch}
                    />

                    <Box
                        h="90vh"
                        w={{ base: '90%', md: '100%' }}
                        marginX={{ base: '4', md: '1' }}
                        marginY={{ base: '4', md: '1' }}
                        overflowY="auto" // Enable scrollable behavior
                    >
                        {/* <Flex direction="column" mt={4}>
                            {props.combinedCourts.map((court) => (
                                <Card key={court.id} court={court} />
                            ))}
                        </Flex> */}
                        {filteredCourts.map((court) => (
                            <Box key={court.id} maxW='lg' borderWidth='1px' borderRadius='lg' overflow='hidden' mb={4}>
                                {/* <Image src={property.imageUrl} alt={property.imageAlt} /> */}

                                <Box p='6'>
                                    <Box display='flex' alignItems='baseline'>
                                        {/* < Link to={`/edit/${game._id}`} > */}
                                        <img src={get_icon(court.sport)} alt={court.sport} style={{ width: '20px', marginRight: '5px' }} />
                                        <Badge as='button' borderRadius='full' px='2' colorScheme='teal'
                                        // onClick={(e) => {
                                        //     handleViewClick(e, game._id)
                                        // }}
                                        >
                                            {court.sport}

                                        </Badge>
                                        {/* </Link> */}
                                        <Box
                                            color='gray.500'
                                            fontWeight='semibold'
                                            letterSpacing='wide'
                                            fontSize='xs'
                                            textTransform='uppercase'
                                            ml='2'
                                        >
                                            Address:
                                            {court.address}
                                        </Box>
                                    </Box>

                                    <Box
                                        mt='1'
                                        fontWeight='semibold'
                                        as='h4'
                                        lineHeight='tight'
                                        noOfLines={1}
                                    >
                                        {court.name}
                                    </Box>

                                    <Box>
                                        <p>{court.address}</p>
                                        {court.coordinates[1]?.toFixed(2)},{court.coordinates[0]?.toFixed(2)}
                                    </Box>

                                    <Box>
                                        <Flex direction="column">

                                            <b>More Details..</b>
                                            {/* {game._id} */}
                                            <Button
                                                onClick={(e) => {
                                                    handleCheckIn(e, court)
                                                }}
                                                w="50%"
                                            >
                                                Check-In
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                </Box >
            </Flex >
        </>

    )
}

export default MapCheckin