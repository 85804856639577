// import React, { useState } from 'react';
// import { Box, Select, FormControl, FormLabel } from '@chakra-ui/react';

// const ProductFilterDropdowns = () => {
//     // Example filter options
//     const categories = [
//         { label: 'All Categories', value: '' },
//         { label: 'Electronics', value: 'electronics' },
//         { label: 'Books', value: 'books' },
//         // Add more categories as needed
//     ];

//     const [selectedCategory, setSelectedCategory] = useState('');

//     // Handle selection change
//     const handleCategoryChange = (event) => {
//         setSelectedCategory(event.target.value);
//         // Update product list based on selected category
//     };

//     return (
//         <Box padding="4" maxWidth="400px" margin="auto">
//             <FormControl>
//                 <FormLabel htmlFor="category-select">Category</FormLabel>
//                 <Select id="category-select" value={selectedCategory} onChange={handleCategoryChange} placeholder="Select category">
//                     {categories.map((category) => (
//                         <option key={category.value} value={category.value}>
//                             {category.label}
//                         </option>
//                     ))}
//                 </Select>
//             </FormControl>
//             {/* Add more filters as needed */}
//         </Box>
//     );
// };

// export default ProductFilterDropdowns;

import React, { useState } from 'react';
const preEnteredCourts = ["abc", "bcde", "cdef"];

const ProductFilterDropdowns = () => {
    const [useCustomLocation, setUseCustomLocation] = useState(false);
    const [selectedCourt, setSelectedCourt] = useState('');
    const [customLocation, setCustomLocation] = useState('');

    const handleCourtChange = (e) => {
        setSelectedCourt(e.target.value);
    };

    const handleCustomLocationChange = (e) => {
        setCustomLocation(e.target.value);
    };

    const handleUseCustomLocationChange = (e) => {
        setUseCustomLocation(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const location = useCustomLocation ? customLocation : selectedCourt;
        console.log('Selected Location:', location);
        // Submit the form with the selected or entered location
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                <input
                    type="checkbox"
                    checked={useCustomLocation}
                    onChange={handleUseCustomLocationChange}
                />
                Enter custom location
            </label>

            {useCustomLocation ? (
                <div>
                    <label>
                        Custom Location:
                        <input
                            type="text"
                            value={customLocation}
                            onChange={handleCustomLocationChange}
                        />
                    </label>
                </div>
            ) : (
                <div>
                    <label>
                        Select Court:
                        <select value={selectedCourt} onChange={handleCourtChange}>
                            <option value="">Select a court</option>
                            {preEnteredCourts.map((court) => (
                                <option key={court.id} value={court.name}>
                                    {court.name}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            )}

            <button type="submit">Submit</button>
        </form>
    );
};

export default ProductFilterDropdowns;