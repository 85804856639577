import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link as ChakraLink,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { Link as ReactLink, NavLink, useNavigate } from 'react-router-dom'
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { AdminContext } from '../App';

// import logo from '../tennis.png'

export default function WithSubnavigation(props) {
    const { isOpen, onToggle } = useDisclosure();
    const isLoggedin = props.username ? true : false;
    const navigate = useNavigate();
    const { isAdmin, setIsAdmin } = useContext(AdminContext);

    async function logoutUser() {
        return fetch(`${process.env.REACT_APP_API_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: ' '
        })
    }
    const handleLogout = async e => {
        e.preventDefault();
        // ask the user if they are sure they want to log out
        const confirm = window.confirm("Are you sure you want to log out?");
        if (!confirm) {
            return;
        }
        const token = await logoutUser();
        props.setToken(token);
        setIsAdmin(false);
        // console.log("token: ", token);
        navigate("/login");
    }
    // console.log('logged in? (navbar)', isLoggedin);
    // console.log("navbar username: ", props.username);
    // console.log("isAdmin navbar: ", isAdmin);

    const handleNavigationCreateGame = () => {
        navigate('/', { state: { tab: 1 } });
    };
    return (
        <Box maxW={{ base: "100%", md: "85vw" }} marginX="auto">
            {/* <Box> */}
            <Flex
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 2 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }}
                    justify={{ base: 'start', md: 'start' }}
                // alignItems="flex-start"
                >
                    <NavLink className="navbar-brand" to="/landing">
                        <Text
                            textAlign={useBreakpointValue({ base: 'start', md: 'left' })}
                            fontFamily={'heading'}
                            color={useColorModeValue('gray.800', 'white')}>
                            {/* <img style={{ "width": 5 + '%' }} src={logo}></img> */}
                            <b>SportMatchHK</b>
                        </Text>
                    </NavLink>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav isLoggedin={isLoggedin} handleLogout={handleLogout} isAdmin={isAdmin} />
                    </Flex>

                </Flex>


                {/* Create Game, Log Out/Log In, Register buttons, and "logged in as ___" text */}
                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}
                    display={{ base: 'inline', md: 'flex' }}
                >
                    <Flex alignItems="center">
                        {/* <Link className="btn btn-link" to={{ pathname: "/create", state: { records: props.records } } ? `/create` : undefined}> */}
                        {isLoggedin &&
                            // <ReactLink className="btn btn-link" to={{ pathname: "/" } ? `/` : undefined} >

                            // <Flex justifyContent="center" alignItems="center">
                            <Button
                                // colorScheme={isLoggedin ? 'green' : 'gray'}
                                colorScheme='teal'
                                disabled={!isLoggedin}
                                display={{ base: 'none', md: 'inline-flex' }}
                                fontSize={'sm'}
                                fontWeight={600}
                                // color={isLoggedin ? 'white' : 'white'}
                                // bg={isLoggedin ? 'green.400' : 'gray.400'}
                                _hover={isLoggedin ? {
                                    bg: 'teal.300',
                                } : ''}
                                onClick={handleNavigationCreateGame}
                                width={'120px'}
                            >
                                Create Game
                            </Button>
                            // </Flex>
                            // </ReactLink>
                        }
                        {/* {isAdmin && <>Sup</>} */}


                        {!props.username &&
                            <ReactLink className="btn btn-link" to={`/login`}>
                                <Button
                                    as={'a'}
                                    display={{ base: 'none', md: 'inline-flex' }}
                                    fontSize={'sm'}
                                    fontWeight={600}
                                    // color={'white'}
                                    // bg={'green.400'}
                                    colorScheme='teal'
                                    variant='outline'
                                    href={'#'}
                                    _hover={{
                                        bg: 'teal.300',
                                    }}>
                                    Log in
                                </Button>
                            </ReactLink>}

                        {props.username &&
                            <ReactLink className="btn btn-link" to={`/login`}>
                                <Button
                                    colorScheme='red'
                                    as={'a'}
                                    display={{ base: 'none', md: 'inline-flex' }}
                                    fontSize={'sm'}
                                    fontWeight={600}
                                    // color={'white'}
                                    // bg={'red.400'}
                                    href={'#'}
                                    _hover={{
                                        bg: 'red.300'
                                    }}
                                    onClick={handleLogout}>
                                    Log Out
                                </Button>
                            </ReactLink>
                        }
                    </Flex>
                    {/* {props.username && <div>Logged in as <b>{props.username}</b></div>} */}

                    {props.username && (
                        <Box
                            style={{ display: 'inline-block' }}
                        >
                            {/* Logged in as <b>{props.username}</b> */}
                            Logged in as <ReactLink to="/profile"><b>{props.username}</b></ReactLink>
                        </Box>
                    )}

                    {!props.username &&
                        <ReactLink className="btn btn-link" to={`/register`}>
                            <Button
                                as={'a'}
                                display={{ base: 'none', md: 'inline-flex' }}
                                fontSize={'sm'}
                                fontWeight={600}
                                // color={'white'}
                                // bg={'green.400'}
                                colorScheme='teal'
                                href={'#'}
                                _hover={{
                                    bg: 'teal.300',
                                }}>
                                Register
                            </Button>
                        </ReactLink>
                    }
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav isLoggedin={isLoggedin} handleLogout={handleLogout} isAdmin={isAdmin} />
            </Collapse>

        </Box>
    );
}

const DesktopNav = ({ isLoggedin, handleLogout, isAdmin }) => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <>
            {/* // <Stack direction={'row'} spacing={4}> */}
            {/* {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <ChakraLink
                                p={2}
                                href={navItem.href ?? '#'}
                                // href='/login'
                                fontSize={'sm'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}>
                                {navItem.label}

                            </ChakraLink>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))} */}
            <MyNav isLoggedin={isLoggedin} screen='desktop' handleLogout={handleLogout} isAdmin={isAdmin} />
            {/* // </Stack> */}
        </>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <ChakraLink
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </ChakraLink>
    );
};

const MobileNav = ({ isLoggedin, handleLogout, isAdmin }) => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            // display={{ md: 'none' }}
            // direction="row" // add this line
            display={{ base: 'flex', md: 'none' }}
            flexDirection={{ base: 'column', md: 'row' }}
        >
            {/* {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))} */}
            <MyNav isLoggedin={isLoggedin} screen='mobile' handleLogout={handleLogout} isAdmin={isAdmin} />
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={ReactLink}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <ReactLink key={child.label} py={2} href={child.href}>
                                {child.label}
                            </ReactLink>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

function MyNav({ isLoggedin, screen, handleLogout, isAdmin }) {
    // console.log("handleLogout:", handleLogout);
    let navItems = [];
    if (screen === 'mobile') {
        navItems = [
            {
                label: 'About',
                href: '/landing',
            },
            {
                label: 'All Games',
                href: '/',
            },
            isLoggedin && {
                label: 'My Games',
                href: '/my_games',
            },

            isLoggedin && {
                label: 'Create Game',
                href: '/'
            },

            // New Create Court Feature
            (isAdmin && isLoggedin) && {
                label: 'Add Court*',
                href: '/map_search'
            },

            {
                label: 'Privacy',
                href: '/privacy_policy',
            },

            !isLoggedin && {
                label: 'Register',
                href: `/register`
            }
            ,
            !isLoggedin && {
                label: 'Login',
                href: `/login`
            },
            isLoggedin && {
                label: 'Logout',
                // href: `/login`,
                onClick: handleLogout
            }
        ].filter(Boolean);
    }
    else if (screen === 'desktop') {
        navItems = [
            {
                label: 'About',
                href: '/landing',
            },
            {
                label: 'All Games',
                href: '/',
            },
            {
                label: 'Privacy',
                href: '/privacy_policy',
            },
            isLoggedin && {
                label: 'My Games',
                href: '/my_games',
            },
            (isAdmin && isLoggedin) && {
                label: 'Add Court*',
                href: '/map_search'
            },
            // New Create Court Feature
            // isLoggedin && {
            //     label: 'Create Court (Admin)',
            //     href: '/mapsearch'
            // }
            // !isLoggedin && {
            //     label: 'Login',
            //     href: `/login`
            // }
        ].filter(Boolean);
    }
    return (
        <Stack direction={screen === 'mobile' ? 'column' : 'row'} spacing={4}>
            {/* <ul> */}
            {navItems.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <ChakraLink
                                as={ReactLink} // Use ChakraLink as RouterLink
                                to={navItem.href ?? '#'} // Use 'to' instead of 'href'
                                p={2}
                                fontSize={'sm'}
                                fontWeight={500}
                                _hover={{
                                    textDecoration: 'none',
                                }}
                                onClick={navItem.onClick}
                            >
                                {navItem.label}
                            </ChakraLink>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                // bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
            {/* </ul> */}
        </Stack>
    );
}

// {NAV_ITEMS.map((navItem) => (
//     <Box key={navItem.label}>
//         <Popover trigger={'hover'} placement={'bottom-start'}>
//             <PopoverTrigger>
//                 <ChakraLink
//                     p={2}
//                     href={navItem.href ?? '#'}
//                     // href='/login'
//                     fontSize={'sm'}
//                     fontWeight={500}
//                     color={linkColor}
//                     _hover={{
//                         textDecoration: 'none',
//                         color: linkHoverColor,
//                     }}>
//                     {navItem.label}

//                 </ChakraLink>
//             </PopoverTrigger>

//             {navItem.children && (
//                 <PopoverContent
//                     border={0}
//                     boxShadow={'xl'}
//                     bg={popoverContentBgColor}
//                     p={4}
//                     rounded={'xl'}
//                     minW={'sm'}>
//                     <Stack>
//                         {navItem.children.map((child) => (
//                             <DesktopSubNav key={child.label} {...child} />
//                         ))}
//                     </Stack>
//                 </PopoverContent>
//             )}
//         </Popover>
//     </Box>
// ))} 