import React from 'react';

// This method will add player to game's player list
export async function joinGame(id, username, setRecords, game_id) {
    const result = window.confirm("Are you sure you want to join game " + game_id + "?");
    if (!result) {
        return;
    } else {
        await fetch(`${process.env.REACT_APP_API_URL}/${id}`, {
            method: "POST",
            body: JSON.stringify({ username: username, game_id: game_id }),
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);

        if (!response.ok) {
            const message = `An error occurred: ${response.statusText}`;
            window.alert(message);
            return;
        }

        const records = await response.json();
        setRecords(records);
    }
}

export async function leaveGame(id, username, setRecords, game_id) {
    const result = window.confirm("Are you sure you want to leave game " + game_id + "?");
    if (!result) {
        return;
    } else {
        await fetch(`${process.env.REACT_APP_API_URL}/remove_game/${id}`, {
            method: "POST",
            body: JSON.stringify({ username: username, game_id: game_id }),
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);

        if (!response.ok) {
            const message = `An error occurred: ${response.statusText}`;
            window.alert(message);
            return;
        }

        const records = await response.json();
        setRecords(records);
    }
}


