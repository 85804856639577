import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Text, VStack, useBreakpointValue, Stack, Flex, Heading } from '@chakra-ui/react';
import nadal_bg from '../images/nadal_bg.png'


const PasswordPage = ({ authenticate }) => {
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        authenticate(password);
    };

    const handleChange = (e) => {
        setPassword(e.target.value);
    };

    const bgImage = useBreakpointValue({
        base: nadal_bg, // Smaller image for mobile devices
        md: nadal_bg // Larger image for desktop
    });

    return (
        <Stack
            minH={'100vh'}
            direction={{ base: 'column', md: 'row' }}
            bgImage={bgImage} // Apply the background image here
            backgroundSize="cover" // Ensure the image covers the whole area
            backgroundPosition="center" // Center the background image
            backgroundRepeat="no-repeat" // Prevent the image from repeating
        >
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Box
                    backgroundColor="white"
                    opacity="0.95"
                    padding="4"
                    borderRadius="lg"
                    boxShadow="lg"
                >
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                            <Text
                                as={'span'}
                                position={'relative'}
                                textAlign="center" // Center the text itself
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: useBreakpointValue({ base: '20%', md: '30%' }),
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'blue.400',
                                    zIndex: -1,
                                }}>
                                SportMatchHK <Text fontSize={"lg"}>(beta-phase login page)</Text>
                            </Text>
                            {/* <br /> */}
                            <Text color={'blue.600'} as={'span'} fontSize={'25px'}>
                                Playing your Favourite Sport, Made Easy.
                            </Text>
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'black.500'}>
                            {/* <p>Leave the game-planning to us, while you spend more time playing.</p> */}
                            <p>Leave us a message @ <a href="mailto:contactus@sportmatch.club" style={{ fontWeight: 'bold' }}>contactus@sportmatch.club</a></p>
                            {/* Matching sports players, one game at a time. */}
                        </Text>
                        {/* Form starts here */}
                        <Box boxShadow="lg">
                            <form onSubmit={handleSubmit}>
                                <FormControl id="password" width="auto" paddingX="4">
                                    <FormLabel htmlFor="password" color="black">Password:</FormLabel>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={handleChange}
                                        bg="white"
                                        borderColor="gray.300"
                                    />
                                    <Button
                                        marginTop="4"
                                        colorScheme="blue"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </FormControl>
                            </form>
                        </Box>
                    </Stack>
                </Box>
            </Flex>
        </Stack>
    );
};

export default PasswordPage;