import React, { useEffect, useState, useMemo } from "react";
import { Link, Navigate } from "react-router-dom";
// import { useTable, useExpanded } from 'react-table'
import { useTable, useExpanded, useGroupBy, useFilters } from 'react-table';
import Map from "./map";
import Test2 from "./test2";
import { Table, Tr, Td, Thead, Tbody, TableCaption, Button, ButtonGroup, Select } from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// import { Button, ButtonGroup } from '@chakra-ui/react'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { Route, Routes } from "react-router-dom";
import { joinGame, leaveGame } from './joinGame';
import { useParams, useNavigate } from "react-router";
import { Flex, Spacer, Box, Text } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
// import Select from 'react-select';
// import all_courts from '../data/courts.json';
import all_courts from '../data/courts.json';
import Create2 from "./create2";
import HomepageHK from "./homepage_hk"
import { useRef } from 'react';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import basketball_icon2 from '../basketball_icon.png'
import football_icon from "../soccer_icon.png"
import boardgames_icon from "../chess2.png"


const sports = ["board games"];
const places = ["Hong Kong"];
// const regions = ["Sai Wan", "Kowloon", "East Side"];

const BoardGames = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
    });
    const [selectedSport, setSelectedSport] = useState("board games");
    const [selectedPlace, setSelectedPlace] = useState("Hong Kong");
    // const [selectedRegion, setSelectedRegion] = useState("Sai Wan");
    // const [tennisCourts, setTennisCourts] = useState([]);
    // const center = { lat: 22.506274717872525, lng: 114.13053207693996 }
    const [center, setCenter] = useState({ lat: 22.288841297468345, lng: 114.18109673466331 })
    // const [bballCourts, setBballCourts] = useState([]);
    const [selectedCourt, setSelectedCourt] = useState(null);
    const [mapLocation, setMapLocation] = useState("");
    const [zoom, setZoom] = useState(13)
    const create2Ref = useRef();
    const viewGamesRef = useRef();
    const [activeTab, setActiveTab] = useState(0);
    const [mapType, setMapType] = useState("satellite");
    const [disableDefaultUI, setDisableDefaultUI] = useState(true);
    const [filtered_CombinedCourts, setFiltered_CombinedCourts] = useState([]);


    if (props.debugMode) { console.log("DEBUG MODE ON") }

    // let courtsList;
    let icon;
    // switch (selectedSport) {
    //     case "basketball":
    //         courtsList = props.bballCourts;
    //         icon = {
    //             url: basketball_icon, scaledSize: { width: 30, height: 30 }
    //         };
    //         break;
    //     case "tennis":
    //         courtsList = props.tennisCourts;
    //         icon = { url: tennis_icon, scaledSize: { width: 30, height: 30 } };
    //         break;
    //     case "football":
    //         courtsList = props.footballCourts;
    //         icon = { url: football_icon, scaledSize: { width: 30, height: 30 } };
    //         break;
    //     case "board games":
    //         courtsList = props.footballCourts;
    //         icon = { url: boardgames_icon, scaledSize: { width: 30, height: 30 } };
    //         break;
    //     default:
    //         courtsList = null; // Default value if no case matches
    // }

    let scaled_basketball_icon2;
    scaled_basketball_icon2 = {
        url: basketball_icon2, scaledSize: { width: 25, height: 25 }
    };

    // console.log("props.basketball_google:", props.basketball_google)
    let google_bball_list = props.basketball_google || [];
    // console.log("google bball spots:", google_bball_list);
    // console.log("db courts:", props.db_courts);
    let football_list = props.footballCourts || [];
    // console.log("football courts:", football_list);

    let combinedCourts_list = props.combinedCourts || [];
    let combined_markers = [];

    if (combinedCourts_list !== null) {
        const filtered_combinedCourts_list = combinedCourts_list.filter((obj) => obj.sport == selectedSport)
        combined_markers = filtered_combinedCourts_list.map(court => {
            const markerPosition = { lat: court.coordinates[1], lng: court.coordinates[0] };
            let icon_size;
            switch (court.source) {
                case "db":
                    icon_size = { width: 30, height: 30 };
                    // icon_img = all_sports_icon;
                    break;
                case "gov":
                    icon_size = { width: 30, height: 30 };
                    break;
                case "google":
                    icon_size = { width: 25, height: 25 };
                    break;
                default:
                    icon_size = { width: 30, height: 30 };
            }
            let icon_img;
            switch (court.sport) {
                case "basketball":
                    icon_img = basketball_icon;
                    break;
                case "tennis":
                    icon_img = tennis_icon;
                    break;
                case "football":
                    icon_img = football_icon;
                    break;
                case "board games":
                    icon_img = boardgames_icon;
                    icon_size = { width: 50, height: 50 };
                    break;
                default:
                    icon_img = all_sports_icon;
            }

            let icon;
            icon = { url: icon_img, scaledSize: icon_size };
            return (
                <Marker
                    key={court.id}
                    position={markerPosition}
                    onClick={() => setSelectedCourt(court)}
                    icon={icon}
                >
                    {selectedCourt === court && (
                        <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                            <div>
                                {/* <h3>Combined Courts Info</h3> */}
                                <h3><b>{court.name}</b></h3>
                                {court.SEARCH01_EN && <p>Region: {court.SEARCH01_EN}</p>}
                                <p>Address: {court.address} <br /> (<b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b>)</p>
                                <p>Sport: {court.sport}</p>
                                {court.SEARCH02_EN && <p>Sports: {court.SEARCH02_EN}</p>}
                                {court.no_of_courts && <p>No. of Courts: {court.no_of_courts}</p>}
                                {/* <p>Status: </p> */}
                                <p style={{ fontSize: '6px' }}>Source: {court.source}</p>
                                <Button
                                    colorScheme='teal'
                                    label="Create"
                                    fontSize="xs"
                                    size="xs"
                                    mr={2}
                                    onClick={(e) => {
                                        // handleJoinClick(e, rowData._id, rowData.game_id);
                                        // window.alert("create game")
                                        handleLocationChange(court.id)
                                        setActiveTab(1)
                                        create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Create
                                </Button>
                                {/* <Button
                                colorScheme='teal'
                                label="Join"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    window.alert("join game")
                                }}
                            >Join</Button> */}
                                <Button
                                    colorScheme='teal'
                                    label="view_availabilities"
                                    fontSize="xs"
                                    size="xs"
                                    onClick={(e) => {
                                        // handleJoinClick(e, rowData._id, rowData.game_id);
                                        // window.alert("view availabilities")
                                        setActiveTab(0);
                                        // console.log("activeTab:", activeTab);
                                        handleLocationChange(court.id)
                                        viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >View Existing Games</Button>
                            </div>
                        </InfoWindow>)}
                </Marker>)
        })
    }



    let scaled_all_sports_icon;
    scaled_all_sports_icon = { url: all_sports_icon, scaledSize: { width: 40, height: 40 } };

    // let markers = [];
    // if (courtsList !== null) {
    //     markers = courtsList.map(court => {
    //         const markerPosition = { lat: court.geometry.coordinates[1], lng: court.geometry.coordinates[0] };
    //         return (
    //             <Marker
    //                 key={court.properties.objectid}
    //                 position={markerPosition}
    //                 onClick={() => setSelectedCourt(court)}
    //                 icon={icon}

    //             >
    //                 {selectedCourt === court && (
    //                     <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
    //                         <div>
    //                             <h3><b>{court.properties.NAME_EN}</b></h3>
    //                             <p>Description: {court.properties.SEARCH01_EN}</p>
    //                             <p>Address: {court.properties.ADDRESS_EN}</p>
    //                             <p>Sports: {court.properties.SEARCH02_EN}</p>
    //                             <p>No. of Courts: {court.properties.No__of_Tennis_Courts_EN}</p>
    //                             <p>Status: </p>
    //                             <Button
    //                                 colorScheme='teal'
    //                                 label="Create"
    //                                 fontSize="xs"
    //                                 size="xs"
    //                                 mr={2}
    //                                 onClick={(e) => {
    //                                     // handleJoinClick(e, rowData._id, rowData.game_id);
    //                                     // window.alert("create game")
    //                                     handleLocationChange(court.properties.NAME_EN)
    //                                     setActiveTab(1)
    //                                     create2Ref.current.scrollIntoView({ behavior: 'smooth' });
    //                                 }}
    //                             >
    //                                 Create
    //                             </Button>
    //                             {/* <Button
    //                             colorScheme='teal'
    //                             label="Join"
    //                             fontSize="xs"
    //                             size="xs"
    //                             mr={2}
    //                             onClick={(e) => {
    //                                 // handleJoinClick(e, rowData._id, rowData.game_id);
    //                                 window.alert("join game")
    //                             }}
    //                         >Join</Button> */}
    //                             <Button
    //                                 colorScheme='teal'
    //                                 label="view_availabilities"
    //                                 fontSize="xs"
    //                                 size="xs"
    //                                 onClick={(e) => {
    //                                     // handleJoinClick(e, rowData._id, rowData.game_id);
    //                                     // window.alert("view availabilities")
    //                                     setActiveTab(0);
    //                                     // console.log("activeTab:", activeTab);
    //                                     handleLocationChange(court.properties.NAME_EN)
    //                                     viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
    //                                 }}
    //                             >View Existing Games</Button>
    //                         </div>
    //                     </InfoWindow>
    //                 )}

    //             </Marker>
    //         )
    //     })
    // };

    // Google court markers
    const google_markers = google_bball_list.map(court => {
        const markerPosition = { lat: court.geometry.location.lat, lng: court.geometry.location.lng };
        return (
            <Marker
                key={court.place_id}
                position={markerPosition}
                onClick={() => setSelectedCourt(court)}
                icon={scaled_basketball_icon2}
            >
                {selectedCourt === court && (
                    <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                        <div>
                            <h3><b>{court.name}</b></h3>
                            <p>Name: {court.name}</p>
                            {/* <p>Address: {court.properties.ADDRESS_EN}</p>
                            <p>More info: <a href={court.properties.NSEARCH05_TC}>{court.properties.NSEARCH05_TC}</a></p> */}
                            <p>Rating: {court.rating}/5 ({court.user_ratings_total} reviews)</p>
                            {/* <p>Price Level: {court.price_level}</p> */}
                            {/* <p>Status: {court.opening_hours.open_now ? "Open" : "Closed"}</p> */}
                            {/* <p>Status: {court.opening_hours}</p> */}
                            <Button
                                colorScheme='teal'
                                label="Create"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    // window.alert("create game")
                                    handleLocationChange(court.name)
                                    setActiveTab(1)
                                    create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Create
                            </Button>
                            {/* <Button
                                colorScheme='teal'
                                label="Join"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    window.alert("join game")
                                }}
                            >Join</Button> */}
                            <Button
                                colorScheme='teal'
                                label="view_availabilities"
                                fontSize="xs"
                                size="xs"
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    // window.alert("view availabilities")
                                    setActiveTab(0);
                                    // console.log("activeTab:", activeTab);
                                    handleLocationChange(court.name)
                                    viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >View Existing Games</Button>
                        </div>
                    </InfoWindow>
                )}

            </Marker>
        )
    });


    // DB court markers
    function generateDbMarkers(sport) {
        return props.db_courts.filter(court => court.sport == sport)
            .map(court => {
                const markerPosition = { lat: court.lat, lng: court.lng };
                return (
                    <Marker
                        key={court._id}
                        position={markerPosition}
                        onClick={() => setSelectedCourt(court)}
                        icon={scaled_all_sports_icon}
                    >
                        {selectedCourt === court && (
                            <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                                <div>
                                    <h3><b>{court.name}</b></h3>
                                    <p>Name: {court.name}</p>
                                    {/* <p>Address: {court.properties.ADDRESS_EN}</p>
                            <p>More info: <a href={court.properties.NSEARCH05_TC}>{court.properties.NSEARCH05_TC}</a></p> */}
                                    {/* <p>Rating: {court.rating}/5 ({court.user_ratings_total} reviews)</p> */}
                                    {/* <p>Price Level: {court.price_level}</p> */}
                                    <p>Description: {court.description}</p>
                                    <p>Sport: {court.sport}</p>
                                    <Button
                                        colorScheme='teal'
                                        label="Create"
                                        fontSize="xs"
                                        size="xs"
                                        mr={2}
                                        onClick={(e) => {
                                            // handleJoinClick(e, rowData._id, rowData.game_id);
                                            // window.alert("create game")
                                            handleLocationChange(court.name)
                                            setActiveTab(1)
                                            create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Create
                                    </Button>
                                    {/* <Button
                                colorScheme='teal'
                                label="Join"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    window.alert("join game")
                                }}
                            >Join</Button> */}
                                    <Button
                                        colorScheme='teal'
                                        label="view_availabilities"
                                        fontSize="xs"
                                        size="xs"
                                        onClick={(e) => {
                                            // handleJoinClick(e, rowData._id, rowData.game_id);
                                            // window.alert("view availabilities")
                                            setActiveTab(0);
                                            // console.log("activeTab:", activeTab);
                                            handleLocationChange(court.name)
                                            viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >View Existing Games</Button>
                                </div>
                            </InfoWindow>
                        )}

                    </Marker>
                )
            });
    }

    const football_markers = football_list.map(court => {
        const markerPosition = { lat: court.geometry.coordinates[1], lng: court.geometry.coordinates[0] };
        return (
            <Marker
                key={court.properties.objectid}
                position={markerPosition}
                onClick={() => setSelectedCourt(court)}
                icon={icon}

            >
                {selectedCourt === court && (
                    <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                        <div>
                            <h3><b>{court.properties.NAME_EN}</b></h3>
                            <p>Description: {court.properties.SEARCH01_EN}</p>
                            <p>Address: {court.properties.ADDRESS_EN}</p>
                            <p>Sports: {court.properties.SEARCH02_EN}</p>
                            <p>No. of Courts: {court.properties.No__of_Tennis_Courts_EN}</p>
                            <p>Status: </p>
                            <Button
                                colorScheme='teal'
                                label="Create"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    // window.alert("create game")
                                    handleLocationChange(court.properties.NAME_EN)
                                    setActiveTab(1)
                                    create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Create
                            </Button>
                            <Button
                                colorScheme='teal'
                                label="view_availabilities"
                                fontSize="xs"
                                size="xs"
                                onClick={(e) => {
                                    setActiveTab(0);
                                    handleLocationChange(court.properties.NAME_EN)
                                    viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >View Existing Games</Button>
                        </div>
                    </InfoWindow>
                )}

            </Marker>
        )
    });



    useEffect(() => {
        if (activeTab === 1) {
            // viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
            create2Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [activeTab]);

    // useEffect(() => {
    //     if (activeTab === 0) {
    //         create2Ref.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [activeTab]);

    const handleLocationChange = (location) => {
        setMapLocation(location);
        // console.log("location id!:", location)
        // Perform any additional actions based on the updated location
        // For example, update the Google Map
        // updateGoogleMap(location);
        // console.log("bballCourts:", bballCourts[0].properties.NAME_EN);
        // let courtsList;
        // switch (selectedSport) {
        //     case "basketball":
        //         courtsList = props.bballCourts;
        //         // Need to do the 3-in-1 list merging again here... Ideally should do this in Top level App, and just need to do once.
        //         break;
        //     case "tennis":
        //         courtsList = props.tennisCourts;
        //         break;
        //     case "football":
        //         courtsList = props.footballCourts;
        //         // icon = { url: football_icon, scaledSize: { width: 30, height: 30 } };
        //         break;
        //     default:
        //         courtsList = null; // Default value if no case matches
        // }

        // rebuild this function to reference full list:
        const selectedCourt = props.combinedCourts.find((court) => court.id == location);
        if (selectedCourt) {
            // console.log("selectedCourt2:", selectedCourt)
            setCenter({ lat: selectedCourt.coordinates[1], lng: selectedCourt.coordinates[0] })
            setZoom(18.5);
        } else {
            // Handle the case when the selected court is not found
            if (props.debugMode) { console.log("Selected court not found:", location); }
        }

        // const selectedCourt = courtsList.find((court) => court.properties.NAME_EN === location);
        // if (selectedCourt) {
        //     console.log("selectedCourt:", selectedCourt)
        //     setCenter({ lat: selectedCourt.properties.LATITUDE, lng: selectedCourt.properties.LONGITUDE })
        //     setZoom(18.5);
        //     // console.log("coordinates:", selectedCourt.geometry.coordinates[1], selectedCourt.geometry.coordinates[0]);
        //     // Perform any additional actions with the coordinates
        //     // updateGoogleMap(lat, lng);
        // } else {
        //     // Handle the case when the selected court is not found
        //     console.log("Selected court not found:", location);
        // }
    };

    const handleSportChange = (sport) => {
        setSelectedSport(sport);
        // console.log("sport!: ", sport);
    }

    // Place = Country (Eg. Hong Kong, Singapore)
    const handlePlaceChange = (place) => {
        setSelectedPlace(place);
        // console.log("place changed:", place);
    }

    // const handleRegionChange = (region) => {
    //     setSelectedRegion(region);
    // }

    const defaultView = () => {
        setCenter({ lat: 22.288841297468345, lng: 114.18109673466331 });
        setZoom(13);
        setMapLocation("");
        setMapType("satellite");
        setDisableDefaultUI(true);
        setSelectedSport("board games");
        setSelectedCourt(null);
        setSelectedPlace("Hong Kong");
    }

    useEffect(() => {
        switch (selectedPlace) {
            case 'Hong Kong':
                setCenter({ lat: 22.3193, lng: 114.1694 });
                setZoom(13);
                break;
            case 'Singapore':
                setCenter({ lat: 1.3521, lng: 103.8198 });
                setZoom(12);
                break;
            case 'Vancouver':
                setCenter({ lat: 49.1659, lng: -123.10233 });
                setZoom(12);
                break;
            default:
                setCenter({ lat: 22.3193, lng: 114.1694 });
                setZoom(13);
                break;
        }
    }, [selectedPlace]);

    // useEffect(() => {
    //     switch (selectedRegion) {
    //         case 'Sai Wan':
    //             setCenter({ lat: 22.3193, lng: 114.1694 });
    //             setZoom(14);
    //             break;
    //         case 'Kowloon':
    //             setCenter({ lat: 1.3521, lng: 103.8198 });
    //             setZoom(14);
    //             break;
    //         default:
    //             setCenter({ lat: 22.3193, lng: 114.1694 });
    //             setZoom(14);
    //             break;
    //     }
    // }, [selectedRegion]);


    const toggleMapType = () => {
        if (mapType === "satellite") {
            setMapType("roadmap");
            setDisableDefaultUI(false);
        } else {
            setMapType("satellite");
            setDisableDefaultUI(true);
        }
    };

    useEffect(() => {
        const temp_filtered_combinedCourts = props.combinedCourts.filter((obj) => obj.sport == selectedSport).sort((a, b) => a.name.localeCompare(b.name));
        setFiltered_CombinedCourts(temp_filtered_combinedCourts);
        if (props.debugMode) { console.log("combinedCourts in new_table:", temp_filtered_combinedCourts) }
    },
        [selectedSport, props.combinedCourts])

    if (!isLoaded) return <div>Loading...</div>;
    return (
        <Box>
            {/* <div>
                <h2><b>Sports Courts</b></h2>
                <ul>
                    {bballCourts.map((court) => (
                        <li key={court.properties.gml_id}>
                            <p>Name: {court.properties.NAME_EN}</p>
                            <p>Sports: {court.properties.SEARCH02_EN}</p>
                            <p>Coordinates: {court.geometry.coordinates[1]}, {court.geometry.coordinates[0]}</p>
                        </li>
                    ))}
                </ul>
            </div> */}
            <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container"
                options={{ disableDefaultUI: disableDefaultUI, mapTypeId: mapType }}>
                {/* // options={{}}> */}
                {/* {markers} */}
                {/* {selectedSport === 'basketball' ? google_markers : null} */}
                {/* {db_markers} */}
                {/* {selectedSport === 'basketball' ? generateDbMarkers('basketball') : null} */}
                {/* {selectedSport === 'tennis' ? generateDbMarkers('tennis') : null} */}
                {/* {selectedSport === 'football' ? football_markers : null} */}
                {combined_markers}
            </GoogleMap>
            <Box display="flex"
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '0', md: '0' }}>

                <Button
                    onClick={defaultView}
                    marginRight={2}
                    fontSize="sm"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                >
                    Default View
                </Button>

                <Button
                    onClick={toggleMapType}
                    marginRight={2}
                    fontSize="sm"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                >
                    Map Type
                </Button>

                <Select value={selectedSport} onChange={(e) => {
                    handleSportChange(e.target.value)
                }}
                    w="150px">
                    {/* <option value=""></option> */}
                    {sports.map((sport) => (
                        <option key={sport} value={sport}>
                            {sport}
                        </option>
                    ))}
                </Select>

                <Select value={selectedPlace} onChange={(e) => {
                    handlePlaceChange(e.target.value)
                }}
                    w="150px">
                    {/* <option value=""></option> */}
                    {places.map((place) => (
                        <option key={place} value={place}>
                            {place}
                        </option>
                    ))}
                </Select>

                {/* Region Filter box */}
                {/* <Select value={selectedRegion} onChange={(e) => {
                    handleRegionChange(e.target.value)
                }}
                    w="150px">
                    {regions.map((region) => (
                        <option key={region} value={region}>
                            {region}
                        </option>
                    ))}
                </Select> */}

                {/* new Location Filter Box */}
                <Select value={selectedCourt} onChange={(e) => {
                    handleLocationChange(e.target.value)
                }}
                    w="150px">
                    <option value={selectedCourt}>Select Court</option>
                    {filtered_CombinedCourts.map(court =>
                        <option key={court.id} value={court.id}>
                            {court.name}
                        </option>)
                    }
                </Select>

            </Box>

            {/* <Tabs variant='soft-rounded' colorScheme='green'  */}
            <Tabs size='md' variant='enclosed'
                index={activeTab} onChange={index => setActiveTab(index)}>
                <TabList>
                    <Tab
                    // isSelected={activeTab === 1}
                    // onClick={() => setActiveTab(1)}
                    >View Games
                    </Tab>
                    <Tab
                    // isSelected={activeTab === 0}
                    // onClick={() => setActiveTab(0)}
                    >
                        Create Game
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <div ref={viewGamesRef}>
                            <HomepageHK username={props.username} tennisCourts={props.tennisCourts} bballCourts={props.bballCourts} mapLocation={mapLocation}
                                type={"boardgames"} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div ref={create2Ref}>
                            <Create2 tennis_courts={props.tennisCourts} basketball_courts={props.bballCourts} handleLocationChange={handleLocationChange}
                                handleSportChange={handleSportChange} selectedSport={selectedSport} username={props.username} mapLocation={mapLocation}
                                basketball_google={props.basketball_google} db_courts={props.db_courts} combinedCourts={props.combinedCourts} debugMode={props.debugMode}
                                type="boardgames" />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box >
    )
};
export default BoardGames;