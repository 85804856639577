import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Flex, Spacer, Box } from "@chakra-ui/react"
import Map from "./map";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import all_courts from '../data/courts.json';
import { ArrowBackIcon } from "@chakra-ui/icons";

const locations = all_courts.map((x) => x.name);

// const locations =
//     ["NUS Multipurpose Hall, Court 1", "NUS Multipurpose Hall, Court 2",
//         "NUS Tennis Court 1", "NUS Tennis Court 2",
//         "NUS Basketball Court 1", "NUS Basketball Court 2"];

// const sports = ["basketball", "tennis", "pickleball", "soccer", "hockey", "volleyball"]

//automated sport values based on courts.json
const sports = [...new Set(all_courts.map((x) => x.sport))];

const sportLocations = {};
all_courts.forEach((court) => {
    const sport = court.sport.toLowerCase();

    if (!sportLocations[sport]) {
        sportLocations[sport] = [];
    }
    sportLocations[sport].push(court.name);
});
// console.log("sportlocations:", sportLocations);

// const sportLocations = {
//     tennis: [
//         "NUS Tennis Court 1",
//         "NUS Tennis Court 2",
//         "NUS Multipurpose Hall, Court 1",
//         "NUS Multipurpose Hall, Court 2"
//     ],
//     basketball: [
//         "NUS Sports Hall 1",
//         "NUS Sports Hall 2",
//         "NUS Sports Hall 3",
//         "NUS Multipurpose Hall, Basketball Court"
//     ]
// };

export default function Create(props) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
    });
    const [selectedLocation, setSelectedLocation] = useState(locations)
    const [locationTimes, setLocationTimes] = useState([]);
    const [form, setForm] = useState({
        name: props.username,
        location: "",
        time: "",
        datetime: "",
        sport: "",
        no_of_players: 1,
        max_no_of_players: "",
        tags: "",
        level: "",
        players: [],
        comments: ""
    });
    const navigate = useNavigate();
    const [formChanged, setFormChanged] = useState(false);


    const minDate = new Date(); // set the minimum date to today's date
    const maxDate = new Date(); // create a new Date object for the maximum date
    maxDate.setDate(maxDate.getDate() + 31); // set the maximum date to 1 month after today's date

    // console.log("Create page Username:", props.username);
    console.log("PROPS.USERNME:", props.username);
    console.log("RECORDS:", props.records);
    console.log("PROPS from Create: ", props)

    useEffect(() => {
        setForm(prevForm => ({ ...prevForm, name: props.username }));
    }, [props.username]);

    // const excludeTimes = props.records.map((record) => new Date(record.datetime));
    // console.log('excludeTimes: ', excludeTimes);

    // const disabledDate = new Date('2023-03-15');
    // disabledDate.setHours(14, 0, 0, 0);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        if (currentDate.getTime() > selectedDate.getTime()) {
            return false
        } else if (locationTimes.find(datetime => datetime.getTime() === selectedDate.getTime())) {
            return false
        }
        else {
            return true
        }
        // return currentDate.getTime() < selectedDate.getTime();
    };

    // useEffect(() => {
    //     // code that should only run on mount or when props.username updates
    // }, [props.records]);

    // These methods will update the state properties.
    function updateForm(value) {
        return setForm((prev) => {
            setFormChanged(true);
            return { ...prev, ...value, name: props.username };
        });
    }

    function handleLocationChange(value) {
        const selectedValues = new Array(1).fill(value.location);
        console.log("value.location: ", value.location);
        const localLocationTimes = props.records
            .filter((record) => record.location === value.location)
            .map((record) => new Date(record.datetime));
        console.log("locationTimes: ", localLocationTimes)
        setSelectedLocation(selectedValues);
        setLocationTimes(localLocationTimes);
    }


    function handleLocationChangeSport(value) {
        console.log("list of locations:", sportLocations[value.sport]);
        return setSelectedLocation(sportLocations[value.sport]);
    }

    function setLocationNone() {
        return setForm((prev) => {
            return { ...prev, location: "" };
        });
    }

    // This function will handle the submission.
    async function onSubmit(e) {
        e.preventDefault();
        // Append a name to the "players" list object inside the form state
        console.log("form: ", form);
        const updatedForm = {
            ...form,
            players: [...form.players, props.username] // replace "John Doe" with the actual name to be added
        };
        console.log("updatedForm: ", updatedForm);


        const max_no_of_players = +form.max_no_of_players
        if (isNaN(max_no_of_players)) {
            window.alert("Invalid: max number of players must be an integer value");
            return;
        }

        if (!Number.isInteger(max_no_of_players)) {
            window.alert("Invalid: max number of players must be an integer value");
            return;
        }

        if (form.no_of_players > form.max_no_of_players) {
            window.alert("Invalid: max number of players must be at least " + form.no_of_players);
            return;
        }

        if (!form.name) {
            window.alert("Username is empty.");
            return;
        }

        // When a post request is sent to the create url, we'll add a new record to the database.
        const newGame = { ...updatedForm };

        // await fetch("http://localhost:5000/record/add", {
        await fetch(`${process.env.REACT_APP_API_URL}/record/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newGame),
        })
            .then(response => {
                console.log("Response status:", response.status);
                if (response.status === 409) {
                    window.alert("Court is already full at requested time. Please choose another time or court.");
                }
                return response.json();
            })
            .then(data => {
                console.log("Response data:", data);
            })
            .catch(error => {
                window.alert(error);
                return;
            });

        setForm({ name: props.username, location: "", time: "", datetime: "", sport: "", no_of_players: 1, max_no_of_players: "", tags: "", players: [], comments: "", level: "" });
        navigate("/");
    }

    function handleBackClick() {
        navigate(-1);
    }


    // This following section will display the form that takes the input from the user.
    if (!isLoaded) return <div>Loading...</div>;
    return (
        <Flex direction={{ base: 'column-reverse', md: 'row' }}>
            <Spacer />
            <Box h="100%" w={{ base: "90%", md: '40%' }}
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '4', md: '4' }}
            >
                <h3><b>Create a New Game</b></h3>
                <br />
                <form onSubmit={onSubmit}>

                    <div className="form-group">
                        <label htmlFor="name">Host Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={props.username}
                            // onChange={(e) => updateForm({ name: e.target.value })}
                            // required
                            disabled
                            readOnly
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="sport">Sport</label>
                        <select
                            type="text"
                            className="form-control"
                            id="sport"
                            value={form.sport}
                            onChange={(e) => {
                                updateForm({ sport: e.target.value });
                                handleLocationChangeSport({ sport: e.target.value });
                                setLocationNone()
                            }}
                            required
                        >
                            <option value="" hidden selected>
                                Select Sport
                            </option>
                            {sports.map((sport) => (
                                <option key={sport} value={sport}>
                                    {sport}
                                </option>
                            ))}
                            {/* <option value="basketball">Basketball</option>
                            <option value="tennis">Tennis</option>
                            <option value="pickleball">Pickleball</option>
                            <option value="soccer">Soccer</option>
                            <option value="hockey">Hockey</option> */}
                        </select>
                    </div>


                    {/* {form.sport === "" && (<div className="form-group">
                        <label htmlFor="location">Location</label>
                        <select
                            type="text"
                            className="form-control"
                            id="location"
                            value={form.location}
                            onChange={(e) => {updateForm({ location: e.target.value });  handleLocationChange({location: e.target.value})} }
                            required
                        >
                            <option value="" hidden selected>
                                Select Location
                            </option>
                            <option value="NUS Multipurpose Hall, Court 1">NUS Multipurpose Hall, Court 1</option>
                            <option value="NUS Multipurpose Hall, Court 2">NUS Multipurpose Hall, Court 2</option>
                            <option value="NUS Tennis Court 1">NUS Tennis Court 1</option>
                            <option value="NUS Tennis Court 2">NUS Tennis Court 2</option>
                            <option value="NUS Basketball Court 1">NUS Basketball Court 1</option>
                            <option value="NUS Basketball Court 2">NUS Basketball Court 2</option>
                        </select>
                    </div>)
                    } */}

                    {form.sport &&
                        (<div className="form-group">
                            <label htmlFor="location">Location</label>
                            <select
                                type="text"
                                className="form-control"
                                id="location"
                                value={form.location}
                                onChange={(e) => {
                                    updateForm({ location: e.target.value });
                                    handleLocationChange({ location: e.target.value })
                                }}
                                required
                            >
                                <option value="" hidden selected>
                                    Select Location
                                </option>
                                {form.sport && Object.entries(sportLocations).map(([sport, locations]) => {
                                    if (form.sport === sport) {
                                        return locations.map((location) => (
                                            <option key={location} value={location}>{location}</option>
                                        ));
                                    } else {
                                        return null;
                                    }
                                })}
                            </select>
                        </div>)
                    }

                    <div className="form-group">
                        <label htmlFor="datetime">Date and Time</label>
                        <DatePicker
                            selected={form.datetime}
                            onChange={(datetime) => updateForm({ datetime })}
                            minDate={minDate}
                            maxDate={maxDate}
                            // excludeTimes={excludeTimes.filter(filterExcludeTimes)}
                            // excludeTimes={excludeTimes}
                            // disabledDates={[disabledDate]}
                            // disabledDates={['2023-03-29', '2015-08-26']}
                            filterTime={filterPassedTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            // dateFormat="yyyy-MM-dd HH:mm"
                            className="form-control"
                            required
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="no_of_players">No. of Players Currently</label>
                        <input
                            type="number"
                            className="form-control"
                            id="no_of_players"
                            value={form.no_of_players}
                            onChange={(e) => updateForm({ no_of_players: e.target.value })}
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="no_of_players">Max No. of Players</label>
                        <input
                            type="number"
                            className="form-control"
                            id="max_no_of_players"
                            value={form.max_no_of_players}
                            onChange={(e) => updateForm({ max_no_of_players: e.target.value })}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="tags">Tags</label>
                        <input
                            type="text"
                            className="form-control"
                            id="tags"
                            value={form.tags}
                            onChange={(e) => updateForm({ tags: e.target.value })}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="skill">Skill Level:&nbsp;&nbsp;</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelBeginner"
                                value="Beginner"
                                checked={form.level === "Beginner"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelBeginner" className="form-check-label">Beginner</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelIntermediate"
                                value="Intermediate"
                                checked={form.level === "Intermediate"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelIntermediate" className="form-check-label">Intermediate</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="levelOptions"
                                id="levelAdvanced"
                                value="Advanced"
                                checked={form.level === "Advanced"}
                                onChange={(e) => updateForm({ level: e.target.value })}
                            />
                            <label htmlFor="levelAdvanced" className="form-check-label">Advanced</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="comments">Comments</label>
                        <input
                            type="textarea"
                            className="form-control"
                            id="comments"
                            value={form.comments}
                            onChange={(e) => updateForm({ comments: e.target.value })}
                        />
                    </div>
                    <br />
                    <Box display="flex">
                        <Button
                            leftIcon={<ArrowBackIcon />}
                            variant='outline'
                            colorScheme='green'
                            onClick={handleBackClick}
                            mr={2}
                        >
                            Back
                        </Button>

                        <Button
                            onClick={onSubmit}
                            colorScheme={formChanged ? "blue" : "gray"}
                            isDisabled={!formChanged}
                        >
                            Create Game
                        </Button>
                    </Box>
                </form>
                {/* </div> */}
            </Box>
            {/* <div width="100%"> */}
            <Spacer />
            <Box
                bg="white"
                // borderRadius="md"
                // boxShadow="md"
                // p={4}
                // h={800}
                h="100%"
                w={{ md: '50%' }}
            >
                <Map selectedLocation={selectedLocation} selectedRegion={["NUS"]} />
            </Box>
            <Spacer />
        </Flex>
    );
}