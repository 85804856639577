import React, { useState, useEffect } from 'react';

export default async function fetchData() {
    let db_games;
    let db_courts
    // let tennisCourts;
    // let bballCourts;
    // let basketball_google;
    // let footballCourts;
    let api_db_courts;

    const startTime = new Date().getTime();
    // fetch data from MongoDB for bookings and courts:
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);
        if (!response.ok) {
            const message = `An error occurred while fetching game records: ${response.statusText}`;
            window.alert(message);
            return;
        }
        db_games = await response.json();
        // setRecords(records);
        // console.log("gamesss", db_games);

        // Fetch courts 
        const courtsResponse = await fetch(`${process.env.REACT_APP_API_URL}/courts/`);
        if (!courtsResponse.ok) {
            const message = `An error occurred while fetching courts: ${courtsResponse.statusText}`;
            window.alert(message);
            return;
        }
        db_courts = await courtsResponse.json();
        // setCourts(db_courts);
        // console.log("DB courtssss:", db_courts);

        // Fetch courts 
        const api_courtsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api_courts/`);
        if (!api_courtsResponse.ok) {
            const message = `An error occurred while fetching courts: ${api_courtsResponse.statusText}`;
            window.alert(message);
            return;
        }
        api_db_courts = await api_courtsResponse.json();

    } catch (error) {
        // console.error('Error:', error)
    }

    // // fetch tennis courts from Gov Open API
    // try {
    //     const response = await fetch('https://api.csdi.gov.hk/apim/dataquery/api/?id=lcsd_rcd_1629267205215_84141&layer=geodatastore&bbox-crs=WGS84&bbox=113.8,22.1,114.7,23.0&limit=56&offset=0');
    //     const data = await response.json();

    //     // Extract the coordinates from each record
    //     const coordinates = data.features.map((record) => record.geometry.coordinates);

    //     // Log the coordinates
    //     // console.log("new function coordinates:", coordinates);
    //     // console.log("new function data.features:", data.features)
    //     // setTennisCourts(data.features);
    //     tennisCourts = data.features

    //     // setCenter(({ lat: avgLat, lng: avgLng }))
    // } catch (error) {
    //     // console.error('Error:', error);
    // }

    // // fetch basketball courts from Gov Open API
    // try {
    //     const response = await fetch('https://api.csdi.gov.hk/apim/dataquery/api/?id=lcsd_rcd_1629267205215_38105&layer=geodatastore&limit=150&offset=0');
    //     const data = await response.json();
    //     insertData(data);
    //     bballCourts = data.features;
    //     // console.log("bballCourts:", bballCourts)
    // } catch (error) {
    //     // console.error('Error:', error);
    // }

    // fetching Google Places API, for 'bball courts in HK'
    // url = f"https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword={encoded_keyword}&location={latitude},{longitude}&radius={radius}&key={API}"
    // try {
    //     // console.log("google bball courts api");
    //     const encoded_keyword = "basketball%20court"
    //     // const encoded_keyword = "tennis%20court"
    //     const latitude = '22.28191793495949'
    //     const longitude = '114.17293834153935'
    //     const radius = 50000
    //     const API_key = 'AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM'
    //     // const response = await fetch(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword=${encoded_keyword}&location=${latitude},${longitude}&radius=${radius}&key=${API}`);

    //     // const response = await fetch('https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword=coffee%20shops&location=22.28191793495949,114.17293834153935&radius=5000&key=AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM')
    //     const url = `${process.env.REACT_APP_API_URL}/google-places?keyword=${encoded_keyword}&location=${latitude},${longitude}&radius=${radius}&key=${API_key}`;
    //     // const response = await fetch('/coffee/google-places')
    //     const response = await fetch(url);
    //     if (!response.ok) {
    //         throw new Error('Request failed');
    //     }
    //     const responseText = await response.text(); // Get the response as text

    //     // console.log('Google Basketball API Response:', responseText); // Log the response text

    //     const data = JSON.parse(responseText); // Try parsing the response text as JSON

    //     // console.log('Parsed Google Basketball API Response:', data);
    //     basketball_google = data.results;
    // } catch (error) {
    //     // console.log('google basketball error:', error);
    // }

    // // fetch football (soccer) fields from Gov Open API
    // try {
    //     const response = await fetch('https://api.csdi.gov.hk/apim/dataquery/api/?id=lcsd_rcd_1629267205215_60483&layer=geodatastore&limit=100&offset=0');
    //     const data = await response.json();
    //     // console.log("football data.features:", data.features)
    //     footballCourts = data.features
    // } catch (error) {
    //     // console.error('Error:', error);
    // }

    // Extract the relevant fields and combine them into a single list
    const combinedCourts = [];

    db_courts.forEach((court) => {
        // Extract the relevant fields from db_courts
        const { name, sport, lng, lat, address, _id, city = "Hong Kong", location_id } = court;
        // console.log("id", id)
        // console.log("_id", _id)
        // Create a new object with the common fields
        const combinedItem = {
            // id: _id,
            id: location_id,
            name: name,
            sport: sport,
            coordinates: [lng, lat],
            address: address,
            no_of_courts: '',
            NSEARCH01_EN: '',
            SEARCH02_EN: '',
            SEARCH01_EN: '',
            NSEARCH02_EN: '',
            source: 'db',
            // need to add from DB and not use this hard-coded value. Also change all var name to City not Country
            country: city,

        };
        combinedCourts.push(combinedItem);
    });

    // bballCourts.forEach((court) => {
    //     const { geometry, properties } = court;
    //     const combinedItem = {
    //         id: properties.gml_id,
    //         coordinates: geometry.coordinates,
    //         name: properties.NAME_EN,
    //         sport: "basketball",
    //         address: properties.ADDRESS_EN,
    //         no_of_courts: properties.No__of_Basketball_Courts_EN,
    //         NSEARCH01_EN: properties.NSEARCH01_EN,
    //         SEARCH02_EN: properties.SEARCH02_EN,
    //         SEARCH01_EN: properties.SEARCH01_EN,
    //         source: 'gov'

    //     };
    //     combinedCourts.push(combinedItem);
    // })

    const govCourts = api_db_courts.filter(court => court.source === "gov");
    const googleCourts = api_db_courts.filter(court => court.source === "google");

    govCourts.forEach(court => {
        const { _id, geometry, properties, sport, source, country, datetime_created } = court;
        const combinedItem = {
            // id: properties.gml_id,
            id: _id,
            // location_id: properties.location_id,
            coordinates: geometry.coordinates,
            name: properties.NAME_EN,
            sport: sport,
            address: properties.ADDRESS_EN,
            no_of_courts: properties.No__of_Basketball_Courts_EN,
            NSEARCH01_EN: properties.NSEARCH01_EN,
            SEARCH02_EN: properties.SEARCH02_EN,
            SEARCH01_EN: properties.SEARCH01_EN,
            NSEARCH02_EN: properties.NSEARCH02_EN,
            source: source,
            country: country,
            datetime_created: datetime_created
        };
        combinedCourts.push(combinedItem);
    });

    // api_db_courts.forEach((court) => {
    //     const { geometry, properties, sport, source } = court;
    //     const combinedItem = {
    //         id: properties.gml_id,
    //         coordinates: geometry.coordinates,
    //         name: properties.NAME_EN,
    //         sport: sport,
    //         address: properties.ADDRESS_EN,
    //         no_of_courts: properties.No__of_Basketball_Courts_EN,
    //         NSEARCH01_EN: properties.NSEARCH01_EN,
    //         SEARCH02_EN: properties.SEARCH02_EN,
    //         SEARCH01_EN: properties.SEARCH01_EN,
    //         source: source

    //     };
    //     combinedCourts.push(combinedItem);
    // })


    // tennisCourts.forEach((court) => {
    //     const { geometry, properties } = court;
    //     const combinedItem = {
    //         id: properties.gml_id,
    //         coordinates: geometry.coordinates,
    //         name: properties.NAME_EN,
    //         sport: "tennis",
    //         address: properties.ADDRESS_EN,
    //         no_of_courts: properties.No__of_Tennis_Courts_EN,
    //         NSEARCH01_EN: properties.NSEARCH01_EN,
    //         SEARCH02_EN: properties.SEARCH02_EN,
    //         SEARCH01_EN: properties.SEARCH01_EN,
    //         source: 'gov'

    //     };
    //     combinedCourts.push(combinedItem);
    // })


    // footballCourts.forEach((court) => {
    //     const { geometry, properties } = court;
    //     const combinedItem = {
    //         id: properties.gml_id,
    //         coordinates: geometry.coordinates,
    //         name: properties.NAME_EN,
    //         sport: "football",
    //         address: properties.ADDRESS_EN,
    //         // no_of_courts: properties.No__of_Basketball_Courts_EN,
    //         NSEARCH01_EN: properties.NSEARCH01_EN,
    //         SEARCH02_EN: properties.SEARCH02_EN,
    //         SEARCH01_EN: properties.SEARCH01_EN,
    //         source: 'gov'

    //     };
    //     combinedCourts.push(combinedItem);
    // })

    googleCourts.forEach((court) => {
        const { geometry, name, opening_hours, place_id, rating, user_ratings_total, business_status, sport, source, country, datetime_created } = court;
        const combinedItem = {
            id: place_id,
            coordinates: [geometry.location.lng, geometry.location.lat],
            name: name,
            sport: sport,
            ratings: rating,
            source: source,
            status: business_status,
            country: country,
            datetime_created: datetime_created
        };
        combinedCourts.push(combinedItem);
    })

    // console.log("combinedCourts:", combinedCourts);
    const endTime = new Date().getTime();
    const elapsedTime = endTime - startTime;
    console.log('Elapsed time:', elapsedTime, 'ms');

    // later, write some error-handling code, so each list fetch is not dependent on success of other fecthces... eg. one of them fails.
    return {
        "tennisCourts": govCourts,
        // "bballCourts": bballCourts,
        "bballCourts": govCourts,
        "basketball_google": googleCourts,
        "footballCourts": govCourts,
        "db_games": db_games,
        "db_courts": db_courts,
        //this should be the only one that's used... should remove the rest above.
        "combinedCourts": combinedCourts
    }
};