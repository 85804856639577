import React, { useEffect, useState, useMemo } from "react";
import {
    Box, Badge, StarIcon, Button, Flex, Spacer, FormControl, FormLabel,
    Input, Form, Stack, Textarea, Text, Image, VStack, Container, Collapse,
    List, ListItem, Avatar, Card
} from '@chakra-ui/react'
import { MdMail } from 'react-icons/md';
import { IoMdRemoveCircle } from 'react-icons/io';
import { IoMdAddCircle } from "react-icons/io";
import { ArrowBackIcon } from "@chakra-ui/icons";
import axios from 'axios';
import all_sports_icon from '../all_sports_icon.png'
import basketball_icon2 from '../basketball_icon.png'
import player_avatar1 from "../player-avatar1.png"
import bball_avatar1 from "../avatars/bball-shooter-male.jpg"
import tennis_avatar1 from "../avatars/tennis-male.jpg"
import vball_avatar1 from "../avatars/vball-female.jpg"
import player_avatar2 from "../avatars/player-avatar2.png"
import bball_avatar2 from "../avatars/bball-male.png"
import bball_avatar3 from "../avatars/bball-male2.png"
import tennis_avatar2 from "../avatars/tennis-female.png"
import vball_avatar2 from "../avatars/vball-female2.png"
import badminton_avatar from "../avatars/badminton-male.png"


export default function Profile(props) {
    const [state, setState] = useState(false)
    const [newUsername, setNewUsername] = useState('')
    const [password, setPassword] = useState();
    const [selectedAvatar, setSelectedAvatar] = useState("");
    const [friend, setFriend] = useState("");
    const [isUsernameBoxOpen, setIsUsernameBoxOpen] = useState(false);
    const [isPasswordBoxOpen, setIsPasswordBoxOpen] = useState(false);
    const [isAvatarBoxOpen, setIsAvatarBoxOpen] = useState(false);
    const [isFriendsBoxOpen, setIsFriendsBoxOpen] = useState(false);
    const [friendRequestsSent, setFriendRequestsSent] = useState([]);
    const [friendRequestsReceived, setFriendRequestsReceived] = useState([]);

    const avatars = [
        player_avatar1,
        player_avatar2,
        bball_avatar2,
        bball_avatar3,
        tennis_avatar2,
        vball_avatar2,
        badminton_avatar,
        bball_avatar1,
        tennis_avatar1,
        vball_avatar1
    ];

    useEffect(() => {
        async function fetchFriendRequests() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/friend-requests`, {

                    // Assuming you have a way to identify the user, e.g., through authentication tokens
                    params: { username: props.username }
                });
                setFriendRequestsSent(response.data.friendRequestsSent);
                setFriendRequestsReceived(response.data.friendRequestsReceived);
            } catch (error) {
                console.error("Failed to fetch friend requests:", error);
                // Handle error appropriately
            }
        }

        fetchFriendRequests();
    }, []); // Empty dependency array means this effect runs once after the initial render

    // console.log("friendRequestsReceived", friendRequestsReceived)
    // console.log("friendRequestsSent", friendRequestsSent)

    const handleAvatarSelect = (avatar) => {
        setSelectedAvatar(avatar);
    };

    async function onSubmit(e) {
        e.preventDefault();
        if (!newUsername) {
            window.alert("Error: New username is empty.");
            return;
        }
        if (!validateUsername(newUsername)) {
            window.alert("Error: Username must only contain letters, numbers, or underscore (_), and must be between 2 and 20 characters.");
            return;
        }
        if (newUsername.toLowerCase() === props.username.toLowerCase()) {
            window.alert("Error: New username cannot be same as current username.");
            return;
        }
        const isConfirmed = window.confirm(`Are you sure you want to update your username to "${newUsername}"? If available, it will take effect on next log-in.`);
        if (!isConfirmed) {
            console.log("Username update canceled by user.");
            return; // Exit the function if the user cancels
        }
        console.log("Submitting new username...");
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/username`, {
                newUsername: newUsername.toLowerCase(),
                currentUsername: props.username
            });
            console.log(response.data);
            window.alert(`Username updated to "${newUsername}" successfully. Please log-out and log back in to see changes.`);
        } catch (error) {
            if (error.response && error.response.status) {
                if (error.response.status === 404) {
                    window.alert("User not found.");
                } else if (error.response.status === 400) {
                    window.alert("Users who registered through Google cannot update password, as they do not need password to login.");
                } else {
                    window.alert("Failed to update username.");
                }
            } else {
                window.alert("Failed to update username.");
            }
        }
        // Append a name to the "players" list object inside the form state
        // console.log("form: ", form);

        // if (!form.name) {
        //     window.alert("Username is empty.");
        //     return;
        // }
    }

    async function onPasswordSubmit(e) {
        e.preventDefault();
        if (!password) {
            window.alert("Error: Password is empty.");
            return;
        }
        if (!validatePassword(password)) {
            window.alert("Error: Password must be at least 5 characters long and contain at least one letter and one number.");
            return;
        }
        const isConfirmed = window.confirm("Are you sure you want to update your password?");
        if (!isConfirmed) {
            console.log("Password update canceled by user.");
            return; // Exit the function if the user cancels
        }
        console.log("Submitting new password...");
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/password`, {
                newPassword: password,
                username: props.username
            });
            console.log(response.data);
            window.alert(`Password updated successfully.`);
        } catch (error) {
            if (error.response && error.response.status) {
                if (error.response.status === 404) {
                    window.alert("ERROR: cannot update password.");
                } else if (error.response.status === 422) {
                    window.alert("ERROR: Users who registered through Google cannot update password, as they do not need password to login.");
                }
                else {
                    window.alert("Failed to update password.");
                }
            }
        }
    }

    function validateUsername(username) {
        const usernameRegex = /^[a-zA-Z0-9_]{2,20}$/;
        return usernameRegex.test(username);
    }

    function validatePassword(password) {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}$/;
        return passwordRegex.test(password);
    }

    const handleCheckUsername = async (e) => {
        e.preventDefault();
        console.log("Checking username availability...");
        console.log("new username", newUsername)
        if (!newUsername) {
            window.alert("Error: New username is empty.");
            return;
        }
        if (!validateUsername(newUsername)) {
            window.alert("Error: Username must only contain letters, numbers, or underscore (_), and must be between 2 and 20 characters.");
            return;
        }
        if (newUsername.toLowerCase() === props.username.toLowerCase()) {
            window.alert("Error: New username cannot be same as current username.");
            return;
        }
        console.log("new username2", newUsername)
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/login_google`, {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/username/availability?username=${encodeURIComponent(newUsername.toLowerCase())}`);
            // console.log(response.data);
            if (response.data.available) {
                window.alert(`Great news, username "${newUsername.toLowerCase()}" is available. Click 'Change Username' to update.`);
            } else {
                window.alert("Sorry, username is already taken.");
            }
        } catch (error) {
            console.error("Error fetching data", error);
            window.alert("Failed to check username availability.");
        }
    }

    const submitAvatarChange = async () => {
        if (!selectedAvatar) {
            window.alert("Error: No avatar selected.");
            return;
        }
        const isConfirmed = window.confirm("Are you sure you want to update your avatar?");
        if (!isConfirmed) {
            return; // Exit the function if the user cancels
        }
        // Assuming you have a method to update the user profile in your API
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/avatar`, {
                newAvatar: selectedAvatar,
                username: props.username
            });
            console.log(response.data);
            window.alert(`Avatar updated successfully. Please log-out and log back in to see changes.`);
        } catch (error) {
            if (error.response && error.response.status) {
                if (error.response.status === 404) {
                    window.alert("404");
                } else if (error.response.status === 400) {
                    window.alert("400");
                }
            } else {
                window.alert("Failed to update avatar.");
            }
        }
    }

    const onAddFriendSubmit = async (e) => {
        e.preventDefault();
        // window.alert("Add Friend")
        // console.log("Adding friend...", friend.toLowerCase(), props.username);
        if (!friend) {
            window.alert("Error: Must enter a friend username.");
            return;
        }
        if (friend.toLowerCase() == props.username) {
            window.alert("Error: Cannot add yourself as a friend.");
            return;
        }
        const isConfirmed = window.confirm(`Request to add friend '${friend}'?`);
        if (!isConfirmed) {
            return; // Exit the function if the user cancels
        }
        try {
            // console.log(`${process.env.REACT_APP_API_URL}/user/add-friend`);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/add-friend`, {
                username: props.username,
                friend: friend.toLowerCase()
            });
            console.log(response.data);
            window.alert(`Friend request sent to '${friend}'.`);
        }
        catch (error) {
            if (error.response && error.response.status) {
                if (error.response.status === 404) {
                    window.alert("404");
                } else if (error.response.status === 400) {
                    window.alert("400");
                }
            } else {
                window.alert("Failed to add friend.");
            }
        }
    }

    return (
        <Container maxWidth="xl" padding={4}>
            <VStack spacing={4} align="stretch">
                <Button onClick={() => setIsUsernameBoxOpen(!isUsernameBoxOpen)} mt="4">
                    {isUsernameBoxOpen ? 'Update Username' : 'Update Username'}
                </Button>
                <Collapse in={isUsernameBoxOpen}>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md" display="flex" justifyContent="center" pl="10">
                        <form onSubmit={onSubmit}>
                            <FormControl>
                                {/* <FormLabel htmlFor="name"><b>Update Username</b></FormLabel> */}
                                <Text id="description"> <b>Current Username:</b> {props.username} </Text>
                                <Text id="description"> Username must be unique. It also must only contain letters, numbers, or underscore (_), and must be between 2 and 20 characters. </Text>
                                <Flex>
                                    <Input id="name" type="text" focusBorderColor="gray.500" borderColor="gray.600" borderWidth="2px"
                                        maxLength="20"
                                        onChange={(e) => setNewUsername(e.target.value)} />
                                    <Button colorScheme="blue" ml="2" fontSize="sm" px="7" py="2" width="auto"
                                        onClick={handleCheckUsername}>
                                        Check Availability
                                    </Button>
                                </Flex>
                            </FormControl>
                            <Button mt={4} colorScheme="teal" type="submit" >
                                Update Username
                            </Button>
                        </form>
                    </Box>
                </Collapse>

                <Button onClick={() => setIsPasswordBoxOpen(!isPasswordBoxOpen)} mt="4">
                    {isPasswordBoxOpen ? 'Update Password' : 'Update Password'}
                </Button>
                <Collapse in={isPasswordBoxOpen}>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md">

                        <form onSubmit={onPasswordSubmit}>
                            {/* <FormLabel htmlFor="password"><b>Update Password</b></FormLabel> */}
                            <Text id="description"> Password must be at least 5 characters long and contain at least one letter and one number. </Text>
                            <Text id="description"> <b>Note:</b> Users who registered using Google <b>cannot</b> update passwords as they do not need passwords to login</Text>
                            <Box>
                                <Input id="password" type="password" focusBorderColor="gray.500" borderColor="gray.600" borderWidth="2px"
                                    maxLength="50"
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Box>
                            <Button mt={4} colorScheme="teal" type="submit" >
                                Update Password
                            </Button>
                        </form>
                    </Box>
                </Collapse>

                {/* // Add this inside your return statement, at the appropriate place */}
                <Button onClick={() => setIsAvatarBoxOpen(!isAvatarBoxOpen)} mt="4">
                    {isAvatarBoxOpen ? 'Update Avatar' : 'Update Avatar'}
                </Button>

                <Collapse in={isAvatarBoxOpen}>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md">

                        {/* <FormLabel htmlFor="avatar"><b>Update Avatar</b></FormLabel> */}
                        <Flex wrap="wrap" justifyContent="space-around">
                            {avatars.map((avatar, index) => (
                                <Box key={index} p={2} borderWidth="2px" borderColor={selectedAvatar === avatar ? "teal" : "gray.600"}
                                    bg={selectedAvatar === avatar ? "teal.100" : "transparent"} onClick={() => handleAvatarSelect(avatar)}>
                                    <Image src={avatar} boxSize="80px" objectFit="cover" />
                                </Box>
                            ))}
                        </Flex>
                        <Button mt={4} colorScheme="teal" onClick={submitAvatarChange}>
                            Update Avatar
                        </Button>
                    </Box>
                </Collapse>

                <Button onClick={() => setIsFriendsBoxOpen(!isFriendsBoxOpen)} mt="4">
                    {isFriendsBoxOpen ? 'Manage Friends (Coming Soon)' : 'Manage Friends (Coming Soon)'}
                </Button>
                <Collapse in={isFriendsBoxOpen}>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md">

                        <form onSubmit={onAddFriendSubmit}>
                            <Text id="description"> <b>Add Friend</b></Text>
                            <Box>
                                <Input id="friend" type="text" focusBorderColor="gray.500" borderColor="gray.600" borderWidth="2px"
                                    maxLength="20"
                                    onChange={(e) => setFriend(e.target.value)} />
                            </Box>
                            <Button mt={4} colorScheme="teal" type="submit">
                                Add Friend
                            </Button>
                        </form>
                    </Box>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md" mt={4}>
                        <Text id="friendRequestsSentTitle"><b>Friend Requests Sent</b></Text>
                        {/* Assuming friendRequestsSent is an array of friend request objects */}
                        {/* <List spacing={3}>
                            {friendRequestsSent?.map((friend, index) => (
                                <ListItem key={index}>
                                    <Text>{friend}</Text>
                                </ListItem>
                            ))}
                        </List> */}
                        {friendRequestsSent?.map((friend, index) => (
                            // <ListItem key={index}>
                            //     <Text>{friend}</Text>
                            // </ListItem>
                            <Card
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                key={friend}
                            >
                                <Flex align="center" justify="space-between" w="100%">
                                    {/* <Avatar name={guest.guest_name} src={player_avatar1} /> */}
                                    <Text px='5' fontWeight='bold'>{friend}</Text>
                                    <Flex>
                                        {/* <Button variant='solid' colorScheme='green' mr={2}>
                                            <IoMdAddCircle />
                                        </Button> */}
                                        <Button variant='solid' colorScheme='red'
                                            onClick={() => window.alert("Withdraw Request.")}>
                                            <IoMdRemoveCircle />
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        ))}
                    </Box>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md" mt={4}>
                        <Text id="friendRequestsSentTitle"><b>Friend Requests Received</b></Text>
                        {/* Assuming friendRequestsSent is an array of friend request objects */}
                        {/* <List spacing={3}> */}
                        {friendRequestsReceived?.map((friend, index) => (
                            // <ListItem key={index}>
                            //     <Text>{friend}</Text>
                            // </ListItem>
                            <Card
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                key={friend}
                            >
                                <Flex align="center" justify="space-between" w="100%">
                                    {/* <Avatar name={guest.guest_name} src={player_avatar1} /> */}
                                    <Text px='5' fontWeight='bold'>{friend}</Text>
                                    <Flex>
                                        <Button variant='solid' colorScheme='green' mr={2}
                                            onClick={() => window.alert("Accept")}>
                                            <IoMdAddCircle />
                                        </Button>
                                        <Button variant='solid' colorScheme='red'
                                            onClick={() => window.alert("Decline")}>
                                            <IoMdRemoveCircle />
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Card>
                        ))}
                        {/* </List> */}
                    </Box>
                    <Box p={5} shadow="md" borderWidth="2px" borderRadius="md" mt={4}>
                        <Card>
                            <b>Friends</b>
                        </Card>
                    </Box>
                </Collapse>
            </VStack>
        </Container>
    )
}


