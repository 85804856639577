import React, { useState, useEffect } from 'react';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    FormHelperText,
    Select,
    CheckboxGroup,
    Center
} from '@chakra-ui/react';
import { useNavigate } from "react-router";
import nadal_bg from '../images/nadal_bg.png'
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

export default function Register_google({ setToken }) {
    const navigate = useNavigate();

    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState(null);
    // Google Auth states:
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();

    const responseMessage = (response) => {
        // console.log("Success:", response);
    };
    const errorMessage = (error) => {
        // console.log("Error:", error);
    };

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                // console.log("Successssss:", codeResponse);
                setUser(codeResponse);

                if (codeResponse) {
                    const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${codeResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    });

                    // console.log("res: ", res.data);
                    setProfile(res.data);
                    // console.log("profile:", res.data);
                    setUsername(res.data.email);
                    // console.log("checkpoint2");
                    setPassword("a12345");
                    setFirstName(res.data.given_name);
                    setLastName(res.data.family_name);
                    setEmail(res.data.email);
                    // console.log("all google states:", res.data.name, res.data.given_name, res.data.family_name, res.data.email);
                    // Call registerGoogle function when all states have been set
                    // how to call this ONLY when all the states above are set...
                    // await registerGoogle(); // Assuming registerGoogle is an async function
                }
            } catch (error) {
                // console.log('Login Failed:', error);
            }
        },
        onError: (error) =>
            console.log('Login Failed:', error)
    });

    useEffect(() => {
        const allStateValues = user && profile && username && firstName && lastName && email;
        if (allStateValues) {
            registerGoogle();
        }
    },
        [user, profile, username, firstName, lastName, email])

    // Inserts new user into DB
    async function registerGoogle() {
        // console.log("Registering with Google");
        const google_flag = 1;
        const newUser = { username, password, firstName, lastName, email, google_flag };
        // console.log("newUser: ", newUser);
        // await fetch("http://localhost:5000/record/add", {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newUser),
        })
            .catch(error => {
                window.alert(error);
                return;
            });
        const data = await response.json(); // parse response data into JSON object
        // console.log("Response from server:", response.status, data);
        if (response.status === 409) {
            window.alert("Username already exists.")
        }
        if (response.status === 200) {
            window.alert("Success: New user '" + username + "' created.");
            // console.log("NEW USER", username)
            navigate("/login");
        }
    }

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    // console.log("profile: ", profile);

    return (
        <div>

            {/* // <Flex
        //     minH={'100vh'}
        //     align={'center'}
        //     justify={'center'}
        //     bg={`url(${nadal_bg}) no-repeat center center fixed`}
        //     bgSize={{ base: "300%", md: 'cover' }}
        //     backgroundPosition={{ base: '60% 25%', md: 'center center' }}
        // >
        //     <Flex
        //         minH={'100vh'}
        //         align={'center'}
        //         justify={'center'}
        //         w={"100%"}
        //         h={"100%"}
        //     >

        //         <Stack
        //             spacing={8}
        //             mx={'auto'}
        //             maxW={'lg'}
        //             py={12}
        //             px={6}
        //             bgColor="rgba(255, 255, 255, 0.5)"
        //             rounded={'lg'}
        //         >
        //             <Stack align={'center'}>
        //                 <Heading fontSize={'4xl'}>Register</Heading>
        //                 <Text fontSize={'lg'} color={'gray.1000'}>
        //                     <b>Get in the game with SportMatchNUS 🎾🏀⚽🏑🏸</b>
        //                 </Text>
        //             </Stack>
        //             <Box
        //                 rounded={'lg'}
        //                 bg={useColorModeValue('white', 'gray.700')}
        //                 boxShadow={'lg'}
        //                 p={8}> */}

            <div>
                <useGoogleLogin onSuccess={responseMessage} onError={errorMessage} />
            </div>
            {/* {profile ? (
                            <div>
                                <img src={profile.picture} alt="user image" />
                                <h3>User Logged in</h3>
                                <p>Name: {profile.name}</p>
                                <p>Email Address: {profile.email}</p>
                                <br />
                                <br />
                                <button onClick={logOut}>Log out</button>
                            </div>
                        ) : (
                            <button onClick={login}>Sign in with Google 🚀</button>
                        )} */}
            {/* <button onClick={login}>Sign in with Google 🚀</button> */}
            <Center><Button onClick={login} size='sm' colorScheme='blue'
            // variant='outline'
            >
                Register with Google </Button></Center>


            {/* 
                    </Box>
                </Stack>
            </Flex>
        </Flex> */}
        </div>
    )
}

