import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';

const LocationSelector = ({ isLoaded, locationDecided, newLocation, hongKongBounds,
    onLoad, onPlaceChanged, selectedGooglePlace, selectedCourt, env, finalCourtObj }) => {
    const [inputValue, setInputValue] = useState(finalCourtObj ? finalCourtObj.name : '');

    useEffect(() => {
        setInputValue(finalCourtObj ? finalCourtObj.name : '');
    }, [finalCourtObj]);

    if (!isLoaded || locationDecided !== "yes") {
        return null;
    }

    return (
        <>
            <div>
                <label htmlFor="location">Location</label>
            </div>
            {/* {!selectedCourt && */}
            {
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                    bounds={hongKongBounds}
                    strictBounds={true}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search for a location"
                            // defaultValue={finalCourtObj ? finalCourtObj.name : ''}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            style={{
                                width: '300px', padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '7px'
                            }}
                        />
                    </div>
                </Autocomplete>
            }
            {/* {selectedCourt && (
                <div style={{
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    border: '1px solid #ccc',
                    color: '#666',
                    borderRadius: '4px',
                    pointerEvents: 'none'
                }}>
                    <b>{selectedCourt.name}</b>
                </div>
            )} */}
            <div style={{ fontSize: '12px' }}>
                <b>Address:</b> {finalCourtObj ? finalCourtObj.address : ""}
            </div>
            {env == 'dev' &&
                <>
                    For testing:<br />
                    <b>selectedCourt:</b> {selectedCourt ? selectedCourt.address : ""}<br />
                    <b>seletedGooglePlace:</b> {selectedGooglePlace ? selectedGooglePlace.formatted_address : ""}<br />
                    <b>finalCourtObj:</b> {finalCourtObj ? finalCourtObj.address : ""}
                </>
            }
        </>
    );
};

export default LocationSelector;