import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    Stack,
    Textarea,
    Tooltip,
    useClipboard,
    useColorModeValue,
    VStack,
    Container,
    SimpleGrid
} from '@chakra-ui/react'
import { BsGithub, BsLinkedin, BsPerson, BsTwitter, BsSendFill } from 'react-icons/bs'
import { TiSocialInstagram } from "react-icons/ti";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import nadal_bg from '../images/nadal_bg.png'
import { Stat } from './stat'

export default function Stats(props) {
    const tennisCourts = props.combinedCourts.filter(x => x.sport == "tennis");
    const bballCourts = props.combinedCourts.filter(x => x.sport == "basketball");
    const footballCourts = props.combinedCourts.filter(x => x.sport == "football");
    const [maxCourt, setMaxCourt] = useState("")
    const [maxCourtCount, setMaxCourtCount] = useState(0)
    const [maxCourtMonth, setMaxCourtMonth] = useState("")
    const [maxCourtCountMonth, setMaxCourtCountMonth] = useState(0)
    const [currentMonth, setCurrentMonth] = useState(0)
    // console.log("# of footballCourts:", footballCourts.length)
    console.log("# of game records:", props.records.length)
    console.log("game records:", props.records)

    function most_popular(array, field) {
        const count_dict = {}
        let max_count = 0
        let max_count_court
        for (let i = 0; i < array.length; i++) {
            console.log(i, array[i][field])
            if (array[i][field] == undefined) {
                // console.log("undefined")
                continue;
            }
            if (count_dict[array[i][field]]) {
                count_dict[array[i][field]] += 1
            } else {
                count_dict[array[i][field]] = 1
            }
            if (count_dict[array[i][field]] >= max_count) {
                max_count = count_dict[array[i][field]]
                max_count_court = array[i][field]
            }
        }
        console.log("count_dict", count_dict)
        console.log(`most popular court: ${max_count_court}: ${max_count}`);
        setMaxCourt(max_count_court);
        setMaxCourtCount(max_count);
    }


    function most_popular_this_month(array, field) {
        // Get the current year and month
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Month is zero-based, so add 1
        setCurrentMonth(currentMonth);
        const count_dict = {}
        let max_count = 0
        let max_count_court
        for (let i = 0; i < array.length; i++) {
            // console.log(i, array[i][field])
            // console.log("dateTime", array[i])
            let date = new Date(array[i]["datetime"]);
            let year = date.getFullYear();
            let month = date.getMonth() + 1; // Month is zero-based, so add 1
            console.log("currentYear", currentYear, year, currentMonth, month)
            if ((array[i][field] == undefined) || (year !== currentYear || month !== currentMonth)) {
                // console.log("undefined")
                continue;
            }
            // if (year !== currentYear || month !== currentMonth) {
            //     console.log("not the same month")
            //     continue
            // }
            if (count_dict[array[i][field]]) {
                count_dict[array[i][field]] += 1
            } else {
                count_dict[array[i][field]] = 1
            }
            if (count_dict[array[i][field]] >= max_count) {
                max_count = count_dict[array[i][field]]
                max_count_court = array[i][field]
            }
        }
        console.log("count_dict", count_dict)
        console.log(`most popular court this Month: ${max_count_court}: ${max_count}`);
        setMaxCourtMonth(max_count_court);
        setMaxCourtCountMonth(max_count);
    }

    useEffect(() => {
        most_popular(props.records, "location_name");
        most_popular_this_month(props.records, "location_name");
    }, []);

    console.log("Month", currentMonth.toLocaleString('default', { month: 'long' }));
    console.log("maxCourt", maxCourt);

    // useEffect(() => {
    // }, [maxCourt, maxCourtMonth]);

    return (
        <>
            <h1 style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>Stats</h1>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Flex justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    border="1px solid black"
                    borderRadius="8px"
                    p="4"
                    minWidth="fit-content"
                    marginBottom="16px" // Add margin-bottom for spacing
                // h="100vh"
                >
                    <Flex justifyContent="center"> <h2># of Courts in Hong Kong</h2></Flex>

                    <Text># of Basketball Courts: {bballCourts.length}</Text>
                    <Text># of Tennis Courts: {tennisCourts.length}</Text>
                    <Text># of Football Fields: {footballCourts.length}</Text>
                </Flex>
                <Flex justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    border="1px solid black"
                    borderRadius="8px"
                    p="4"
                    minWidth="fit-content"
                    marginBottom="16px" // Add margin-bottom for spacing
                // h="100vh"
                >
                    <h2>Most Popular Court</h2>
                    <Text><b>{maxCourt}</b> with <b>{maxCourtCount}</b> total games</Text>
                </Flex>

                <Flex justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    border="1px solid black"
                    borderRadius="8px"
                    p="4"
                    minWidth="fit-content"
                // h="100vh"
                >
                    <h2>Most Popular Court in {new Date(0, currentMonth - 1).toLocaleString('default', { month: 'long' })}</h2>
                    <Text><b>{maxCourtMonth}</b> with <b>{maxCourtCountMonth}</b> total games</Text>
                </Flex>
                <Box
                    as="section"
                    py={{
                        base: '4',
                        md: '8',
                    }}
                >
                    <Container>
                        <SimpleGrid
                            columns={{
                                base: 1,
                                md: 3,
                            }}
                            gap={{
                                base: '5',
                                md: '6',
                            }}
                        >
                            <Stat key={"bballCourts"} label={"Basketball Courts:"} value={bballCourts.length} />
                            <Stat key={"tennisCourts"} label={"Tennis Courts:"} value={tennisCourts.length} />
                            <Stat key={"footballCourts"} label={"Football Fields:"} value={footballCourts.length} />
                        </SimpleGrid>
                    </Container>
                </Box>
            </div>
        </>
    )
}