import React, { useState, useEffect, useContext } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow, Autocomplete } from "@react-google-maps/api";
import {
    Table, Tr, Td, Th, Thead, Tbody, TableCaption, Button, Flex, Spacer, Box, Collapse, useDisclosure,
    Tabs, TabList, TabPanels, Tab, TabPanel, Text, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Badge, Heading, Select
} from "@chakra-ui/react"
import mapStyle from '../styles/mapStyle3.json';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import basketball_icon2 from '../basketball_icon.png'
import football_icon from "../soccer_icon.png"
import { MdGpsFixed } from "react-icons/md";
import { AdminContext } from '../App';
// import axios from 'axios';

const sports = ["basketball", "tennis", "football", "badminton", "jogging", "gym", "yoga", "hiking", "biking", "surfing", "misc"];

// const OPENAI_API_KEY = '';
// const OPENAI_API_URL = 'https://api.openai.com/v1/completions';
const MapSearch = (props) => {
    const [searchResult, setSearchResult] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [sport, setSport] = useState(null);

    const [zoom, setZoom] = useState(13)
    const [center, setCenter] = useState({ lat: 22.288841297468345, lng: 114.18109673466331 })
    const [bounds, setBounds] = useState(null);
    const { isAdmin } = useContext(AdminContext);


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
        libraries: ['places'],
    });

    const onLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    };

    const onPlaceChanged = () => {
        if (searchResult !== null) {
            const place = searchResult.getPlace();
            setSelectedPlace(place);
            // console.log(selectedPlace.geometry.location)

            // const newBounds = new window.google.maps.LatLngBounds();
            // newBounds.extend(place.geometry.location);
            // setBounds(newBounds);
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    const onSubmit = async () => {
        // remove until figure out how to not do re-render on isAdmin.
        if (!isAdmin) {
            window.alert("You are not authorized to add locations to the database.")
            return
        }
        if (!sport) {
            console.log("No sport selected")
            window.alert("Please select a sport")
            return
        }
        console.log(selectedPlace)
        if (selectedPlace && selectedPlace.geometry && selectedPlace.geometry.location) {
            // Call the lat() and lng() functions to get the actual values
            const lat = selectedPlace.geometry.location.lat();
            const lng = selectedPlace.geometry.location.lng();

            // console.log("selectedPlace lat", lat);
            // console.log("selectedPlace lng", lng);
            // console.log("selectedPlace short_name", selectedPlace["address_components"][0]["short_name"]);
            // console.log("selectedPlace long_name", selectedPlace["address_components"][0]["long_name"]);
            console.log("selectedPlace name", selectedPlace["name"]);


            // Prepare the data to be sent, including the latitude and longitude
            const placeData = {
                name: selectedPlace["name"],
                lng: lng,
                lat: lat,
                address: selectedPlace.formatted_address,
                sport: sport,
                username: props.username
            };
            // console.log("placeData", placeData)

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/add-court`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(placeData),
                });

                if (response.ok) {
                    // Handle success
                    console.log('Record added successfully');
                    window.alert("Location added successfully to database");
                } else {
                    // Handle error
                    console.log('Failed to add record');
                    window.alert("Failed to add location to database. Court for this sport may already exist.");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('No place selected or missing geometry/location');
            window.alert("Please select a place")
        }
    }

    const handleSportChange = (e) => {
        setSport(e.target.value)
        // console.log("sport", sport)
    }
    // console.log("sport", sport);

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            {isLoaded && (
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                    // bounds={bounds}
                    strictBounds={true}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search for a place"
                            style={{ width: '300px', padding: '10px' }}
                        />
                        {/* <Button colorScheme="blue" ml={2} onClick={onFind} >Find</Button> */}
                        {/* <Input type="text" ml={2} placeholder="Enter sport" onChange={handleSportChange} /> */}
                        <Select placeholder="Select sport" onChange={handleSportChange}>
                            {sports.map((sport, index) => (
                                <option key={index} value={sport}>{sport}</option>
                            ))}
                        </Select>

                        <Button colorScheme="blue" ml={2} onClick={onSubmit} isDisabled={!selectedPlace || !sport}>Submit</Button>
                    </div>
                </Autocomplete>
            )}


            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '600px' }}
                zoom={zoom}
                center={center}
            >
                {selectedPlace && (
                    <Marker
                        position={selectedPlace.geometry.location || null}
                        onClick={() => {
                            setSelectedPlace(selectedPlace);
                        }}
                    />
                )}
                {selectedPlace && (
                    <InfoWindow
                        position={selectedPlace.geometry.location}
                    >
                        <>
                            <div>
                                <h3>{selectedPlace.name}</h3>
                                <p>{selectedPlace.formatted_address}</p>
                                {/* <p>Description:</p> */}
                            </div>
                            <Button colorScheme="blue" onClick={onSubmit}>Add Location to Database</Button>
                        </>
                    </InfoWindow>
                )}
            </GoogleMap>
            <Table variant="striped" mt={4} size="sm" borderWidth="1px">
                {/* <TableCaption>Place Details</TableCaption> */}
                <Tbody>
                    <Tr>
                        <Td>Name</Td>
                        <Td>{selectedPlace && selectedPlace.name}</Td>
                    </Tr>
                    <Tr>
                        <Td>Coordinates</Td>
                        <Td>
                            {selectedPlace && (
                                <>
                                    {selectedPlace.geometry.location.lat().toFixed(2)},
                                    {selectedPlace.geometry.location.lng().toFixed(2)}
                                </>
                            )}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>Address</Td>
                        <Td>{selectedPlace && selectedPlace.formatted_address}</Td>
                    </Tr>
                    <Tr>
                        <Td>Description</Td>
                        <Td></Td>
                    </Tr>
                </Tbody>
            </Table>
        </div>
    );
};

export default MapSearch;