// import { useMemo, useState } from 'react';
import { useTable, Column, useFilters } from 'react-table';
import React, { useEffect, useState, useMemo } from "react";
// import { useTable, useExpanded, useGroupBy } from 'react-table';
import { Box, Badge, StarIcon, Button, Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from "react-router";
import Map from "./map";
import all_courts from '../data/courts.json';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import soccer_icon from '../soccer_icon.png'
import hockey_icon from '../hockey_icon.png'
import all_sports_icon from '../all_sports_icon.png'

// const locations = all_courts.map((x) => x.name);
// const sports = [...new Set(all_courts.map((x) => x.sport))];

export default function MyGames(props) {
    const navigate = useNavigate();
    // const [selectedLocation, setSelectedLocation] = useState(locations);
    // const [selectedRegion, setSelectedRegion] = useState(["NUS"]);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
    });
    const [showUpcomingGames, setShowUpcomingGames] = useState(false);
    // const [selectedSport, setSelectedSport] = useState(sports);

    // const [selectedLocation, setSelectedLocation] = useState(locations)

    const all_games = props.records.filter(record => ((record.name === props.username) || (record.players.includes(props.username))))
        .sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
    // && selectedSport.includes(record.sport))

    const prev_games = all_games.filter(record => ((new Date(record.datetime) < new Date().getTime())));
    const upcoming_games = all_games.filter(record => ((new Date(record.datetime) >= new Date().getTime())));
    // const my_games = React.useMemo(
    //     () => records.filter(x => x.name === props.username),
    //     [records]
    // );

    const my_games = showUpcomingGames ? prev_games : upcoming_games;


    // console.log("records:", records);
    if (props.debugMode) { console.log("my_games:", my_games); }

    function handleViewClick(e, id) {
        e.preventDefault();
        navigate(`/view/${id}`);
        // navigate(`/edit/${id}`, { state: { records: props.records } });
    }

    const toggleShowUpcomingGames = () => {
        setShowUpcomingGames(prevValue => !prevValue);
    }

    // const handleSportChange = (event) => {
    //     const selectedSports = Array.from(event.target.selectedOptions, option => option.value.toLowerCase());
    //     setSelectedSport(selectedSports);
    // }

    function get_icon(sport) {
        // let icon;
        switch (sport) {
            case 'tennis':
                return tennis_icon;
            case 'basketball':
                return basketball_icon;
            case 'soccer':
                return soccer_icon;
            case 'hockey':
                return hockey_icon;
            default:
                return all_sports_icon;
        }
    }

    // if (!isLoaded) return <div>Loading...</div>;
    return (
        <Box maxW="90vw" marginX="auto">

            <>

                <b>My Games</b>
                <Box mt={2}>
                    <Button
                        onClick={toggleShowUpcomingGames}
                        marginRight={2}
                        fontSize="sm"
                        marginBottom={3}
                    >
                        {showUpcomingGames ? 'Show Upcoming Games Only' : 'Show Previous Games'}
                    </Button>
                </Box>
                {/* <Flex maxW={{ base: '800px', md: 'none' }} flexDirection={{ base: 'row', md: 'row' }}
                    marginX={{ base: '2', md: '0' }}
                    marginY={{ base: '0', md: '0' }}
                    marginTop={{ base: '2', md: '0' }}
                >
                    <Box mr={{ base: '10px', md: '20px' }} maxW={{ base: "400px", md: 'none' }}>
                        <Flex direction="column" alignItems="center">
                            <b style={{ color: 'black' }}>Sport</b>
                            <select multiple={true} value={selectedSport} onChange={handleSportChange}
                                style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '10px', width: '100%' }}>
                                {sports.map((sport) => (
                                    <option key={sport} value={sport.toLowerCase()}>
                                        {sport}
                                    </option>
                                ))}
                            </select>
                        </Flex>
                    </Box>
                </Flex> */}

                <div style={{
                    display: 'grid',
                    // gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateColumns: '1fr',
                    gap: '1rem',
                }}>
                    {my_games.map((game) => (
                        <Box key={game.game_id} maxW='lg' borderWidth='1px' borderRadius='lg' overflow='hidden' mb={4}>
                            {/* <Image src={property.imageUrl} alt={property.imageAlt} /> */}

                            <Box p='6'>
                                <Box display='flex' alignItems='baseline'>
                                    {/* < Link to={`/edit/${game._id}`} > */}
                                    <img src={get_icon(game.sport)} alt={game.sport} style={{ width: '20px', marginRight: '5px' }} />
                                    <Badge as='button' borderRadius='full' px='2' colorScheme='teal'
                                        onClick={(e) => {
                                            handleViewClick(e, game._id)
                                        }}>
                                        {game.sport}
                                        {/* const icon = get_icon(rowData.sport); */}
                                    </Badge>
                                    {/* </Link> */}
                                    <Box
                                        color='gray.500'
                                        fontWeight='semibold'
                                        letterSpacing='wide'
                                        fontSize='xs'
                                        textTransform='uppercase'
                                        ml='2'
                                    >
                                        Host:
                                        {/* {`${game.name.substring(0, 3)}*****`} */}
                                        {`${game.name}`}
                                        {game.name === props.username &&
                                            <Badge colorScheme='yellow'>(You) 👑</Badge>}
                                    </Box>

                                    {/* <Button
                                        colorScheme='red'
                                        label="Leave"
                                        mr={2}
                                    // onClick={(e) => {
                                    // props.handleLeaveClick(e, rowData._id, rowData.game_id);
                                    // }
                                    // }
                                    >Leave</Button> */}

                                </Box>

                                {/* <Box
                                    mt='1'
                                    fontWeight='semibold'
                                    as='h4'
                                    lineHeight='tight'
                                    noOfLines={1}
                                >
                                    Region: {game.region}
                                </Box> */}

                                <Box
                                    mt='1'
                                    // fontWeight='semibold'
                                    as='h6'
                                    lineHeight='tight'
                                    noOfLines={1}
                                >
                                    Location: {game.location_name || game.location}
                                </Box>

                                <Box>
                                    {new Date(game.datetime).toLocaleString('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                    })}
                                </Box>

                                <Box>
                                    <b><a href={`/view/${game._id}`}>More Details...</a></b>
                                    {/* {game._id} */}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </div>
            </>
            {/* <Box>
                <Map selectedLocation={selectedLocation} selectedRegion={selectedRegion} />
            </Box> */}
        </Box >

    )

}

