import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import {
    Table, Tr, Td, Thead, Tbody, TableCaption, Button, ButtonGroup, Select,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure,
    Flex, Spacer, Box, Text, Grid, GridItem
} from "@chakra-ui/react"
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import Create2 from "./create2";
import { useRef } from 'react';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import basketball_icon2 from '../basketball_icon.png'
import football_icon from "../soccer_icon.png"
import mapStyle from '../styles/mapStyle3.json';
import { MdGpsFixed } from "react-icons/md";
import GameCards2 from "./gameCards2";



// const sports = ["All", "basketball", "tennis", "football", "badminton", "jogging", "gym", "yoga", "hiking", "biking", "surfing", "misc"];
const sports = ["All", "basketball", "tennis", "football", "badminton", "misc"];
// const places = ["Hong Kong", "Singapore", "Vancouver"];
const places = ["Hong Kong"];

const NewTableMobile2 = (props) => {
    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
    // });

    const [selectedSport, setSelectedSport] = useState("All");
    const [selectedCity, setSelectedCity] = useState("Hong Kong");
    const [center, setCenter] = useState({ lat: 22.288841297468345, lng: 114.18109673466331 })
    const [zoom, setZoom] = useState(13)
    const [mapType, setMapType] = useState("satellite");
    const [disableDefaultUI, setDisableDefaultUI] = useState(true);
    const [userLocation, setUserLocation] = useState(null);
    const [myLocationMarker, setMyLocationMarker] = useState(null);

    const location = useLocation();
    const initialActiveTab = location.state?.tab || 0;
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const create2Ref = useRef();
    const viewGamesRef = useRef();
    const [filteredGames, setFilteredGames] = useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState("upcoming");
    const [filtered_CombinedCourts, setFiltered_CombinedCourts] = useState(props.combinedCourts);
    const [mapLocation, setMapLocation] = useState("");
    const [selectedCourt, setSelectedCourt] = useState(null);
    const [combined_markers, setCombined_markers] = useState([]);
    const [googleLocationMarker, setGoogleLocationMarker] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [mapCourts, setMapCourts] = useState("all");

    const now = new Date();
    const time_buffer = new Date(now.getTime() - 12 * 60 * 60 * 1000);

    const all_games = props.env == 'dev' ? props.records.filter(rec => rec.env == "dev") : props.records.filter(rec => rec.env !== "dev");
    const upcoming_games = all_games.filter(rec => new Date(rec.datetime) >= time_buffer);
    console.log("upcoming_games", upcoming_games);

    // const upcoming_games = props.records.filter(rec => new Date(rec.datetime) >= now);
    // const past_games = props.records;
    const past_games = all_games.filter(rec => {
        const gameDate = new Date(rec.datetime);
        return gameDate >= new Date(new Date().setDate(new Date().getDate() - 21))
        // && gameDate < now;
    });

    useEffect(() => {
        if (selectedTimeframe === 'upcoming') {
            setFilteredGames(upcoming_games);
        } else if (selectedTimeframe === 'past') {
            setFilteredGames(past_games);
        } else {
            setFilteredGames([]);
        }
    }, [selectedTimeframe, props.records]);

    const handleMyLocation = () => {
        if (navigator.geolocation) {
            // Request user's location
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    // const localUserLocation = { latitude, longitude };
                    setUserLocation({ latitude, longitude });
                    setCenter({ lat: latitude, lng: longitude });
                    // setCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
                    setZoom(16);

                    const myLocationMarker = (
                        <Marker
                            key={1}
                            position={{ lat: latitude, lng: longitude }}
                        //   onClick={() => setSelectedCourt(court)}
                        //   icon={icon}
                        />
                    );
                    setMyLocationMarker(myLocationMarker);
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation API is not supported in this browser.');
        }
    }

    const handleLocationChange = (location) => {
        console.log("location:", location)
        setMapLocation(location);

        // rebuild this function to reference full list:
        const selectedCourt = props.combinedCourts.find((court) => court.id == location);
        if (selectedCourt) {
            // console.log("selectedCourt2:", selectedCourt)
            setCenter({ lat: selectedCourt.coordinates[1], lng: selectedCourt.coordinates[0] })
            setZoom(19);
            setSelectedCourt(selectedCourt);

            console.log("selectedCourt", selectedCourt)
        } else {
            // Handle the case when the selected court is not found
            if (props.debugMode) { console.log("Selected court not found:", location); }
        }
    };

    const handleSportChange = (sport) => {
        if (sport === "All") {
            // Handle the "All" option
            setSelectedSport(""); // or any logic to include all sports
        } else {
            setSelectedSport(sport);
        }
        // setSelectedSport(sport);
        // console.log("sport!: ", sport);
    }

    const handleCityChange = (place) => {
        setSelectedCity(place);
        // console.log("place changed:", place);
    }

    const handleGoogleLocationChange = (location) => {
        // setMapLocation(location);
        console.log("handleGoogleLocationChange location:", location)
        const lat = location.geometry.location.lat();
        const lng = location.geometry.location.lng();
        setCenter({ lat: lat, lng: lng })
        setZoom(19);
        // const markerPosition = { lat: lat, lng: lng };
        const googleLocationMarker = (
            <Marker
                key={1}
                position={{ lat: lat, lng: lng }}
                //   onClick={() => setSelectedCourt(court)}
                //   icon={icon}
                onClick={() =>
                    setSelectedCourt(location.place_id)
                }
            // icon={icon}
            >
                {/* {selectedCourt === location.place_id && ( */}
                <InfoWindow
                    position={{ lat: lat, lng: lng }}
                    onCloseClick={() => setSelectedCourt(null)}
                >
                    <div>
                        {/* <h3>Court Info</h3> */}
                        {location.name && <h6><b>{location.name}</b></h6>}
                        {location.formatted_address && <p><b>Address: {location.formatted_address}</b></p>}
                    </div>
                </InfoWindow>
                {/* )
                } */}
            </Marker >
        );
        console.log("setting googleLocationMarker:", googleLocationMarker)
        setGoogleLocationMarker(googleLocationMarker);
    };


    useEffect(() => {
        let final_combinedCourts = props.combinedCourts.filter(court => court.sport === selectedSport || selectedSport === "All");
        if (mapCourts === "upcoming") {
            final_combinedCourts = final_combinedCourts.filter(court => {
                return filteredGames.some(game => game.location === court.id)
            })
        }

        console.log("final_combinedCourts which sets the Markers:", final_combinedCourts);

        setCombined_markers(final_combinedCourts.map(court => {
            // combined_markers = combinedCourts_list.map(court => {
            const markerPosition = { lat: court.coordinates[1], lng: court.coordinates[0] };
            let icon_img;
            switch (court.sport) {
                case "basketball":
                    icon_img = basketball_icon;
                    break;
                case "tennis":
                    icon_img = tennis_icon;
                    break;
                case "football":
                    icon_img = football_icon;
                    break;
                default:
                    icon_img = all_sports_icon;
            }
            let icon_size;
            switch (court.source) {
                case "db":
                    icon_size = { width: 30, height: 30 };
                    // icon_img = all_sports_icon;
                    break;
                case "gov":
                    icon_size = { width: 30, height: 30 };
                    break;
                case "google":
                    icon_size = { width: 25, height: 25 };
                    break;
                default:
                    icon_size = { width: 30, height: 30 };
            }
            let icon;
            icon = { url: icon_img, scaledSize: icon_size };
            return (
                <Marker
                    key={court.id}
                    position={markerPosition}
                    onClick={() => {
                        console.log("court", court)
                        setSelectedCourt(court);
                    }}
                    icon={icon}
                >
                    {selectedCourt === court && (
                        <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                            <div>
                                {/* <h3>Combined Courts Info</h3> */}
                                <h6><b>{court.name}</b></h6>
                                {court.SEARCH01_EN && <p>Region: {court.SEARCH01_EN}</p>}
                                <p>Address: {court.address} <br />(<b><a href={`http://www.google.com/maps/place/${court.coordinates[1]},${court.coordinates[0]}`} target="_blank" rel="noopener noreferrer">Open in Google Maps</a></b>)</p>
                                <p>Sport: {court.sport}</p>
                                {court.SEARCH02_EN && <p>Sports: {court.SEARCH02_EN}</p>}
                                {court.no_of_courts && <p>No. of Courts: {court.no_of_courts}</p>}
                                {/* <p>Status: </p> */}
                                {court.NSEARCH02_EN && <p>Hours: {court.NSEARCH02_EN}</p>}
                                <p style={{ fontSize: '6px' }}>Source: {court.source}</p>
                                <Button
                                    colorScheme='teal'
                                    label="Create"
                                    fontSize="xs"
                                    size="xs"
                                    mr={2}
                                    onClick={(e) => {
                                        // handleJoinClick(e, rowData._id, rowData.game_id);
                                        // window.alert("create game")

                                        // need to check this court.id....!!!!!
                                        handleLocationChange(court.id)
                                        setActiveTab(1)
                                        create2Ref.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    Create
                                </Button>
                                <Button
                                    colorScheme='teal'
                                    label="view_availabilities"
                                    fontSize="xs"
                                    size="xs"
                                    onClick={(e) => {
                                        // handleJoinClick(e, rowData._id, rowData.game_id);
                                        // window.alert("view availabilities")
                                        setActiveTab(0);
                                        // console.log("activeTab:", activeTab);
                                        handleLocationChange(court.id)
                                        viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >View Existing Games</Button>
                            </div>
                        </InfoWindow>)}
                </Marker>)
        }))
        // console.log("combined_markers:", combined_markers)
    }
        // }
        , [props.combinedCourts, selectedSport, selectedCourt, mapCourts]);


    // useEffect(() => {
    //     setActiveTab(initialActiveTab);
    //     if (initialActiveTab === 1) {
    //         // console.log("heree")
    //         setMapCourts("all");
    //     }
    // }, [initialActiveTab]);

    useEffect(() => {
        if (activeTab === 1 && create2Ref.current) {
            // viewGamesRef.current.scrollIntoView({ behavior: 'smooth' });
            create2Ref.current.scrollIntoView({ behavior: 'smooth' });
            setMapCourts("all");
        }
        else if (activeTab === 0 && viewGamesRef.current) {
            setMapCourts("upcoming");
            // console.log("here2")
        }
    }, [activeTab]);

    // if (!isLoaded) return <div>Loading...</div>;
    // if (!props.isLoaded) {
    //     return <div>Loading...</div>;
    // }
    return (
        <Box>
            <Box p="2">
                <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container"
                    mapContainerStyle={{ height: "40vh", width: "100%", borderRadius: "10px" }}
                    options={{ disableDefaultUI: disableDefaultUI, mapTypeId: mapType, styles: mapStyle }}
                // options={{ styles: mapStyle }}
                >
                    {combined_markers}
                    {myLocationMarker}
                    {googleLocationMarker}
                    <Button
                        onClick={handleMyLocation}
                        position="absolute"
                        bottom="2"
                        right="2"
                    >
                        <MdGpsFixed />
                    </Button>

                </GoogleMap>
            </Box>

            <Box display="flex"
                marginX={{ base: '4', md: '0' }}
                marginY={{ base: '1', md: '1' }}
            >
                <Button
                    onClick={onOpen}
                    fontSize="xs"
                    whiteSpace="normal" // Enable text wrapping
                    overflowWrap="break-word" // Allow words to break within the button
                    // colorScheme="blue"
                    // rounded={'full'}
                    colorScheme="teal"
                // variant="outline"
                >
                    Filter
                </Button>
                {/* <Modal isOpen={isOpen} onClose={onClose}> */}
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Filter Options</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Select placeholder="Select Sport" value={selectedSport} onChange={(e) => setSelectedSport(e.target.value)}>
                                {sports.map((sport) => (
                                    <option key={sport} value={sport}>{sport.charAt(0).toUpperCase() + sport.slice(1)}</option>
                                ))}
                            </Select>

                            <Select placeholder="Select Location" value={selectedCity} onChange={(e) => handleCityChange(e.target.value)} mt={4}
                                isDisabled
                            >
                                {places.map((place) => (
                                    <option key={place} value={place}>
                                        {place}
                                    </option>
                                ))}
                            </Select>

                            {/* <Select placeholder="Select Court" value={selectedCourt} onChange={(e) => handleLocationChange(e.target.value)} mt={4}>
                                {filtered_CombinedCourts.map(court =>
                                    <option key={court.id} value={court.id}>
                                        {court.name}
                                    </option>)
                                }
                            </Select> */}

                            <Select placeholder="Select Timeframe" value={selectedTimeframe} onChange={(e) => setSelectedTimeframe(e.target.value)} mt={4}>
                                <option value="upcoming">Upcoming Games Only</option>
                                <option value="past">Include Past Games</option>
                            </Select>

                            <Select placeholder="Show All Courts?" value={mapCourts} onChange={(e) => setMapCourts(e.target.value)} mt={4}>
                                <option value="upcoming">Courts with Upoming Games Only</option>
                                <option value="past">Show All Courts</option>
                            </Select>

                        </ModalBody>

                        <ModalFooter>
                            {/* <Button colorScheme="blue" mr={3} onClick={applyFilters}>
                                Apply Filters
                            </Button> */}
                            <Button colorScheme="gray" onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </Box>


            <Tabs size='md' variant='enclosed'
                index={activeTab} onChange={index => setActiveTab(index)}
                mt="2">
                <TabList>
                    <Tab
                    // isSelected={activeTab === 1}
                    // onClick={() => setActiveTab(1)}
                    >
                        View Games
                    </Tab>
                    <Tab
                    // isSelected={activeTab === 0}
                    // onClick={() => setActiveTab(0)}
                    >
                        Create Game
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <div ref={viewGamesRef}>
                            <GameCards2
                                username={props.username}
                                // records={props.records}
                                records={filteredGames}
                                combinedCourts={filtered_CombinedCourts}
                                selectedSport={selectedSport}
                                mapLocation={mapLocation}
                                debugMode={props.debugMode}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div ref={create2Ref} id="create">
                            <Create2
                                handleLocationChange={handleLocationChange} handleSportChange={handleSportChange} selectedSport={selectedSport}
                                username={props.username} mapLocation={mapLocation}
                                combinedCourts={filtered_CombinedCourts}
                                debugMode={props.debugMode}
                                selectedCourt={selectedCourt}
                                handleGoogleLocationChange={handleGoogleLocationChange}
                                env={props.env}
                                selectedCity={selectedCity}
                            />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
};
export default NewTableMobile2;