// import React from 'react'
// import GoogleMapReact from 'google-map-react'
// import './map.css'
// import { Icon } from '@iconify/react'
// import locationIcon from '@iconify/icons-mdi/map-marker'
import "../style.css";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import React, { useEffect, useState, useMemo, useRef } from "react";
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import soccer_icon from '../soccer_icon.png'
import hockey_icon from '../hockey_icon.png'
import volleyball_icon from '../volleyball_icon.png'
// import all_courts from '../data/courts.json';
import all_courts from '../data/courts.json';
import { Button } from "@chakra-ui/react"

export default function Map({ selectedLocation }) {
    // const all_courts_in_region = all_courts.filter(court => selectedRegion.includes(court.region));
    const [selectedCourt, setSelectedCourt] = useState(null);

    console.log("Maps selectedLocation:", selectedLocation);

    const all_courts_in_region = all_courts.filter(court => selectedLocation.includes(court.name));
    console.log("all_courts_in_region:", all_courts_in_region);
    const avgLat = all_courts_in_region.reduce((sum, court) => sum + court.lat, 0) / all_courts_in_region.length;
    const avgLng = all_courts_in_region.reduce((sum, court) => sum + court.lng, 0) / all_courts_in_region.length;
    const center = useMemo(() => ({ lat: avgLat, lng: avgLng }), [selectedLocation]);
    // const center = useMemo(() => ({ lat: 1.299064121363014, lng: 103.77753554314797 }), []);

    // console.log(all_courts.filter(court => court.region === selectedRegion));
    console.log("Filtered courts by region:", all_courts_in_region);

    console.log("selected courts:", selectedLocation);
    // console.log("selected region:", selectedRegion);

    const filtered_court = all_courts_in_region.filter(court => selectedLocation.includes(court.name));
    console.log("filtered courts: ", filtered_court);

    const markers = filtered_court.map(court => {
        const markerPosition = { lat: court.lat, lng: court.lng };
        let icon;
        switch (court.sport) {
            case 'tennis':
                icon = { url: tennis_icon, scaledSize: { width: 30, height: 30 } };
                break;
            case 'basketball':
                icon = {
                    url: basketball_icon, scaledSize: { width: 30, height: 30 }
                };
                break;
            case 'soccer':
                icon = { url: soccer_icon, scaledSize: { width: 30, height: 30 } };
                break;
            case 'volleyball':
                icon = { url: volleyball_icon, scaledSize: { width: 25, height: 25 } };
                break;
            // Add more cases for other sports as needed
            default:
                icon = { url: all_sports_icon, scaledSize: { width: 50, height: 50 } };
                break;
        }

        return (
            <Marker
                key={court.id}
                position={markerPosition}
                onClick={() => setSelectedCourt(court)}
                icon={icon}
            >
                {selectedCourt === court && (
                    <InfoWindow position={markerPosition} onCloseClick={() => setSelectedCourt(null)}>
                        <div>
                            <h3><b>{court.name}</b></h3>
                            <p>Description: {court.description}</p>
                            <p>Status: </p>
                            <Button
                                colorScheme='teal'
                                label="Create"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    window.alert("create game")
                                }}
                            >Create</Button>
                            <Button
                                colorScheme='teal'
                                label="Join"
                                fontSize="xs"
                                size="xs"
                                mr={2}
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    window.alert("join game")
                                }}
                            >Join</Button>
                            <Button
                                colorScheme='teal'
                                label="view_availabilities"
                                fontSize="xs"
                                size="xs"
                                onClick={(e) => {
                                    // handleJoinClick(e, rowData._id, rowData.game_id);
                                    window.alert("view availabilities")
                                }}
                            >View Availabilities</Button>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        )
    })

    return (
        <GoogleMap zoom={18.5} center={center} mapContainerClassName="map-container" mapTypeId='satellite'
            options={{ disableDefaultUI: true, mapTypeId: "satellite" }}>
            {markers}
        </GoogleMap>
    );
}

// gestureHandling: 'none',