import React, { useEffect, useState, useMemo, useRef } from "react";
import { Table, Tr, Td, Thead, Tbody, TableCaption, ButtonGroup } from "@chakra-ui/react"
import { Box, Image, Text, Stack, Button, Flex, VStack } from '@chakra-ui/react';
import { Tag } from 'primereact/tag';
import bball_card from '../images/bball_card.jpg';
import bball_card2 from '../images/bball_card2.png';
import tennis_card from '../images/tennis_card.jpg';
import football_card from '../images/football_card.png';
import badminton_card from '../images/badminton_card.jpg';
import gym_card from '../images/gym_card.png';
import yoga_card from '../images/yoga_card.png';
import jogging_card from '../images/jogging_card.jpg';
import hiking_card from '../images/hiking_card.jpg';
import biking_card from '../images/biking_card.jpg';
import surfing_card from '../images/surfing_card.webp';
import misc_card from '../images/misc_card.jpg';


import { format, parseISO } from 'date-fns';
import tennis_icon from '../tennis_icon.png'
import basketball_icon from '../basketball_icon.png'
import soccer_icon from '../soccer_icon.png'
import hockey_icon from '../hockey_icon.png'
import all_sports_icon from '../all_sports_icon.png'
import { useParams, useNavigate } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { FaAngleRight } from "react-icons/fa";


SwiperCore.use([Pagination]);

const GameCards2 = (props) => {
    const [currentIndex, setCurrentIndex] = useState(1); // State to keep track of the current slide index
    const [totalSlides, setTotalSlides] = useState(0); // State to keep track of the total number of slides
    const [games, setGames] = useState([]);

    const now = new Date();
    // const games = props.records.filter(rec => new Date(rec.datetime) >= now);
    // let public_data = props.data.filter(item => item.public_setting === "public");

    useEffect(() => {
        let filteredGames = props.records
            .filter(rec => rec.public_setting === "public")
            .sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

        if (props.selectedSport !== "All") {
            filteredGames = filteredGames.filter(rec => rec.sport === props.selectedSport);
        }

        setGames(filteredGames);
        setTotalSlides(filteredGames.length);
    }, [props.records, props.selectedSport]);

    // const games = props.records
    //     // .filter(rec => new Date(rec.datetime) >= now)
    //     .filter(rec => rec.public_setting === "public")
    //     .filter(rec => rec.sport == props.selectedSport)
    //     .sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
    // console.log("games", games)

    // Function to handle slide change
    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.realIndex + 1); // Update current slide index
    };

    useEffect(() => {
        // Assuming you have a way to determine the total number of games/cards
        // For example, if you have a games array, you can set its length as total slides

        setTotalSlides(games.length);
    }, []);

    // const capacity = `${record.no_of_players}/${record.max_no_of_players}`;
    // const isFull = parseInt(record.max_no_of_players) <= parseInt(record.no_of_players);
    // const alreadyJoined = record.players && record.players.includes(props.username);
    // const isJoinable = !isFull && !alreadyJoined && isLoggedin;
    const navigate = useNavigate();


    function handleEditClick(e, id) {
        e.preventDefault();
        navigate(`/view/${id}`);
        // navigate(`/edit/${id}`, { state: { handleJoinClick: props.handleJoinClick } });
    }

    // console.log("currentIndex, totalSlides", currentIndex, totalSlides);

    // image selector depending on sport
    function getImageForSport(sport) {
        switch (sport.toLowerCase()) {
            case 'basketball':
                return bball_card2;
            case 'football':
                return football_card;
            case 'tennis':
                return tennis_card;
            case 'badminton':
                return badminton_card;
            case 'gym':
                return gym_card;
            case 'yoga':
                return yoga_card;
            case 'jogging':
                return jogging_card;
            case 'hiking':
                return hiking_card;
            case 'biking':
                return biking_card;
            case 'surfing':
                return surfing_card;
            case 'misc':
                return misc_card;
            default:
                return misc_card;
        }
    }

    function getIcon(sport) {
        switch (sport.toLowerCase()) {
            case 'basketball':
                return basketball_icon;
            case 'football':
                return soccer_icon;
            case 'tennis':
                return tennis_icon;
            case 'badminton':
                return all_sports_icon;
            case 'gym':
                return all_sports_icon;
            case 'yoga':
                return all_sports_icon;
            case 'jogging':
                return all_sports_icon;
            case 'hiking':
                return all_sports_icon;
            case 'biking':
                return all_sports_icon;
            case 'surfing':
                return all_sports_icon;
            case 'misc':
                return all_sports_icon;
            default:
                return all_sports_icon;
        }
    }

    // Reference to the Swiper instance
    const swiperRef = useRef(null);
    const swiperRefs = useRef(games.map(() => React.createRef()));


    // Function to go to the next card
    // const goToNextCard = () => {
    //     if (swiperRef.current && swiperRef.current.swiper) {
    //         swiperRef.current.swiper.slideNext();
    //     }
    // };

    const goToNextCard = (index) => {
        if (swiperRefs.current[index] && swiperRefs.current[index].current.swiper) {
            swiperRefs.current[index].current.swiper.slideNext();
        }
    };

    return (

        <>
            {games.length === 0 ? (
                <p>There are no upcoming games at the moment. :( <br /> Try checking back later, or create one yourself!</p>
            ) : (
                <>
                    {/* <Flex overflowX="scroll" w="full" p={5}> */}
                    {games.map((game, index) => (
                        <div key={index}>
                            <Swiper
                                spaceBetween={70}
                                slidesPerView={1}
                                // onSlideChange={() => console.log('slide change')}
                                // onSwiper={(swiper) => console.log(swiper)}
                                // ref={swiperRef}
                                ref={swiperRefs.current[index]}
                            >
                                <SwiperSlide>
                                    <Box maxW="sm"
                                        // maxH="150px" 
                                        minWidth="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} boxShadow="md" mb={2} mr={5}
                                        bgColor="gray.50"
                                    >
                                        <Flex>
                                            {/* <Image src={basketball_icon} alt={`Image of ${game.sport}`}
                            boxSize="30px"
                            p="1"
                        // objectFit="cover" 
                        // flex="1" 
                        /> */}
                                            <Box flex="1">
                                                <Box display="flex" alignItems="baseline">
                                                    <Text fontWeight="semibold" letterSpacing="wide" fontSize="sm" textTransform="uppercase">
                                                        {format(parseISO(game.datetime), 'EEE, MMM d, p')}
                                                    </Text>
                                                </Box>

                                                {/* <Text mt={2} fontSize="sm">
                                Game #{game.game_id}
                            </Text> */}
                                                <Flex align="center" mb={3}>
                                                    <Text fontSize="sm" my="auto">
                                                        SPORT: {game.sport.charAt(0).toUpperCase() + game.sport.slice(1)}
                                                    </Text>
                                                    <Image src={getIcon(game.sport)} alt={`${game.sport}`} boxSize="20px" ml={2} my="auto" />
                                                </Flex>
                                                <Text fontSize="sm">
                                                    LOCATION: {game.location === "TBD" ? "To Be Decided" : game.location_name}
                                                </Text>
                                                <Text fontSize="sm">
                                                    ATTENDEES: {game.no_of_players}/{game.max_no_of_players}
                                                </Text>
                                                <Text fontSize="sm">
                                                    {/* Host: {`${game.name.substring(0, 3)}*****`} */}
                                                    HOST: {game.name} {' '}
                                                    {game.name === props.username &&
                                                        <Tag severity="info" value="HOST" marginleft="5px">👑</Tag>}
                                                </Text>

                                                <Stack direction="row" spacing={4} align="center" mt={2}>
                                                    {/* <Button colorScheme="teal" variant="solid">
                                    Join / Leave
                                </Button> */}
                                                    {game.name === props.username ? (
                                                        <Button
                                                            label="Edit"
                                                            colorScheme="yellow"
                                                            // bg="yellow.300"
                                                            variant="outline"
                                                            // color="white"
                                                            onClick={(e) => {
                                                                handleEditClick(e, game._id)
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>)
                                                        : (<Button
                                                            label="View"
                                                            colorScheme="green"
                                                            onClick={(e) => {
                                                                handleEditClick(e, game._id)
                                                            }}
                                                        >
                                                            View
                                                        </Button>)
                                                    }
                                                </Stack>
                                            </Box>
                                            <Box>
                                                <Image src={getImageForSport(game.sport)}
                                                    alt={`Image of ${game.sport}`}
                                                    boxSize="100px"
                                                    // p="1"
                                                    // objectFit="cover" 
                                                    // flex="1" 
                                                    onClick={(e) => {
                                                        handleEditClick(e, game._id)
                                                    }}
                                                    css={{ cursor: 'pointer' }}
                                                // overflow='hidden'
                                                />
                                                <Text fontSize="sm" mt="2">
                                                    {/* Host: {`${game.name.substring(0, 3)}*****`} */}
                                                    {game.tags ?
                                                        (<Tag severity="warning" value={game.tags}></Tag>) :
                                                        null}
                                                </Text>

                                            </Box>
                                        </Flex >

                                        <Box position="absolute" right="10px" top="50%" zIndex="banner" color="gray.600">
                                            {/* <button onClick={goToNextCard} style={{ border: 'none', background: 'none' }}>
                                        <FaAngleRight color="gray" />
                                    </button> */}
                                            <button onClick={() => goToNextCard(index)}>
                                                <FaAngleRight color="gray" />
                                            </button>
                                        </Box>
                                    </Box >
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box maxW="sm"
                                        // maxH="150px" 
                                        minWidth="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" p={5} boxShadow="md" mb={2} mr={5}
                                        bgColor="gray.50"
                                    >
                                        {/* Content of the second box */}
                                        <Flex>
                                            <Box flex="1">
                                                <Box display="flex" alignItems="baseline">
                                                    <Text fontWeight="semibold" letterSpacing="wide" fontSize="sm" textTransform="uppercase">
                                                        Description:
                                                    </Text>
                                                </Box>
                                                <Text>
                                                    {game.comments ? game.comments : "None"}
                                                </Text>
                                                {game.name === props.username ? (
                                                    <Button
                                                        label="Edit"
                                                        colorScheme="yellow"
                                                        variant="outline"
                                                        // color="gray.500"
                                                        onClick={(e) => {
                                                            handleEditClick(e, game._id)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>)
                                                    : (<Button
                                                        label="View"
                                                        colorScheme="green"
                                                        onClick={(e) => {
                                                            handleEditClick(e, game._id)
                                                        }}
                                                    >
                                                        View
                                                    </Button>)
                                                }
                                            </Box>

                                        </Flex>

                                    </Box>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    ))
                    }
                    {/* <Box className="slide-counter" p={4} bg="blue.100" borderRadius="md"> */}
                </>
            )}
        </>
    )
}

export default GameCards2