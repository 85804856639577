import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Divider,
    AbsoluteCenter
} from '@chakra-ui/react';
import { useNavigate } from "react-router";
import jwt_decode from "jwt-decode"; // import the jwt-decode library
import nadal_bg from '../images/nadal_bg.png'
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleButton from 'react-google-button'
// import { useContext } from 'react';
import { AdminContext } from '../App';


async function loginUser(credentials) {
    // console.log("START LOGIN");
    // console.log("credentials: ", credentials);
    // const response = await fetch('http://:5000/login', {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });

    const status = response.status;
    // console.log("status: ", status);

    const text = await response.text();
    // console.log("response text: ", text);

    const json = JSON.parse(text);
    // console.log("json: ", json);

    return { json, status };
}

function getExpiration(token) {
    const decodedToken = jwt_decode(token);
    const currentTime = new Date().getTime() / 1000;
    return decodedToken.exp - currentTime;
    // return decodedToken.
}

export default function Login2({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [admin, setAdmin] = useState();
    const { setIsAdmin } = useContext(AdminContext);

    // for Google Auth
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState(null);
    // Google Auth states:
    const [g_username, setG_username] = useState();
    const [g_password, setG_password] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();

    const navigate = useNavigate();


    const handleSubmit = async e => {
        e.preventDefault();
        if (!username) {
            window.alert("Please enter a username.");
            return;
        }
        const token = await loginUser({
            username,
            password,
            admin
            // games
        });
        setToken(token.json);
        // console.log("token: ", token.json);
        // console.log("token status ", token.status);
        if (token.status === 401) {
            window.alert("Invalid credentials.");
        }
        // if (token.hasOwnProperty("token")) {
        //     console.log("The object contains a key called 'token'");
        //     navigate("/table");
        // }
        // if (token.hasOwnProperty("token")) {
        //     console.log("The object contains a key called 'token'");
        //     const expiresIn = getExpiration(token.token);
        //     console.log("expiresIn: ", expiresIn); // log the expiration time
        //     navigate("/table");
        // }
        if (token.json.hasOwnProperty("token")) {
            const decodedToken = jwt_decode(token.json.token);
            const username = decodedToken.username;
            // console.log("Username: ", username);
            const expiresIn = getExpiration(token.json.token);
            // console.log("expiresIn: ", expiresIn);
            navigate("/");
        }
    }

    // for Google Auth
    const responseMessage = (response) => {
        // console.log("Success:", response);
    };
    const errorMessage = (error) => {
        // console.log("Error:", error);
    };

    // const login = useGoogleLogin({
    //     onSuccess: (codeResponse) => {
    //         console.log("Successssss:", codeResponse)
    //         setUser(codeResponse)
    //         // console.log("checkpoint1")
    //     },
    //     onError: (error) => console.log('Login Failed:', error)
    // });

    // useEffect(
    //     () => {
    //         if (user) {
    //             axios
    //                 .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
    //                     headers: {
    //                         Authorization: `Bearer ${user.access_token}`,
    //                         Accept: 'application/json'
    //                     }
    //                 })
    //                 .then((res) => {
    // console.log("res: ", res)
    // setProfile(res.data);
    // console.log("profile:", res.data);
    // // setG_username(res.data.name);
    // setG_username(res.data.email);
    // // console.log("checkpoint2")
    // // setG_password("a12345");
    // // setFirstName(res.data.given_name);
    // // setLastName(res.data.family_name);
    // // setEmail(res.data.email);
    // // console.log("all google states:", g_username, g_password, g_firstName, g_lastName);
    // console.log("all google states:", res.data.name, res.data.given_name, res.data.family_name, res.data.email);
    //                 })
    //                 .catch((err) => console.log(err));
    //             console.log("user:", user)
    //             // setUserName(profile.name);
    //             // console.log("setUserName():", profile.name);
    //             handleSubmit_google()
    //         }
    //     },
    //     [user]
    // );
    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                // console.log("Successssss:", codeResponse);
                setUser(codeResponse);

                if (codeResponse) {
                    const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${codeResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    });
                    // console.log("res: ", res)
                    setProfile(res.data);
                    // console.log("profile:", res.data);
                    // setG_username(res.data.name);
                    setG_username(res.data.email);
                    // console.log("checkpoint2")
                    // setG_password("a12345");
                    // setFirstName(res.data.given_name);
                    // setLastName(res.data.family_name);
                    // setEmail(res.data.email);
                    // console.log("all google states:", g_username, g_password, g_firstName, g_lastName);
                    // console.log("all google states:", res.data.name, res.data.given_name, res.data.family_name, res.data.email);
                    // Call registerGoogle function when all states have been set
                    // how to call this ONLY when all the states above are set...
                    // await registerGoogle(); // Assuming registerGoogle is an async function
                    const temp_username = res.data.email;
                    // console.log("temp_username", temp_username)
                    const google_flag = 1;
                    const token = await loginUser_google({
                        g_username: temp_username,
                        google_flag,
                        // password,
                        admin
                        // games
                    });
                    setToken(token.json);
                    // console.log("token: ", token.json);
                    // console.log("token status ", token.status);
                    if (token.status === 401) {
                        window.alert("Please register with Google first. You will be re-directed to the registration page.");
                        navigate("/register");
                    }
                    if (token.json.hasOwnProperty("token")) {
                        const decodedToken = jwt_decode(token.json.token);
                        const g_username = decodedToken.g_username;
                        // console.log("Username: ", g_username);
                        const expiresIn = getExpiration(token.json.token);
                        // console.log("expiresIn: ", expiresIn);
                        navigate("/");
                    }
                }
            } catch (error) {
                // console.log('Login Failed:', error);
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const handleSubmit_google = async e => {
        e.preventDefault();
        if (!g_username) {
            window.alert("Please enter a Google username.");
            return;
        }
        const google_flag = 1;
        const token = await loginUser_google({
            g_username,
            google_flag,
            // password,
            // admin
            // games
        });
        setToken(token.json);
        // console.log("token: ", token.json);
        // console.log("token status ", token.status);
        if (token.status === 401) {
            window.alert("Invalid credentials.");
        }
        if (token.json.hasOwnProperty("token")) {
            const decodedToken = jwt_decode(token.json.token);
            const g_username = decodedToken.g_username;
            console.log("Username: ", g_username);
            const expiresIn = getExpiration(token.json.token);
            console.log("expiresIn: ", expiresIn);
            navigate("/");
        }
    }

    async function loginUser_google(credentials) {
        // console.log("START LOGIN", credentials);
        // console.log("credentials: ", credentials);
        // const response = await fetch('http://:5000/login', {
        // console.log(credentials);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/login_google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });

        const status = response.status;
        // console.log("status: ", status);

        const text = await response.text();
        // console.log("response text: ", text);

        const json = JSON.parse(text);
        // console.log("json: ", json);
        // console.log("json.admin: ", json.admin);
        setIsAdmin(json.admin);
        return { json, status };
    }


    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            // bg={useColorModeValue('gray.50', 'gray.800')}
            bg={`url(${nadal_bg}) no-repeat center center fixed`}
            // bgSize="cover"
            bgSize={{ base: "300%", md: 'cover' }}
            backgroundPosition={{ base: '60% 25%;', md: 'center center' }}
        // transform="scale(1)"
        // bgPosition="center"
        // bgOpacity={0.2}
        // sx={{
        //     backgroundImage: `url('https://assets.tennismajors.com/app/uploads/2022/06/06001504/Rafael_Nadal_foot.jpg' )`,
        // }}
        >
            <Flex
                // bg={"whiteAlpha.500"}
                // position={"absolute"}
                // top={0}
                // left={0}
                minH={'100vh'}
                align={'center'}
                justify={'center'}
                w={"100%"}
                h={"100%"}
            >

                <Stack
                    spacing={8}
                    mx={'auto'}
                    maxW={'lg'}
                    py={12}
                    px={6}
                    bgColor="rgba(255, 255, 255, 0.5)"
                    rounded={'lg'}
                >
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'}>Log in</Heading>
                        <Text fontSize={'lg'} color={'gray.900'}>
                            {/* to create or join a <Link color={'blue.400'}>game</Link> today. 🎾🏀⚽🏑🏸 */}
                            <b> Match, Play, Repeat 🎾🏀⚽🏑🏸</b>
                        </Text>
                    </Stack>
                    {/* <div className="login-wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}> */}
                    {/* <h1>Please Log In</h1> */}
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('white', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            <form onSubmit={handleSubmit}>
                                <FormControl id="username">
                                    <FormLabel>Username</FormLabel>
                                    {/* <Input type="email" /> */}
                                    <Input type="text" onChange={e => setUserName(e.target.value)} />
                                </FormControl>

                                <FormControl id="password">
                                    <FormLabel>Password</FormLabel>
                                    {/* <Input type="password" /> */}
                                    <Input type="password" onChange={e => setPassword(e.target.value)} />
                                </FormControl>
                                <Stack spacing={10}>
                                    <Stack
                                        direction={{ base: 'column', sm: 'row' }}
                                        align={'start'}
                                        justify={'space-between'}>
                                        {/* <Checkbox>Remember me</Checkbox> */}
                                        <Link color={'blue.400'}>Forgot password?</Link>
                                    </Stack>
                                    <Button
                                        type="submit"
                                        colorScheme='blue'
                                        variant='outline'
                                        // bg={'blue.400'}
                                        // color={'white'}
                                        // _hover={{
                                        //     bg: 'blue.500',
                                        // }}
                                        zIndex="9999" // add this line
                                        isDisabled={false}
                                    >
                                        Log in
                                    </Button>
                                    {/* <button
                                    type="submit">
                                    Log in
                                </button> */}

                                </Stack>
                            </form>
                            <Box position='relative'>
                                <Divider />
                                <AbsoluteCenter bg='white' px='3'>
                                    or
                                </AbsoluteCenter>
                            </Box>
                            {/* <div> */}
                            <useGoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                            {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                            {/* </div> */}
                            <Button onClick={login}
                                size='sm'
                                colorScheme='blue'
                            // variant='outline'
                            >
                                Log in with Google
                            </Button>
                            {/* {profile ? (
                                <div>
                                    <img src={profile.picture} alt="user image" />
                                    <h3>User Logged in</h3>
                                    <p>Name: {profile.name}</p>
                                    <p>Email Address: {profile.email}</p>
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                <Button onClick={login} size='xs' colorScheme='yellow'>Log in with Google 🚀</Button>
                            )} */}
                            {/* <button onClick={handleSubmit_google}>Validate user in DB 🚀</button> */}
                            <Flex style={{ fontSize: 'small' }}><p>New to <b>SportMatchHK</b>? Register <b><a href="/register">here</a></b>.🏀</p></Flex>
                        </Stack>
                    </Box>

                </Stack>
            </Flex>
        </Flex>
    )
}

Login2.propTypes = {
    setToken: PropTypes.func.isRequired
}


{/* 
                    <form onSubmit={handleSubmit}>
                        <label>
                            <p>Username</p>
                            <input type="text" onChange={e => setUserName(e.target.value)} />
                        </label>
                        <label>
                            <p>Password</p>
                            <input type="password" onChange={e => setPassword(e.target.value)} />
                        </label>
                        <div>
                            <button type="submit">Submit</button>
                        </div>
                    </form> */}
{/* </div> */ }