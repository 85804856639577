import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
    Box,
    FormControl,
    FormLabel,
    Input,
    Select,
} from '@chakra-ui/react';
// import Map from "./map";
import logo from '../tennis.png'
import { Link } from "react-router-dom";
import nadal_bg from '../images/nadal_bg.png'
import conquer from '../images/conquer.jpg'
import kobe from '../images/kobe.jpg'
import kobe_rest from '../images/kobe_rest.jpg'
import soccer_team from '../images/soccer_team.jpeg'



// Store the image URLs in an array
const imageUrls = [
    nadal_bg,
    kobe_rest,
];
// Get a random index from the array
const randomIndex = Math.floor(Math.random() * imageUrls.length);


export default function SplitScreen() {
    const subject = encodeURIComponent('Question');
    const body = encodeURIComponent('Hello,\n\nI wanted to inquire about...');
    return (
        <>
            <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: useBreakpointValue({ base: '20%', md: '30%' }),
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'blue.400',
                                    zIndex: -1,
                                }}>
                                SportMatchHK
                            </Text>
                            <br />
                            <Text color={'blue.400'} as={'span'}>
                                {/* Ready for a game, anytime. */}
                                Plan your game here.

                            </Text>
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                            {/* Connecting Sports Enthusiasts, One Match at a Time */}
                            {/* Player-Court Matching Platform */}
                            {/* <p>Our mission is simple. </p> */}
                            <p>We want to make it simple for you to <b>connect with other sports enthusiasts</b>  and <b>discover available facilities</b> near you.</p>

                            <p>Leave us a message on our <b><a href="/contactus">Contact Us page</a></b> to let us know what you think!</p>
                            Matching sports players, one game at a time.
                        </Text>

                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>

                            <Link className="btn btn-link" to={`/`}>
                                <Button
                                    rounded={'full'}
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    Find a Game
                                </Button>
                            </Link>

                            <Link className="btn btn-link" to={`/`}>
                                <Button
                                    rounded={'full'}
                                >
                                    Create a Game
                                </Button>
                            </Link>
                            {/* <Button rounded={'full'}>Create a Game</Button> */}
                            {/* <Link className="btn btn-link" to={`/contactus`}> */}

                            {/* <Link className="btn btn-link" to={`mailto:contactus@sportmatch.club?subject=${subject}&body=${body}`} target="_blank">
                            <Button
                                rounded={'full'} fontSize="12px"
                                _hover={{ backgroundColor: 'lightyellow' }}
                            >
                                Contact Us
                            </Button>
                        </Link> */}

                            <Link className="btn btn-link" to={`/contactus`}>
                                <Button
                                    rounded={'full'}
                                    _hover={{ backgroundColor: 'lightyellow' }}
                                >
                                    Contact Us
                                </Button>
                            </Link>

                        </Stack>

                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Image
                        alt={'Login Image'}
                        objectFit={'cover'}
                        src={
                            nadal_bg
                        }
                    />
                </Flex>
            </Stack>

            <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: useBreakpointValue({ base: '20%', md: '30%' }),
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'blue.400',
                                    zIndex: -1,
                                }}>
                                SportMatchHK
                            </Text>
                            <br />
                            <Text color={'blue.400'} as={'span'}>
                                {/* Ready for a game, anytime. */}
                                Get notified. Play.

                            </Text>
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                            {/* Connecting Sports Enthusiasts, One Match at a Time */}
                            {/* Player-Court Matching Platform */}
                            {/* <p>Our mission is simple. </p> */}
                            <p>Want to be more active? Enter your email, interested sport(s), skill-level, region(s), and get notified whenever there is a game of interest.</p>
                        </Text>

                        <Box maxW="400px" mx="auto">
                            <FormControl id="email" mb="4">
                                <FormLabel>Email</FormLabel>
                                <Input type="email" placeholder="Enter your email" />
                            </FormControl>

                            <FormControl id="sports" mb="4">
                                <FormLabel>Interested Sport(s)</FormLabel>
                                <Select placeholder="Select sport(s)" multiple>
                                    <option value="basketball">Basketball</option>
                                    <option value="soccer">Soccer</option>
                                    <option value="tennis">Tennis</option>
                                    {/* Add more options for other sports */}
                                </Select>
                            </FormControl>

                            <FormControl id="skillLevel" mb="4">
                                <FormLabel>Skill Level</FormLabel>
                                <Select placeholder="Select skill level">
                                    <option value="beginner">Beginner</option>
                                    <option value="intermediate">Intermediate</option>
                                    <option value="advanced">Advanced</option>
                                </Select>
                            </FormControl>

                            <FormControl id="regions" mb="4">
                                <FormLabel>Region(s)</FormLabel>
                                <Select placeholder="Select region(s)" multiple>
                                    <option value="north">North</option>
                                    <option value="south">South</option>
                                    <option value="east">East</option>
                                    {/* Add more options for other regions */}
                                </Select>
                            </FormControl>

                            <Button colorScheme="teal">Submit</Button>
                        </Box>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Image
                        alt={'Login Image'}
                        objectFit={'cover'}
                        src={
                            kobe_rest
                        }
                    />
                </Flex>
            </Stack>
        </>

    );
}
